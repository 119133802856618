@import "../../styles/shared";

.notified-group-wrapper {

  .wrapped-tabs-wrapper {

    .arrow {
      background-color: #ffffff !important;

      &.arrow-left {
        border-right: 10px solid rgba(255, 255, 255, 0.7) !important;
      }
  
      &.arrow-right {
        border-left: 10px solid rgba(255, 255, 255, 0.7) !important;
      }
    }
  }

  .permissions-wrapper {
    flex-wrap: wrap;
    margin-top: 10px;

    .permission-wrapper {
      width: 33.33%;
      align-items: center;
      padding-bottom: 7px;
      padding-top: 3px;
      color: #08232f;

      &.view-mode {
        color: #758091;

        &.selected-permission {
          color: #08232f;
        }
      }

      .checkbox-wrapper {
        display: flex;

        .select-all {
          font-weight: bold;
        }
      }
    }

    @media screen and (max-width: 500px) {
      .permission-wrapper {
        width: 50%;
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .actions-wrapper {
      flex-direction: column;
      gap: $size16;
      height: unset;
      align-items: flex-start;
      padding: 0 $size16;

      .account-actions {
        margin-left: 0;
        flex-direction: column;
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }
          
          .card-item-body-status {
            display: flex;
            gap: $size8;

            p {
              margin: 0;
            }

            .status-active {
              display: flex;
              align-items: center;
            }

            .status-inactive {
              display: flex;
              align-items: center;
            }
          }
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .delete-account-confirmation {

      .confirmation-modal-input {
        padding-left: 0;

        .tips {
          text-align: center;
        }
      }

      .confirmation-modal-buttons {
        flex-direction: column;

        button {
          height: $size36;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .wrapped-tabs-wrapper {
      width: 100%;
      margin: 0;
    }
  }
}

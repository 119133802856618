@import "../../../styles/shared";

.my-account-wrapper {
  .my-company-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 790px;
    margin-top: $size16;
    box-sizing: border-box;
    gap: $size16;

    .section-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size16;
      width: 100%;
      padding: 0 $size24 $size24 $size24;
      border-radius: $element-border-radius;
      background-color: $white;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;

      .wrapper-header { 
        display: flex;
        justify-content: space-between;
        padding: $size24 0 $size8 0;
        box-sizing: border-box;

        .wrapper-title {
          font-size: $size14;
          font-weight: 500;
          color: $dark-grey-blue;
          margin: 0;
          text-transform: uppercase;
        }
        
        .buttons-wrapper {
          display: flex;
          gap: $size8;
        }

        .simple-button {
          margin-left: auto;
        }
      }

      .section {
        display: flex;
        width: 100%;
        max-width: 1134px;
        box-sizing: border-box;
        align-items: center;

        .section-title {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 25%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
          justify-content: center;

          .disclaimer {
            margin: 0;
            font-size: $size14;
            color: $dark-grey-blue;
            font-weight: 400;
          }
        }
        
        .section-content {
          height: 100%;
          width: 75%;
          display: flex;
          align-items: center;
          gap: $size16;
          
          .input-item-wrapper {
            display: flex;
            align-items: center;
            gap: $size8;
            padding: 0 $size16;
            width: 32%;
            height: $size36;
            border: 1px solid $medium-grey-blue;
            border-radius: $element-border-radius;
            box-sizing: border-box;

            .input-item {
              border: none;
              background-color: $white;
              outline: none;
              box-sizing: border-box;
              width: 100%;
            }

            .input-item:disabled {
              opacity: 0.7;
            }

            .input-item-icon {
              width: $size16;
              height: $size16;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
            }

            .error-message {
              position: absolute;
              font-size: $size12;
              color: $error-color;
              margin-top: 62px;
            }
          }

          .has-error {
            border: 1px solid $error-color;
          }

          .cancel-button {
            margin-left: auto;
          }

          .larger-input {
            width: calc(64% + 16px);
          }

          .disclaimer {
            margin: 0;
            font-size: $size14;
            color: $dark-grey-blue;
          }

          .avatar-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            cursor: pointer;

            .file-input-wrapper {
              width: 80px;
              height: 80px;
              border-radius: 50%;
              cursor: pointer;

              .avatar-img {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                .image {
                  border-radius: 50%;
                  background-size: auto;
                  background-position: center;
                  height: 80px;
                  width: 80px;
                  object-fit: cover;
                }

                .avatar-initials {
                  background-color: $dark-grey-blue;
                  color: $white;
                  width: inherit;
                  height: inherit;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: $size24;
                  flex-shrink: 0;
                }
              }

              svg {
                position: relative;
                right: -55px;
                bottom: 25px;
                z-index: 1;
              }
            }

            .file-input {
              display: none;
            }
          }

          .remove-image-button {
            margin-left: $size40;
          }         

          .click-outside-wrapper {
            margin: 0;
            border: 1px solid $medium-grey-blue; 
            box-shadow: none;
            max-width: 32%;
            box-sizing: border-box;

            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }         

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }

              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }

            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 160px;
              position: relative;
              overflow: overlay;

              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }
        }

        .buttons {
          display: flex;
          margin-top: $size24;
          gap: $size8;
        }
      }

      .separator {
        background-color: $medium-grey-blue;
        width: 100%;
        height: 1px;
        margin: $size16 0;
      }

      .full-width {
        max-width: unset;
      }

      .empty-page-results {
        min-height: 350px;
      }
    }

    .locked-section {
      min-height: 420px;
      align-items: center;
      justify-content: center;

      .locked-section-title {
        margin: 0 0 $size8 0;
        font-size: $size32;
        font-weight: 500;
        color: $primary-black;
        text-align: center;      
      }

      .locked-section-subtitle {
        margin: 0 0 $size24 0;
        font-size: $size14;
        color: $dark-grey-blue;
        text-align: center;
        max-width: 405px;

        .link-action {
          color: $primary-blue;
          cursor: pointer;
        }
      }

      .locked-section-buttons-wrapper {
        display: flex;
        justify-content: center;
        gap: $size24;
        width: 100%;
      }
    }

    .table-actions-wrapper {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: end;
      cursor: pointer;
    }

    .status-wrapper {
      display: flex;
      align-items: center;
      width: 150px;
  
      .status-active {
        color: $success-green;
        display: flex;
        align-items: center;
      }
  
      .status-inactive {
        display: flex;
        align-items: center;
        color: $dark-grey-blue;
      }
      
      .status-pending {
        color: $dark-yellow;
        display: flex;
        align-items: center;
      }
  
      svg {
        margin-right: $size8;
      }
    }

    .leave-company-confirmation {

      .confirmation-modal-message {
        margin-bottom: $size8;
      }
  
      .leave-confirmation-wrapper {
        padding-left: 56px;
  
        .disclaimer {
          padding: $size8 $size16;
          border-radius: $element-border-radius;
          background-color: $transparent-blue;
          margin-bottom: $size16;
        }
      }
  
      .confirmation-modal-buttons {
        margin-top: $size32;
      }
    }

    .spinner-wrapper {
      position: fixed;
      z-index: 9999;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      .loading-spinner {
        position: relative;
        top: 25%;
        left: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @media all and (max-width: 768px) {
    .my-company-wrapper {
      .section-wrapper {
        padding: 0 $size16 $size24 $size16;
        border-radius: 0;
        
        .section {
          flex-direction: column;
          align-items: flex-start;
          gap: $size8;

          .section-title {
            width: 100%;

            p {
              margin-top: 0;
            }
          }

          .empty {
            display: none;
          }
          
          .section-content.avatar {
            flex-direction: column;
          }

          .section-content {
            width: 100%;
            gap: $size8;

            .input-item-wrapper {
              width: 100%;
            }

            .simple-button {
              width: 100%;
            }

            .avatar-wrapper {
              width: 100%;
              justify-content: center;
            }

            .remove-image-button {
              margin: $size8 0 0 0;
            }

            .click-outside-wrapper {
              max-width: 100%;
            }
          }

          .without-margin-top {
            margin-top: -16px;
          }

          .buttons {
            display: flex !important;
            flex-direction: row !important;
          }
        }
      }

      .locked-section {
        .locked-section-buttons-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: $size16;
        }
      }
      
      .click-outside-wrapper {
        margin-bottom: $size16;
        border: 1px solid $medium-grey-blue; 
        box-shadow: none;
        max-width: 100%;
        box-sizing: border-box;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }         

          svg {
            width: 12px;
            height: 12px;
            path {
              fill : $primary-black;
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 160px;
          position: relative;
          overflow: overlay;

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }
      
      .hidden {
        visibility: hidden;
      }
      
      .cards-container {
        display: flex;
        flex-direction: column;
        gap: $size4;
      }
      
      .card {
        width: 100%;
        min-height: $size48;
        height: max-content;
        margin-top: $size4;
        background-color: $white;
        padding: $size16 $size8 0 $size16;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;
        cursor: pointer;
        border: 1px solid $medium-grey-blue;

        .card-item {
          display: flex;
          align-items: flex-start;

          .card-item-title {
            width: 35%;
            font-size: $size14;
            font-weight: 700;
            color: $primary-black;
            margin: 0;
          }

          .card-item-body {
            width: 65%;
            font-size: $size14;
            color: $primary-black;
            padding-right: $size16;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            text-align: end;
            box-sizing: border-box;

            p {
              margin: 0;
            }

            .card-description {
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            
            .card-actions {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              position: absolute;
              right: $size16;
  
              .actions-menu-wrapper {
                right: 0;
              }
            }
          }

          .with-capitalize {
            text-transform: capitalize;
          }
        }

        .expander-wrapper {
          width: 100%;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 12px;
            height: 12px;

            path {
              fill : $primary-blue;
            }
          }
        }
      }

      .with-padding-bottom {
        padding-bottom: $size16;
      }

      .leave-company-confirmation {
        font-size: $size14;
  
        .leave-confirmation-wrapper {
          padding-left: 0;
  
          .disclaimer {
            p {
              margin: 0;
            }
          }
        }
  
        .confirmation-modal-buttons {
          flex-direction: column;
  
          button {
            height: $size36;
          }
        }
      }
    }
  }
}
@import "../../styles/shared";

.mycoltene-templates-wrapper {

  .name-wrapper {
    .text {
      color: $primary-blue;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }
}

@import "../../../styles/shared";

.search-with-filter-wrapper {
  height: $size36;
  display: flex;
  align-items: center;
  box-sizing: border-box;

  .search-wrapper {
    .search {
      margin: 0 $size8 0 0 !important;
      box-shadow: none !important;
      width: 280px;

      svg {
        display: none;
      }
    }
  }

  .filter-button-wrapper {
    height: $size36;
    width: max-content;
    display: flex;
    align-items: center;
    background-color: $white;
    border-radius: $element-border-radius;
    border: none;
    font-size: $size14;
    font-weight: 500;

    &:hover {
      box-shadow: $button-box-shadow;
    }

    .filter-button {
      min-width: max-content;
      max-width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 $size16 0 $size16;
      cursor: pointer;

      .icon-wrapper {
        width: 24px;
        height: 24px;
        margin-right: $size8;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .filters-counter {
      display: flex;
      align-items: center;
      margin: 0 $size8 0 0;

      .counter-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        border-radius: 8px;
        background-color: $primary-blue;
        color: $white;
        font-size: $size12;
      }

      .reset-filters-button {
        background: none;
        border: none;
        width: 28px !important;
        height: 28px !important;
        cursor: pointer;

        svg {
          width: 28px !important;
          height: 28px !important;
          circle {
            fill: $white;
            stroke: none;
          }
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .search-with-filter-wrapper {
    width: 100%;
    justify-content: space-between;

    .search-wrapper {
      flex-grow: 1;

      .search {
        width: unset;
      }
    }
  }
}

@import "../../../styles/shared";

.notification-settings-wrapper {
  .page-modal-wrapper {
    .page-modal {
      min-width: max-content;

      .page-content {
        height: calc(100% - 69px);

        .notification-settings-modal-content {

          .device-data-wrapper {
            justify-content: space-between;
            width: 100%;
            height: 84px;
            margin: 0 0 $size16 0;
            border-top: 1px solid $medium-grey-blue;
            border-bottom: 1px solid $medium-grey-blue;
            box-sizing: border-box;

            .data {
              margin: 0;
              display: flex;
              align-items: center;

              .image-wrapper {
                margin: 0 $size24 0 0;
                height: $size36;
                width: $size36;

                img {
                  width: $size36;
                }
              }

              .product-wrapper {
                .product-title {
                  margin: 0 0 $size8 0;
                  font-size: $size16;
                  font-weight: 600;
                  color: $primary-black;
                }

                .product-number {
                  margin: 0;
                  font-size: $size14;
                }              
              }
            }

            .action {
              margin: 0;
              display: flex;
              flex-direction: column;
              gap: $size4;
              cursor: pointer;

              .tooltip {
                color: $grey-text;
                margin: 0;
                font-size: $size14;
              }           
            }
          }

          .notification-settings-table {
            width: 100%;
            height: max-content;

            .table-info-header {
              display: flex;
              align-items: flex-end;

              .table-info-header-item {
                width: 126px;
                color: $primary-black;
                font-weight: 700;
                padding: $size16;
                box-sizing: border-box;
              
                p {
                  margin: 0;
                }

                .with-margin-right {
                  margin-right: $size16;
                }
              }

              .send-to-item {
                width: 280px;
                display: flex;
                align-items: center;
                cursor: pointer;

                .header-sort {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-left: 10px;
                  margin-right: 10px;

                  svg {
                    width: 8px;
                    height: 8px;
                    cursor: pointer;
                    fill: $dark-grey-blue;
                  }

                  svg:last-of-type {
                    transform: rotateX(180deg);
                  }

                  .active-sort {
                    fill: $primary-blue;
                  }
                }
              }

              .action-item {
                width: 104px;
                display: flex;
                align-items: center;
                cursor: pointer;
              }
            }

            .table-action-header {
              height: 67px;
              display: flex;
              align-items: center;
              background-color: $grey-background;
              border-radius: $element-border-radius;

              .table-action-header-item {
                width: 126px;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 $size16;
                box-sizing: border-box;

                .email-input {
                  height: $size36;
                  width: 100%;
                  margin: 0;
                  border-radius: $element-border-radius;
                  border: 1px solid $medium-grey-blue;
                  outline: none;
                  text-indent: $size16;
                }

                .status {
                  margin-left: $size8;
                }

                .status-disabled {
                  color: $dark-grey-blue;
                }

                .radio-wrapper {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  box-sizing: border-box;
                  cursor: pointer;
                }
              }

              .send-to-item {
                width: 280px;
                display: flex;
                align-items: center; 
                border-left: none;  
                justify-content: flex-start !important;
              }

              .category-item {
                gap: $size16;
              }

              .action-item {
                width: 104px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .simple-button {
                  min-width: unset;
                }
              }
            }
            
            .table-body {
              height: 50px;
              display: flex;
              align-items: center;

              .table-body-item {
                width: 126px;
                height: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 $size16;
                box-sizing: border-box;

                .status {
                  margin-left: $size8;
                }

                .status-disabled {
                  color: $dark-grey-blue;
                }

                .radio-wrapper {
                  display: flex;
                  align-items: center;
                  height: 100%;
                  box-sizing: border-box;
                  cursor: pointer;
                }
                
                p {
                  margin: 0;
                }
              }

              .send-to-item {
                width: 280px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-left: none;
              }

              .category-item {
                gap: $size16;
              }

              .action-item {
                width: 104px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }

            .fixed-item {
              margin-top: $size8;
              background-color: $transparent-blue;
              border-radius: $element-border-radius;
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  .confirmation-modal-wrapper {
    .modal {
      .apply-to-all-tooltip {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;

        p {
          margin: $size8 0 0 0;
        }
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 1054px) {
    .page-modal-wrapper {
      .page-modal {
        width: 100%;
        height: 100%;
        overflow: inherit;
        overflow-x: hidden;
        box-sizing: border-box;
        min-width: unset;

        .page-header {
          height: max-content;
        }

        .page-content {

          .notification-settings-modal-content {
            width: 100%;
            box-sizing: border-box;

            .device-data-wrapper {
              flex-direction: column;
              height: fit-content;
              border-bottom: none;
              border-top: none;

              .data {
                width: 100%;
                margin: $size16 0;
              }

              .action {
                margin: 0 $size16 0 $size16;
                width: 100%;

                .action-button {
                  width: 100%;
                  max-width: 400px;
                }
              }
            }

            .notification-settings-table {
              font-size: $size14;
              color: $primary-black;

              .card-add-header {
                width: 100%;
                box-sizing: border-box;
                background-color: $grey-background;
                padding-bottom: $size16;

                .card-add-header-send-to {
                  font-weight: 700;
                  height: $size36;
                  display: flex;
                  align-items: center;
                  margin: 0 $size16 0 $size16;

                  p {
                    margin: 0;
                  }
                }

                .email-input {
                  height: $size36;
                  width: 90%;
                  margin: 0 $size16 0 $size16;
                  border-radius: $element-border-radius;
                  border: 1px solid $medium-grey-blue;
                  outline: none;
                  text-indent: $size16;
                  box-sizing: border-box;
                }

                .card-add-header-notification {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  height: 46px;
                  margin: $size16 $size16 0 $size16;
                  box-sizing: border-box;

                  p {
                    margin: 0;
                  }

                  .category-title {
                    font-weight: 700;
                  }

                  .card-add-header-notification-status-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: $size8;

                    .status {
                      margin-right: $size8;
                    }

                    .radio-wrapper {
                      display: flex;
                      align-items: center;
                      height: 100%;
                      box-sizing: border-box;
                      cursor: pointer;
                    }
                  }

                  .category-item {
                    gap: $size16;
                  }
                }
                
                .card-add-header-button {
                  margin: $size16 $size16 0 $size16;

                  .submit-button {
                    width: 100%;
                  }
                }
              }

              .card-configuration {
                background-color: $white;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                margin-bottom: $size4;

                .card-configuration-header {
                  display: flex;
                  height: 40px;
                  align-items: center;
                  box-sizing: border-box;

                  .card-configuration-actions {
                    display:flex;
                    align-items: center;
                    margin-left: auto;
                    margin-right: $size8;
                  }
                }

                .card-configuration-notification {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  height: 46px;
                  margin: $size16 $size16 0 $size16;
                  box-sizing: border-box;

                  p {
                    margin: 0;
                  }

                  .category-title {
                    font-weight: 700;
                  }

                  .card-configuration-notification-status-wrapper {
                    display: flex;
                    align-items: center;
                    margin-top: $size8;

                    .status {
                      margin-right: $size8;
                    }

                    .radio-wrapper {
                      display: flex;
                      align-items: center;
                      height: 100%;
                      box-sizing: border-box;
                      cursor: pointer;
                    }
                  }

                  .category-item {
                    gap: $size16;
                  }
                }

                .with-margin-left {
                  margin-left: $size16;
                }
              }

              .fixed-item {
                background-color: $transparent-blue;
                border: 1px solid transparent;
              }

              .selected {
                padding-bottom: $size16;
              }
            }
          }
        }
      }
    }

    .confirmation-modal-wrapper {
      .modal {
        .apply-to-all-tooltip {
          text-align: center;
        }
      }
    }
  }
}
@import "../../../styles/shared";

.wrapped-tabs-wrapper {
  width: 100%;
  border-bottom: 1px solid $medium-grey-blue;
  position: relative;

  .arrow {
    display: flex;
    width: 29px;
    height: $size36;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    bottom: 0;
    margin: auto;
    padding-bottom: 7px;
    align-self: flex-end;
    background-color: $white;
    box-sizing: border-box;
    background-clip: padding-box;

    &.arrow-left {
      border-right: 10px solid rgba(255, 255, 255, .7);
      left: 0;
      svg {
        transform: rotateY(180deg);
      }
    }

    &.arrow-right {
      border-left: 10px solid rgba(255, 255, 255, .7);
      right: 0
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }
  
  .tabs-container {
    overflow: hidden;
    gap: $size24;
    position: relative;
    z-index: 1;

    .tab {
      height: $size36;
      font-size: $size16;
      color: $dark-grey-blue;
      text-transform: capitalize;
      box-sizing: border-box;
      cursor: pointer;

      span {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .selected {
      color: $primary-black;
      font-weight: 700;        
      border-bottom: 4px solid $primary-blue;
      border-radius: 2px;

      span {
        padding-top: 2px;
      }
    }
  }

  .scrollable {
    overflow-x: auto !important;
    overflow-y: hidden;
    scrollbar-width: none; // for Firefox
    -ms-overflow-style: none; // for Internet Explorer and Edge

    &::-webkit-scrollbar {
      display: none; // for Chrome, Safari, and Opera
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 32px);
    margin: 0 $size16;
  }
}

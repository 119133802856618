@import "../../../styles/shared";

.toast-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 53px;
  padding: 8px;
  gap: $size24;
  
  .status {
    width: 8px;
    height: 100%;
    border-radius: $size4;
  }

  .error-status {
    background-color: $required-red;
  }

  .error-svg {
    .a {
      fill: $required-red;
    }
  }

  .info-status {
    background-color: $strong-blue;
  }

  .info-svg {
    .fillable-area {
      fill: $strong-blue !important;
      width: 24px !important;
      height: 24px !important;
    }
  }

  .success-status {
    background-color: $success-green;
  }

  .warning-status {
    background-color: $dark-yellow;
  }

  .warning-svg {
    .a {
      fill: $dark-yellow;
    }
  }

  .text {
    color: $primary-black;
    font-size: $size16;
    font-weight: 700;
  }

  .action {
    background-color: transparent;
    border: none;
    color: $primary-blue;
    font-size: 14;
    font-weight: 500;
    margin: $size4 0 0 0;
    padding: 0;
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }

  @media screen and (max-width: 768px){
    gap: $size8;

    .text {
      font-size: $size14;
    }
  }
}
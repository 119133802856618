@import '../../styles/shared';

.troubleshoot-wrapper {
  
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
    border-top: unset;
  }

  .content {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: $size16;
    min-height: 790px;
    border-radius: $element-border-radius;
    background-color: $white;
    box-shadow: 0px 0px 25px #0000000D;
    box-sizing: border-box;

    .products {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 33%;
      max-width: 300px;
      border-radius: $element-border-radius 0px 0px $element-border-radius;
      padding: $size16;
      box-sizing: border-box;
      border-right: 1px solid $medium-grey-blue;

      .card {
        display: flex;
        align-items: center;
        height: 80px;
        background-color: $light-grey-blue;
        border-radius: $element-border-radius;
        border: 1px solid transparent;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
            object-fit: cover;
          }
        }

        .product-name {
          font-size: $size16;
          color: $primary-black;
          font-weight: 700;
          margin: 0 $size16 0 0;
        } 
      }

      .selected-card {
        border: 1px solid $primary-blue;
      }
    }    

    .cycles-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: $element-border-radius;

      .cycle-select-title {
        font-size: $size14;
        margin: $size24 $size16 $size8 $size16;
        font-weight: 500;
        color: $primary-black;
      }

      .click-outside-wrapper {
        margin: 0px $size16 $size32 $size16;
        border: 1px solid $medium-grey-blue;
        box-shadow: none;
        max-width: 328px;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }         

          svg {
            width: 12px;
            height: 12px;
            path {
              fill : $primary-black;
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: overlay;

          .options-filter {
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: 1;

            .search {
              background-color: $light-grey-blue;
              border-radius: $element-border-radius;
              margin: $size16 $size16 $size4 $size16 !important;
              box-shadow: none !important;
              z-index: 1;

              .search-icon {
                display: none;
              }
            }
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }

      .cycle-details-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 $size16 $size24 $size16;
        gap: $size32;
        box-sizing: border-box;

        .cycle-title-wrapper {
          display: flex;
          flex-direction: column;
          gap: $size8;

          .cycle-title {
            display: flex;
            font-size: $size24;
            font-weight: 700;
            gap: $size4;

            .cycle-title-number {
              color: $primary-blue
            }
          }

          .cycle-description {
            font-size: $size14;
            color: $primary-black;
          }
        }      

        .cycle-category {
          .cycle-category-title {
            font-size: $size14;
            font-weight: 700;
            margin: 0 0 $size8 0;
          }
          
          .cycle-categories {
            margin: 0px;
          }
          
          .list-none {
            list-style: none;
            margin: 0px;
            padding: 0px;
          }
          
          .cycle-category-issue-list {
            margin: 0 0 0 $size16;
            padding: 0;
            list-style: none;

            .cycle-category-issue {
              font-size: $size14; 
              margin-bottom: $size4;
              color: $primary-black;
            }

            li::before {
              content: "\2022";
              color: $primary-blue;
              font-weight: bold;
              font-size: $size16;
              display: inline-block;
              width: 12px;
              margin-left: -16px;
            }
          }          
        }

        .product-videos-wrapper {
          display: flex;
          flex-direction: column;
          border-top: 1px solid $medium-grey-blue;

          .section-title {
            font-size: $size14;
            font-weight: 500;
            color: $primary-black;
            margin: $size24 0 $size8 0;
          }

          .videos-wrapper {
            display: flex;
            gap: $size24;
            width: 100%;
            margin-bottom: $size32;

            .video-item-wrapper {
              display: flex;
              flex-direction: column;
              width: 100%;
              max-width: 263px;
              cursor: pointer;

              .thumbnail-wrapper {
                display: flex;
                position: relative;
                width: 100%;
                height: 160px;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border-radius: $element-border-radius;

                .thumbnail-icon {
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translateX(-50%) translateY(-50%);

                  svg {
                    width: $size32;
                    height: $size32;
                  }
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              .title {
                margin: $size16 0 0 0;
                font-weight: 700;
                text-align: left;
                font-size: $size16;
                word-wrap: break-word;
              }
            }

            .no-videos-wrapper {
              display: flex;
              align-items: center;
              background-color: $light-grey-blue;
              border-radius: $element-border-radius;
              height: 52px;

              .disclaimer {
                margin: 0 $size16;
                font-size: $size14;
                font-style: italic;
                color: $dark-grey-blue;
              }
            }
          }
        }
      }
    }
  }

  .troubleshoot-modal-wrapper {
    display: block;
    background-color: $white;

    .products-wrapper,
    .cycles-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0px;
      overflow: inherit;

      .product-select-title,
      .cycle-select-title {
        font-size: $size14;
        margin: 0 0 $size8 0;
        font-weight: 500;
      }

      .product-select-title {
        margin-top: $size16;
      }

      .click-outside-wrapper {
        margin: 0 0 $size16 0;
        width: 100%;
        border: 1px solid $medium-grey-blue; 
        border-radius: $element-border-radius;
        box-shadow: none;
        max-width: none;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }         

          svg {
            width: 12px;
            height: 12px;
            path {
              fill : $primary-black;
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border-radius: $element-border-radius;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          z-index: 2;
          overflow: overlay;

          .options-filter {
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: 2;

            .search {
              background-color: $light-grey-blue;
              border-radius: $element-border-radius;
              margin: $size16 $size16 $size4 $size16 !important;
              box-shadow: none !important;
              z-index: 2;

              .search-icon {
                display: none;
              }
            }
          }

          .option {
            z-index: 2;
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
            font-size: $size14;
          }
        }
      }

      .cycle-details-wrapper {
        display: block;
        width: 100%;
        height: 100%;

        .cycle-title {
          display: flex;
          margin: 60px 0 0 0;
          font-size: $size24;
          font-weight: 700;

          .cycle-title-number {
            margin-left: 5px;
            color: $primary-blue
          }
        }

        .cycle-description {
          margin: 20px 0 40px 0;
          font-size: 14px;
          color: $primary-black;
          font-weight: 400;
        }

        .cycle-category {
          margin: 0 0 40px 0;

          .cycle-category-title {
            font-size: $size14;
            font-weight: 700;
            margin-bottom: 20px;
          }

          .cycle-category-issue-list {
            margin: 0 0 0 15px;
            padding: 0;
            list-style: none;

            .cycle-category-issue {
              font-size: $size14; 
              margin-bottom: 5px;
              color: $primary-black;
              font-weight: 400;
            }

            li::before {
              content: "\2022";
              color: $primary-blue;
              font-weight: bold;
              font-size: $size16;
              display: inline-block;
              width: 12px;
              margin-left: -16px;
            }
          }
        }
      }
    }

    .disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 100%;
      height: 100%;
      min-height: 250px;
      margin-top: $size16;
      border: none;
      box-shadow: none;
      background-color: inherit;

      .card {
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        background-color: $white;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
          }
        }

        .product-name {
          font-size: $size16;
          color: $primary-black;
          font-weight: 700;
          margin: 0 $size16 0 0;
        }
      }
    }

    .troubleshoot-modal-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      min-height: 250px;
      margin-top: 20px;
      border: none;
      background-color: $white;

      .products-wrapper,
      .cycles-wrapper {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 0px;
        overflow: inherit;

        .product-select-title,
        .cycle-select-title {
          font-size: $size14;
          margin: 0 0 10px 0;
          font-weight: 500;
        }

        .product-select-title {
          margin-top: 20px;
        }

        .click-outside-wrapper {
          margin: 0 0 20px 0;
          width: 100%;
          border: 1px solid $scrollbar-grey;
          border-radius: $element-border-radius;
          box-shadow: none;
          max-width: none;
          cursor: pointer;

          .height {
            div {
              color: $primary-black;
              font-size: $size14;
            }         

            svg {
              width: 12px;
              height: 12px;
              path {
                fill : $primary-black;
              }
            }

            .icon-dropdown-open {
              transform: rotate(180deg);
            }
          }

          .options-wrapper {
            margin-top: 4px;
            border-radius: $element-border-radius;
            border: 1px solid $scrollbar-grey;
            max-height: 390px;
            z-index: 2;
            overflow: overlay;

            .options-filter {
              width: 100%;
              height: 100%;
              background-color: $white;
              z-index: 2;

              .search {
                background-color: $light-grey-blue;
                border-radius: $element-border-radius;
                margin: 16px 16px 5px 16px !important;
                box-shadow: none !important;
                z-index: 2;

                .search-icon {
                  display: none;
                }
              }
            }

            .option {
              z-index: 2;
            }

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }

        .cycle-details-wrapper {
          display: block;
          width: 100%;
          height: 100%;

          .cycle-title {
            display: flex;
            margin: 60px 0 0 0;
            font-size: $size24;
            font-weight: 700;

            .cycle-title-number {
              margin-left: 5px;
              color: $primary-blue
            }
          }

          .cycle-description {
            margin: 20px 0 40px 0;
            font-size: 14px;
            color: $primary-black;
            font-weight: 400;
          }

          .cycle-category {
            margin: 0 0 40px 0;

            .cycle-category-title {
              font-size: $size14;
              font-weight: 700;
              margin-bottom: 20px;
            }

            .cycle-category-issue-list {
              margin: 0 0 0 15px;
              padding: 0;
              list-style: none;

              .cycle-category-issue {
                font-size: $size14; 
                margin-bottom: 5px;
                color: $primary-black;
                font-weight: 400;
              }

              li::before {
                content: "\2022";
                color: $primary-blue;
                font-weight: bold;
                font-size: $size16;
                display: inline-block;
                width: 12px;
                margin-left: -16px;
              }
            }          
          }        
        }
      }

      .disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }
  }
}
@import '../../styles/shared';

.remote-access-session-wrapper {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 790px;
  margin-top: $size16;
  border: 1px solid $danger-red;
  border-radius: $element-border-radius;
  background-color: $white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);

  .h-80vh {
    height: 80vh;
  }

  .iframe-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .iframe {
      width: 100%;
      height: 100%;
      background: #fff;
      transform: scale(0.95);
      position: absolute;
      top: 0;
      border-radius: $element-border-radius;
      border: 1px solid $scrollbar-grey;
      overflow: scroll;
    }

    .iframe::-webkit-scrollbar {
      visibility: visible;
    }
  }
}
$primary-blue: #00a0e9;
$primary-black: #08232f;
$dark-grey-blue: #758091;
$medium-grey-blue: #e5e7f0;
$light-grey-blue: #f5f6fa;
$green: #27cb6b;
$dark-green: #007f5f;
$red: #f92e57;
$orange: #fda700;
$light-orange: #fffbfa;
$medium-light-orange: #fda29b;
$purple: #6255ba;
$light-purple: #e9e6fd;
$dark-orange: #e87f31;
$soft-blue: #5357e6;
$required-red: #ff0000;
$dark-red: #c82929;
$transparent-red: #fbf2f1;
$medium-grey-red: #c94f3f;
$light-red: #fbf2f1;
$white: #ffffff;
$grey: #788090;
$grey-text: #768092;
$grey-background: #f5f6fa;
$grey-background-tabs: #e6e8f0;
$black: #11232e;
$purple-black: #192c49;
$separator: #707070;
$scrollbar-grey: #e5e7f0;
$table-header-background: #88abba;
$table-header-grey: #fafafa;
$table-column-line: #ededed;
$table-row-hover: #fdfdfe;
$dark-red: #d65645;
$border: #e3e3e3;
$off: #c1c1c1;
$grey: #7c7c7c;
$dark-grey-placeholder: #cccccc;
$light-grey-placeholder: #e6e6e6;
$light-grey-button: #00a0e9;
$light-grey: #e9ecef;
$dark-grey: #dee2e6;
$dark-blue: #474f6f;
$grey-indicator: #adb5bd;
$pink: #e82582;
$yellow: #f0c255;
$dark-yellow: #e9b73e;
$dark-yellow-transparent: #fefaf1;
$light-blue: #cdecfb;
$light-blue-primary: #04a0e9;
$danger-red: #c94f3f;
$success-green: #479b5e;
$cream: #ccbea0;
$light-cream: #fffaf3;
$brown: #7a4d05;
$transparent-blue: #f0faff;
$transparent-green: #f2f8f3;
$tracking-green: #d1fadf;
$tracking-red: #fee4e2;
$tracking-yellow: #fdf1c8;
$tracking-grey: #e3e6ea;
$strong-blue: #006eab;
$disabled-grey: #cbd0d6;
$outline-blue: #21a2ee;

$card-box-shadow: 0px 1px 1px #0000002f;
$modal-box-shadow: 0px 2px 6px #00000034;
$input-box-shadow: 0px 1px 1px #00000029;
$button-box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.08);

$element-border-radius: 4px;

$size4: 4px;
$size8: 8px;
$size10: 10px;
$size12: 12px;
$size13: 13px;
$size14: 14px;
$size16: 16px;
$size18: 18px;
$size20: 20px;
$size21: 21px;
$size22: 22px;
$size24: 24px;
$size28: 28px;
$size30: 30px;
$size32: 32px;
$size36: 36px;
$size40: 40px;
$size48: 48px;
$size64: 64px;

//error vars
$error-color: #ff0000;

//success vars
$success-color: #1e561f;

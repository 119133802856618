@import "../../styles/shared";


.notification-wrapper {
  div {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }
  .cursor-pointer {
    cursor: pointer;
  }

  h1 {
    margin: 0 0 21px 30px !important;
  }

  margin-bottom: 50px;

  .table {
    display: grid;
    grid-template-columns: minmax(220px, 1fr) minmax(208px, 0.9fr) minmax(140px, 0.6fr);

    .send-to, .cycle-summary-notification, .maintenance-notification, .upcoming-maintenance-notification, .actions {
      div {
        height: 45px;
        padding-left: 20px;
        margin: 0 0 14px 0;
        font-size: $size13;
        font-weight: bold;
        color: $grey-text;
        display: flex;
        align-items: flex-end;

        .up {
          width: 7px;
          height: 13px;
          transform: rotate(90deg);

          path {
            fill: $black;
          }
        }

        .down {
          margin-left: 11px;
          width: 7px;
          height: 13px;
          transform: rotate(270deg);

          path {
            fill: $black;
          }
        }

        &:first-child {
          padding-left: 30px;
        }
      }

      button {
        max-width: 90px;
        width: 100%;
        height: 30px;
        border: none;
        color: $white;
        background-color: $primary-blue;
        border-radius: $element-border-radius;
        cursor: pointer;
        justify-content: center;
      }
      
      button[disabled] {
        background-color: #e5e7f0;
        color: #08232f;
        cursor: not-allowed;
        pointer-events: none;
      }

      .table-header {
        justify-content: flex-start;
        background-color: $grey-background;
        align-items: center;
        height: 67px;
        margin-bottom: 0;
        border-right: 1px solid $scrollbar-grey;
        border-bottom: 1px solid $scrollbar-grey;

        input {
          margin-left: 10px;
          max-width: 170px;
          width: 100%;
          height: 40px;
          border-radius: $element-border-radius;
          border: 1px solid $border;
          padding-left: 11px;

          &:focus {
            outline: none;
          }
        }

        input[type="checkbox"] {
          margin-left: 0;
          width: 13px;
          cursor: pointer;
        }

        div {
          height: 16px;
          margin: 0;
          padding-left: 13px;
          padding-right: 27px;
        }

        .on {
          cursor: pointer;
          margin-left: 10px;

          path {
            fill: $primary-blue;
          }
        }

        .off {
          cursor: pointer;
          margin-left: 10px;

          path {
            fill: $off;
          }
        }
      }
    }

    .table-body {
      padding-left: 20px;
      border-right: 1px solid $scrollbar-grey;
      border-bottom: 1px solid $scrollbar-grey;
      height: 49px;
      display: flex;
      align-items: center;

      input[type="checkbox"] {
        margin-left: 0;
        width: 13px;
        cursor: pointer;
      }

      div {
        height: 16px;
        margin: 0;
        padding-left: 13px;
        padding-right: 27px;
      }
      .on {
        cursor: pointer;
        margin-left: 10px;

        path {
          fill: $primary-blue;
        }
      }

      .off {
        cursor: pointer;
        margin-left: 10px;

        path {
          fill: $off;
        }
      }

      .delete-icon {
        cursor: pointer;
      }

      &:first-child {
        padding-left: 32px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 0;
    h1 {
      font-size: $size16;
      margin: 0 0 0 14px;
    }
    .mobile {
      padding: 18px 15px 0 15px;
      .send-to {
        margin-bottom: 11px;
        font-size: $size14;
        font-weight: bold;
      }
      input {
        height: 40px;
        background-color: $grey-background;
        border: none;
        margin-bottom: 22px;
        padding-left: 10px;
        color: $black;
        &:focus {
          outline: none;
        }
      }
      .cycle, .upcoming-maintenance, .maintenance {
        font-size: $size14;
        line-height: 40px;
        .checkbox {
          input {
            margin: 0 0 0 14px;
          }
          div{
            margin-left: 12px;
          }
        }
        .on {
          margin-left: 8px;
          path{
            fill: $primary-blue;
          }
        }
        .off {
          margin-left: 8px;
          path{
            fill: $off;
          }
        }
      }
      .add {
        margin-top: 18px;
        margin-bottom: 28px;
        width: 100%;
        height: 40px;
        border: none;
        color: $white;
        background-color: $primary-blue;
        border-radius: $element-border-radius;
        cursor: pointer;
        justify-content: center;
      }
      .delete {
        margin-bottom: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        svg {
          cursor: pointer;
        }

        .users-wrapper {
          padding-top: 23px;
          &:first-of-type {
            border: 1px solid $dark-grey-blue;
          }
        }
      }
    }
  }
}

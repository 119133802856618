@import "../../../styles/shared";

.dropdown-menu {
  position: relative;
  //display: inline-block;
  display: flex;
  font-family: Arial, sans-serif;
  width:100%;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e5e7f0;
  border-radius: 4px;
  height: 36px;

  input[type='text'] {
    border: 0;
    text-indent: 18px;
    outline: none;
    box-sizing: border-box;
    width:100%;
    font-size: $size14;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &::placeholder {
      color: black;
    }

    .has-error {
      border: 1px solid $required-red;
    }
    
  }
  .dropdown-toggle {
    // position: absolute;
    // top: 0.5em;
    // right: 0;
    margin: 0 15px 0 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  svg {
    width: 12px;
    height: 12px;
    path {
      fill: $primary-black;
    }
  }

  .icon-dropdown-open {
    transform: rotate(180deg);
  }

  .dropdown-content {
    margin-top: 4px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    width: 100%;
    border: 1px solid #e5e7f0;
    border-radius: 4px;
    z-index: 1;
    padding: 8px 18px;
    max-height: 160px;
    overflow-y: auto;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    box-shadow: 0px 1px 6px #00000024;

    .select-all {
      cursor: pointer;
      margin-bottom: 1em;

      input[type='checkbox'] {
        margin-right: 0.8em;
      }
    }

    .checkbox-option {
      margin-bottom: 1em;

      input[type='checkbox'] {
        margin-right: 0.8em;
      }
    }
  }
}
@import "../../../styles/shared";

.radio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $size8;

  .radio-switch {
    width: $size14;
    height: $size14;
    border: 1px solid $dark-grey-blue;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .inner-radio-circle {
    width: $size4;
    height: $size4;
    border: 1px solid $white;
    border-radius: 50%;
    background-color: $white;
  }

  .active-radio-switch {
    background-color: $primary-blue;
  }

  .disabled-radio-switch {
    opacity: 0.7;
    pointer-events: none;
  }

  .radio-text {
    font-size: $size14;
    color: $primary-black;
  }
}

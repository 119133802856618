@import '../../styles/shared';

.spareparts-details-modal-wrapper {
  .page-modal-wrapper {
    .page-modal {
      .page-header {
        .page-title {
          display: none;
        }

        .page-header-content {
          margin-right: 0 !important;
          width: 100%;

          .spareparts-header {
            display: flex;
            align-items: center;
            gap: $size16;
            height: 100%;
            width: 100%;
            cursor: pointer;

            .back-button {
              display: flex;
              align-items: center;
              padding-left: $size16;
              width: 24px;
              height: 24px;

              .back-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                flex-shrink: 0;
              }
            }

            .title {
              margin: 0 $size16 0 0;
              font-weight: 500;
              font-size: $size14;
            }
          }

          .page-close {
            display: none;
          }
        }
      }

      .page-content {
        padding: 0 $size16;
        height: calc(100% - 69px);

        .spareparts-details-modal-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          min-height: 540px;

          .product-type-card {
            display: flex;
            align-items: center;
            height: 96px;
            background-color: $light-grey-blue;
            border-radius: $element-border-radius;
            box-sizing: border-box;
            flex-shrink: 0;

            .image-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 $size16 0 $size16;
              height: 64px;
              width: 64px;
              background-color: $white;
              border-radius: $element-border-radius;
              flex-shrink: 0;

              img {
                width: 44px;
                height: 44px;
              }
            }

            .product-type-name {
              font-size: $size16;
              color: $primary-black;
              font-weight: 700;
              margin: 0 $size16 0 0;
            }
          }

          .model-select-title {
            font-size: $size14;
            margin: $size16 0 $size8 0;
            font-weight: 500;
            color: $primary-black;
          }

          .click-outside-wrapper {
            margin: 0 0 $size32 0;
            border: 1px solid $medium-grey-blue;
            box-shadow: none;
            max-width: 100%;
            cursor: pointer;

            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }         

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }

              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }

            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 390px;
              position: relative;
              overflow: overlay;

              .options-filter {
                width: 100%;
                height: 100%;
                background-color: $white;
                z-index: 1;

                .search {
                  background-color: $light-grey-blue;
                  border-radius: $element-border-radius;
                  margin: $size16 $size16 $size4 $size16 !important;
                  box-shadow: none !important;
                  z-index: 1;

                  .search-icon {
                    display: none;
                  }
                }
              }

              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }

          .fit-within-page {
            width: unset;
            height: unset;
            min-height: unset;
          }

          .table-wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 0 $size24 0;
            box-sizing: border-box;
                
            .product-header-wrapper {
              display: flex;
              align-items: center;
              height: 48px;
              margin-bottom: $size16;
              box-sizing: border-box;
              width: 100%;

              .image-wrapper {
                width: 48px;
                height: 48px;
                justify-content: center;
                background-color: $white;
                border-radius: $element-border-radius;
                flex-shrink: 0;
                margin: 0 $size8 0 0;

                img {
                  width: 48px;
                  height: 48px;
                }
              }

              .product-name-wrapper {
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                gap: $size4;
                margin: 0;

                .product-name {
                  color: $primary-black;
                  font-weight: 700;
                  font-size: $size16;
                }

                .product-quantity {
                  color: $primary-blue;
                  font-size: $size14;
                }
              }
            }

            .search-with-filter-wrapper {
              width: 100%;
              border-radius: $element-border-radius;
              margin-bottom: $size24;

              .search-wrapper {
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
              }

              .filter-button-wrapper {
                display: none;
              }
            }

            .spare-parts-card {
              width: 100%;
              display: flex;
              height: max-content;
              padding: $size16 0;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              justify-content: center;
              gap: $size8;
              border-bottom: 1px solid $medium-grey-blue;

              .card-item {
                display: flex;
                align-items: center;

                .card-item-title {
                  width: 25%;
                  font-size: $size14;
                  font-weight: 700;
                  color: $primary-black;
                  margin: 0;
                }

                .card-item-body {
                  width: 75%;
                  font-size: $size14;
                  color: $primary-black;
                  margin: 0 0 0 $size8;
                  display: flex;
                  align-items: center;
                  gap: $size8;

                  p {
                    margin: 0;
                  }

                  .image-wrapper {
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                      width: 40px;
                      height: 40px;
                      object-fit: cover;
                      flex-shrink: 0;
                    }
                  }

                  .action-button,
                  .cancel-button {
                    height: $size24;
                  }

                  .components-list-wrapper {
                    display: flex;
                    width: 100%;
                    color: $dark-grey-blue;
                    margin-left: $size16;

                    .components-list {
                      list-style: none;
                      margin: 0;
                      padding: 0;
                      display: flex;
                      flex-direction: column;
                      gap: $size4;
                      align-items: flex-start;
                      justify-content: center;
                      width: max-content;

                      li::before {
                        content: "\2022";
                        color: $primary-blue;
                        font-weight: bold;
                        font-size: $size16;
                        display: inline-block;
                        width: 12px;
                        margin-left: -16px;
                        align-self: flex-start;
                      }

                      .component-wrapper {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        .component-description {
                          margin: 0;
                        }
                      }                
                    }
                  }
                }
              }
            }

            .buttons-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: $size24;
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }
}
@import "../../styles/shared";

.software-version-wrapper {
  padding: $size24 $size16;

  .list-wrapper {
    padding: 0 0 $size14 0;

    .list-info {
      width: 100%;

      .section-title {
        margin: 0 0 $size16 0;
        color: $dark-grey-blue;
        font-size: $size14;
        text-align: left;
        font-weight: 500;
      }

      .data {
        display: flex;
        gap: $size8;
        width: 100%;
        margin-bottom: $size8;

        span {
          text-align: left;
          font-size: $size14;
          color: $dark-grey-blue;
        }

        .label {
          width: 25%;
          color: $primary-black;
          font-weight: bold;
          line-height: $size18;
        }

        .label-data {
          width: 50%;
          line-height: $size18;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: $size16 0;
    display: flex;
    flex-direction: column;
    gap: $size32;

    .list-wrapper {
      padding: 0 $size16;
      flex-direction: column;

      .list-info {
        width: 100%;

        .section-title {
          margin: 0 0 $size16 0;
        }

        .data {
          .label {
            width: 50%;
          }
          
          .label-data {
            text-align: right;
          }
        }
      }
    }
  }
}

@import "../../../styles/shared";

.empty-page-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 530px;
  background-color: $white;
  border-radius: $element-border-radius;
  
  .title {
    font-size: $size24;
    font-weight: bold;
    margin: 8px 16px 8px 16px;
  }

  .subtitle {
    opacity: 0.8;
    margin: 0px 16px 8px 16px;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
}

@import "../../styles/shared.scss";

.add-edit-branch-modal {

  .page-modal {
    height: max-content;

    .add-edit-branch-modal-content {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size16;

      .input-wrapper {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;

        .input-option {
          margin: 0;
          font-weight: 500;
        }
        
        .name-input {
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
        }

        .click-outside-wrapper {
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          .height {
            div {
              color: $primary-black;
              font-size: $size14;
            }

            svg {
              width: 12px;
              height: 12px;
              path {
                fill: $primary-black;
              }
            }

            .icon-dropdown-open {
              transform: rotate(180deg);
            }
          }

          .options-wrapper {
            width: 363px;
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 160px;
            position: absolute;
            overflow: overlay;
            z-index: 2;

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }

        .has-error {
          border: 1px solid $required-red;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;

      .add-edit-branch-modal-content {
        font-size: $size14;

        .input-wrapper {
          flex-direction: column;
          gap: $size8;
          width: 100%;
          box-sizing: border-box;

          .click-outside-wrapper {
            max-width: 100%;

            .options-wrapper {
              width: 100% !important;
              position: relative !important;
            }
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

@import "../../styles/shared";

.hidden {
  display: none;
}

.products-document-wrapper {
  margin: 0 30px;
}

.documents-wrapper {
  
  .divider {
    margin-bottom: $size16;
    height: 1px;
    background-color: $scrollbar-grey;
    display: flex;
  }

  .documents-content-wrapper {

    .actions-wrapper {
      height: $size36;
      width: 100%;
      margin-top: $size16;
      margin-bottom: $size16;
      box-sizing: border-box;
    }

    .list-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: $element-border-radius;
      background-color: $white;
      margin-top: $size16;
      
      .item-wrapper {
        display: flex;
        height: 50px;
        width: 100%;
        padding: 0 $size16;
        align-items: center;
        border-bottom: 1px solid $scrollbar-grey;
        box-sizing: border-box;
        gap: $size8;
        cursor: pointer;

        .image-wrapper {
          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }

        .rounded-image {
          background-color: $light-grey-blue;
          border-radius: 50%;
        }

        .name-wrapper {
          display: block;
          margin-right: $size16;

          .title {
            margin: 0;
            font-size: $size14;
            font-weight: 500;
            opacity: 1;
          }

          .matching-text {
            background-color: transparent;
          }

          .non-matching-text {
            opacity: 0.5 !important;
          }

          .section {
            margin: 0;
            font-size: $size14;
            font-weight: 400;
            opacity: 0.8;
          }
         }
      }

      .no-border {
        border-bottom: none;
      }  
    }

    .buttons-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      margin-bottom: 20px;
    }

    .with-margin-top {
      margin-top: $size16;
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .documents-content-wrapper {

      .actions-wrapper {
        padding: 0 $size16;
      }

      .list-wrapper {
        gap: $size4;
        background-color: $grey-background;

        .item-wrapper {
          min-height: 48px;
          height: max-content;
          gap: $size8;
          border-bottom: none;
          background-color: $white;
          padding: 0 $size8;

          .name-wrapper {
            margin: 5px $size8 5px 0;
            width: calc(100% - 72px);
          }
        } 
      }
    }
  }
}

@import '../../styles/shared';

.product-details-modal-wrapper {
  .page-modal-wrapper {
    .page-modal {
      .page-header {
        .page-title {
          display: none;
        }

        .page-header-content {
          margin-right: 0 !important;
          width: 100%;

          .product-header {
            display: flex;
            align-items: center;
            gap: $size16;
            height: 100%;
            width: 100%;
            cursor: pointer;

            .back-button {
              display: flex;
              align-items: center;
              padding-left: $size16;
              width: 24px;
              height: 24px;

              .back-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                flex-shrink: 0;
              }
            }

            .title {
              margin: 0 $size16 0 0;
              font-weight: 500;
              font-size: $size14;
            }
          }

          .page-close {
            display: none;
          }
        }
      }

      .page-content {
        padding: 0 $size16;
        height: calc(100% - 69px);

        .product-details-modal-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          min-height: 540px;

          .product-card {
            display: flex;
            align-items: center;
            height: 80px;
            background-color: $light-grey-blue;
            border-radius: $element-border-radius;
            box-sizing: border-box;
            flex-shrink: 0;

            .image-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 $size16 0 $size16;
              height: 64px;
              width: 64px;
              background-color: $white;
              border-radius: $element-border-radius;
              flex-shrink: 0;

              img {
                width: 44px;
                height: 44px;
                object-fit: cover;
              }
            }

            .product-wrapper {
              display: block;
              margin-right: $size8;

              .product-name {
                font-size: $size16;
                color: $primary-black;
                font-weight: 700;
                margin: 0;
              }

              .product-serials {
                font-size: $size14;
                color: $dark-grey-blue;
                margin: 0;
              }
            }
          }

          .serial-select-title {
            font-size: $size14;
            margin: $size24 0 $size8 0;
            font-weight: 500;
            color: $primary-black;
          }

          .click-outside-wrapper {
            margin: 0 0 $size24 0;
            border: 1px solid $medium-grey-blue;
            box-shadow: none;
            max-width: 100%;
            cursor: pointer;

            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }         

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }

              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }

            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 390px;
              position: relative;
              overflow: overlay;

              .options-filter {
                width: 100%;
                height: 100%;
                background-color: $white;
                z-index: 1;

                .search {
                  background-color: $light-grey-blue;
                  border-radius: $element-border-radius;
                  margin: $size16 $size16 $size4 $size16 !important;
                  box-shadow: none !important;
                  z-index: 1;

                  .search-icon {
                    display: none;
                  }
                }
              }

              .option-with-icon {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: $size8;
                font-size: $size14;
                color: $primary-black;

                .online-access {
                  margin-right: $size16;

                  .no-access {
                    opacity: 0.2;

                    path {
                      fill: $primary-black;
                    }
                  }
                }
              }

              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }

          .fit-within-page {
            width: unset;
            height: unset;
            min-height: unset;
          }

          .linked-products-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size4;
            width: 100%;
            box-sizing: border-box;
            padding: $size8 0 $size16 0;
    
            .linked-product {
              display: flex;
              align-items: center;
              height: 32px;
              width: 100%;
              padding: 0 $size16;
              border-radius: $element-border-radius;
              background-color: $light-grey-blue;
              box-sizing: border-box;
              
              .product-image {
                width: 32px;
                height: 32px;
                justify-content: center;
                border-radius: 4px;
                flex-shrink: 0;
                margin: 0 16px 0 0;
              }
    
              .product-nickname {
                color: $primary-black;
                font-size: $size14;
                width: 40%;
              }
    
              .product-serial {
                color: $dark-grey-blue;
                font-size: $size12;
              }
            }
    
            .with-navigation {
              cursor: pointer;
    
              .product-serial {
                color: $primary-blue;
              }
            }
          }
    
          .content-limiter {
            font-size: $size14;
            color: $primary-blue;
            margin: 0 0 $size32 0;
            text-transform: capitalize;
            cursor: pointer;
          }
          
          .activation-box-text {
            display: flex;
            gap: $size8;
            margin: 0 0 $size24;
            padding: $size16;
            border-radius: $element-border-radius;
            background-color: $transparent-blue;
            color: $primary-black;
        
            .icon-wrapper {
              display: flex;
              align-items: flex-start;
            }
        
            .activation-wrapper {
              display: inline;
              flex-direction: column;
              gap: $size8;
              font-size: $size14;
              padding-top: $size4;
        
              .title {
                font-weight: 700;
              }
    
              .activation-steps {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }

          .product-details-wrapper {
            display: flex;
            flex-direction: column;
            margin: 0 0 $size24 0;
            box-sizing: border-box;
            
            .product-status-wrapper {
              display: flex;
              align-items: flex-start;
              margin-bottom: $size16;

              .image-wrapper {
                width: 64px;
                height: 64px;
                justify-content: center;
                background-color: $white;
                border-radius: $element-border-radius;
                flex-shrink: 0;
                margin: 0 $size16 0 0;

                img {
                  width: 44px;
                  height: 44px;
                  object-fit: cover;
                }
              }

              .product-serial-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size4;
                margin: 0 $size16 0 0;
                align-self: center;

                .product-serial {
                  color: $primary-black;
                  font-size: $size14;
                }

                .product-serial-subtitle {
                  font-size: $size16;

                  .nickname-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: $size4;
                    font-size: $size16;
    
                    .product-nickname {
                      color: $primary-black;
                      min-height: 20px;
                      font-weight: 700;
                    }
    
                    .nickname-actions-wrapper {
                      display: flex;
                      gap: $size8;
    
                      .delete-nickname {
                        color: $strong-blue;
                        cursor: pointer;
                      }
      
                      .edit-nickname {
                        color: $primary-blue;
                        cursor: pointer;
                      }
                    }
                  }

                  .nickname-add-wrapper {
                    color: $primary-blue;
                    font-size: $size16;
                    cursor: pointer;
                  }
    
                  .disabled {
                    pointer-events: none;
                    opacity: 0.6;
                  }
                }

                .product-status-legend {
                  display: flex;
                  align-items: center;
                  gap: $size16;
                  margin-top: $size4;

                  .tooltiped-icon-wrapper {
                    .tooltip {
                      bottom: 140%;
                    }

                    .unavailable {
                      opacity: 0.2;

                      path {
                        fill: $primary-black;
                      }
                    }
                  }
                }
              }
            }

            .with-bottom-margin {
              margin-bottom: $size24;
            }

            .section-title {
              font-size: $size14;
              color: $primary-black;
              font-weight: 500;
              margin: $size24 0 $size16 0;
            }

            .with-extra-information {
              margin-bottom: $size4;
            }

            .product-software-wrapper {
              display: flex;
              margin-bottom: $size24;

              .update-wrapper {
                display: flex;
                width: 100%;
                background-color: $transparent-blue;
                padding: 0 $size16 $size16 $size16;

                .info-wrapper {
                  display: flex;
                  flex-direction: column;
                  font-size: $size14;
                  gap: $size4;
                  width: 100%;
                  box-sizing: border-box;

                  .software-title {
                    margin-top: $size16 !important;
                  }

                  .title-version {
                    margin: 0;
                    color: $dark-grey-blue;

                    .current-version {
                      color: $primary-black;
                    }

                    .latest-version {
                      color: $primary-blue;
                    }
                  }

                  .submit-button {
                    margin-top: $size16;
                    width: 100%;
                  }
                }

                .update-dot {
                  margin: $size16 0;
                  width: 6px;
                  height: 6px;
                  border-radius: 3px;
                  background-color: $red;
                  margin-left: auto;
                }
              }
            }

            .product-cycles-wrapper {
              display: flex;
              flex-direction: column;
              border-top: 1px solid $medium-grey-blue;
              margin: 0 0 $size24 0;
              width: 100%;

              .extra-information {
                margin: 0 0 $size16 0;
                font-size: $size12;
                color: $dark-grey-blue;
              }

              .cycles-wrapper {
                display: inline-grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: $size4;
                width: 100%;

                .cycle-item-wrapper {
                  display: flex;
                  flex-grow: 1;
                  height: 24px;
                  padding: 0 $size8;
                  align-items: center;
                  justify-content: space-between;
                  box-sizing: border-box;
                  cursor: pointer;
                  background-color: #26c875;
                  border-radius: $element-border-radius;

                  .title {
                    margin: 0;
                    font-size: $size12;
                    font-weight: 700;
                    color: $white;

                    @media screen and (max-width: 375px) {
                      font-weight: 500;
                    }
                  }
                  
                  .time {
                    margin: 0;
                    font-size: $size12;
                    color: $white;
                    opacity: 0.6;
                  }
                }

                .cycle-pending {
                  background-color: $tracking-grey !important;
                }

                .cycle-complete {
                  background-color: #26c875 !important;
                }

                .cycle-fault {
                  background-color: #e2445c !important;
                }

                .cycle-aborted,
                .cycle-interrupted,
                .stop-button-pressed {
                  background-color: #fdab3d !important;
                }
              }

              .ic-track-cycles-wrapper {
                .cycle-tracking-completed {
                  background-color: $tracking-green;
                }
    
                .cycle-tracking-interrupted {
                  background-color: $tracking-yellow;
                }
    
                .cycle-tracking-error {
                  background-color: $tracking-red;
                }
    
                .cycle-tracking-in-progress {
                  background-color: $tracking-grey;
                }
    
                .icon-title-wrapper {
                  display: flex;
                  align-items: center;
                  gap: $size4;
                  justify-content: space-between;
                  width: 100%;
                  
                  .icon {
                    display: flex;
                    gap: 5px;
                  }

                  svg {
                    flex-shrink: 0;
                  }
                }
                
                .title,
                .cycle-num{
                  color: $primary-black !important;
                  display: -webkit-box;
                  -webkit-line-clamp: 1;
                  -webkit-box-orient: vertical;
                  margin: 0;
                  font-size: $size12;
                  font-weight: 500;
                }
                
                .cycle-num {
                  overflow: hidden;
                  text-overflow: ellipsis;
                  
                  @media screen and (max-width: 440px) {
                    display: none;
                  }
                }
              }

              .cycles-navigation-buttons-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size8;
                margin-top: $size16;
              }

              .no-cycles-wrapper {
                display: flex;
                align-items: center;
                border-radius: $element-border-radius;
                width: 100%;

                .disclaimer {
                  margin: 0;
                  font-size: $size14;
                  font-style: italic;
                  color: $dark-grey-blue;
                }
              }

              .view-cycles-button {
                margin-top: $size16;
              }
            }

            .product-documents-wrapper {
              display: flex;
              flex-direction: column;
              border-top: 1px solid $medium-grey-blue;
              margin: 0 0 $size24 0;
              width: 100%;

              .documents-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size16;
                width: 100%;

                .document-item-wrapper {
                  display: flex;
                  width: 100%;
                  align-items: flex-start;
                  box-sizing: border-box;
                  gap: $size8;
                  cursor: pointer;

                  .image-wrapper {
                    display: flex;
                    width: 40px;
                    height: 40px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                  }

                  .rounded-image {
                    background-color: $light-grey-blue;
                    border-radius: 50%;
                  }

                  .title-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: $size4;

                    .title {
                      margin: 0;
                      font-size: $size14;
                      color: $primary-black;
                    }

                    .section {
                      margin: 0;
                      font-size: $size12;
                      color: $dark-grey-blue;
                    }
                  }
                  
                }
              }

              .no-documents-wrapper {
                display: flex;
                align-items: center;
                border-radius: $element-border-radius;
                width: 100%;

                .disclaimer {
                  margin: 0;
                  font-size: $size14;
                  font-style: italic;
                  color: $dark-grey-blue;
                }
              }

              .view-documents-button {
                margin-top: $size16;
              }
            }

            .product-videos-wrapper {
              display: flex;
              flex-direction: column;
              border-top: 1px solid $medium-grey-blue;
              margin: 0 0 $size24 0;
              width: 100%;

              .videos-wrapper {
                display: flex;
                gap: $size24;
                width: 100%;

                .video-item-wrapper {
                  display: flex;
                  flex-direction: column;
                  width: 100%;
                  max-width: 263px;
                  border-radius: $element-border-radius;
                  cursor: pointer;

                  .thumbnail-wrapper {
                    position: relative;
                    display: flex;
                    width: 100%;
                    height: 160px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: $element-border-radius;

                    .thumbnail-icon {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translateX(-50%) translateY(-50%);
          
                      svg{
                        width: $size32;
                        height: $size32;
                      }
                    }

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      border-radius: $element-border-radius;
                    }
                  }

                  .title {
                    margin-top: $size16;
                    font-weight: 700;
                    text-align: left;
                    font-size: $size16;
                    word-wrap: break-word;
                  }
                }
              }

              .no-videos-wrapper {
                display: flex;
                align-items: center;
                border-radius: $element-border-radius;

                .disclaimer {
                  margin: 0;
                  font-size: $size14;
                  font-style: italic;
                  color: $dark-grey-blue;
                }
              }

              .view-videos-button {
                margin-top: $size16;
              }
            }

            .product-related-products-wrapper {
              display: flex;
              flex-direction: column;
              border-top: 1px solid $medium-grey-blue;
              margin: 0 0 $size24 0;
              width: 100%;

              .related-products-wrapper {
                display: flex;
                gap: $size24;
                width: 100%;
              }

              .no-related-products-wrapper {
                display: flex;
                align-items: center;
                border-radius: $element-border-radius;

                .disclaimer {
                  margin: 0;
                  font-size: $size14;
                  font-style: italic;
                  color: $dark-grey-blue;
                }
              }

              .view-store-button {
                margin-top: $size16;
              }
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999 !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: unset;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 425px) {
    .video-item-wrapper {
      width: 100% !important;
      max-width: unset !important;
    }

    .simple-button {
      width: 100% !important;
    }
  }
}
@import '../../styles/shared';

.report-problem-modern-wrapper {

  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .actions-wrapper {
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;

    .action-button {
      margin-left: auto;
    }
  }

  .selectable-wrapper {
    max-width: 24px;
    user-select: none;

    .checkbox-wrapper {
      gap: 0;
    }
  }

  .date-wrapper {
    max-width: 150px;
  }

  .serial-number-wrapper {
    max-width: 150px;
  }

  .subject-wrapper {
    max-width: 250px;
  }

  .status-wrapper {
    display: flex;
    align-items: center;

    .status-open {
      color: $success-green;
      display: flex;
      align-items: center;
      gap: $size4;
    }

    .status-close {
      display: flex;
      align-items: center;
      color: $dark-grey-blue;
      gap: $size4;
    }

    p {
      margin: 0;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .actions-wrapper {
      flex-direction: column;
      gap: $size16;
      height: unset;
      padding: 0 $size16;

      .action-button {
        margin-left: unset;
        width: 100%;
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 35%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 65%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;

          .card-description {
            display: flex;
            gap: $size8;
            align-items: center;
            text-align: right;
          }

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }

          .card-item-body-status {
            display: flex;
            gap: $size8;

            .status-open {
              color: $success-green;
              display: flex;
              align-items: center;
            }
        
            .status-close {
              display: flex;
              align-items: center;
              color: $dark-grey-blue;
            }

            p {
              margin: 0;
            }
          }
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
      margin-bottom: $size16;
    }
  }
}

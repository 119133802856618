@import "../../../styles/shared";

.notifications-timeline-page {
  position: absolute;
  width: 100%;
  height: 100%;

  .notifications-overflow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $primary-black;
    opacity: 0.32;
    z-index: 99;
  }

  .notifications-timeline-wrapper {
    position: fixed;
    top: 69px;
    right: 0;
    height: calc(100vh - 70px);
    width: 420px;
    background-color: $white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    overflow: scroll;
    z-index: 100;

    .notifications-header {
      display: flex;
      align-items: center;
      position: sticky;
      z-index: 101;
      top: 0;
      background-color: $white;
      height: 72px;
      padding: $size16 $size24 $size16 $size24;
      border-bottom: 1px solid $light-grey-blue;
      box-sizing: border-box;
      cursor: pointer;

      .notifications-back-button {
        display: flex;
        align-items: center;
        height: 100%;

        .fillable-area {
          fill: $primary-black !important;
        }

        .title {
          margin: 0 0 0 0;
          font-size: $size24;
          font-weight: 500;
          color: $primary-black;
        }

        .counter-badge {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 8px;
          height: $size24;
          width: $size24;
          border-radius: 12px;
          background-color: $primary-blue;
          color: $white;
          font-size: $size14;
        }
      }

      .notifications-mark-as-read {
        margin-left: auto;
        display: flex;
        align-items: center;
        font-size: $size14;
        color: $strong-blue;
        font-weight: 500;
        height: 24px;
        padding: 0 1rem;
        border-radius: 4px;
        cursor: pointer;
      }

      .notifications-show-unread {
        display: flex;
        align-items: center;
        margin-left: 1em;

        .input-option {
          margin: 0 $size8 0 0;
          font-size: $size14;
          color: $primary-black;
        }

        .status-option {
          display: flex;
          align-items: center;
        }
      }
    }
    
    @media (max-width: 500px) {
      .notifications-header {
        flex-wrap: wrap;
        height: auto;
        margin-top: 1.5em;
        margin-bottom: 1em;
    
        .notifications-back-button {
          order: 1;
          flex: 1 100%;
          margin-bottom: 1.5em;
        }
    
        .notifications-mark-as-read {
          order: 2;
          margin-right: 1rem;
        }
    
        .notifications-show-unread {
          order: 2;
          margin-right: 0;
        }
      }
    }

    .notifications-body {
      min-height: calc(100% - 121px);
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0 0 $size16 0;
      gap: $size4;
      box-sizing: border-box;

      .notification-date {
        color: $dark-grey-blue;
        text-transform: uppercase;
        margin: 0 0 $size8 0;
      }

      .with-margin-top {
        margin-top: $size32;
      }

      .notifications-date-wrapper {
        display: flex;
        flex-direction: column;
        gap: $size4;
      }

      .notification-item {
        position: relative;
        width: 100%;
        min-height: 85px;
        display: flex;
        flex-direction: column;
        border-radius: $element-border-radius;
        padding: $size16 $size8 $size16 $size24;
        box-sizing: border-box;
        cursor: pointer;

        .title-wrapper {
          display: flex;
          align-items: center;
          gap: $size8;
          margin-bottom: $size8;

          .notification-title {
            margin: 0;
            font-size: $size16;
            font-weight: 500;
            color: $primary-black;
          }

          .notification-sn {
            opacity: 0.7;
            margin: 0;
            font-size: $size14;
            color: $primary-black;
          }
        }


        .notification-diff {
          opacity: 0.7;
          margin: $size8 0 0 0;
          font-size: $size14;
          color: $primary-black;
        }

        .cf-notification-body {
          display: flex;
          align-items: center;
          background-color: $transparent-red;
          border: 1px solid $medium-grey-red;
          border-radius: 12px;
          color: $medium-grey-red;
          font-size: $size14;
          padding: 0 $size8;
          box-sizing: border-box;
          width: max-content;
          cursor: pointer;
        }

        .notification-body {
          display: flex;
          flex-direction: column;
          gap: $size8;
          border-radius: $element-border-radius;
          font-size: $size14;

          .simple-button {
            height: 24px;
          }

          .one-line-format {
            display: inline-block;
            .custom-link {
              text-decoration: none;
              color: inherit;
            }
            .firmware-update {
              color: skyblue;
              font-weight: bold;
              display: contents;
            }
          }

          cursor: pointer;
        }

        .reminder-space {
          letter-spacing: -0.3px
        }

        .is-unread {
          position: absolute;
          top: 6px;
          right: 8px;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: $success-green;
        }

        p {
          margin: 0;
        }
      }

      .unread {
        background-color: $light-grey-blue;
      }

      .notification-item:last-of-type {
        border-bottom: none;
      }

      .no-unread-notifications {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        font-weight: bold;
        height:inherit;

        p {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin: 0;
        }
      }
    }

    .spinner-wrapper {
      .loading-spinner {
        width: 2em;
        height: 2em;
        left: calc(50% - 1em);
      }
    }
  }

  @media all and (max-width: 768px) {
    .notifications-timeline-wrapper {
      width: 100%;
      top: 50px;
      height: calc(100vh - 50px);
    }
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
}
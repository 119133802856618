@import "../../styles/shared";

.notes-history-wrapper {
  padding: $size24 $size16;

  .notes-history-table {
    .table-row:not(:first-of-type) {
      .table-column:nth-of-type(2) {
        .text {
          color: $medium-grey-red;
        }
      }
    }
  }

  .no-notes-wrapper {
    display: flex;
    align-self: flex-start;
    width: 252px;
    align-items: center;
    background-color: $light-grey-blue;
    border-radius: $element-border-radius;
    height: 52px;

    .disclaimer {
      margin: 0 $size16;
      font-size: $size14;
      font-style: italic;
      color: $dark-grey-blue;
    }
  }

  .empty-list {
    min-height: 250px;
    
    .title {
      font-size: $size18;
    }
  }

  @media all and (max-width: 768px) {
    .mobile-content {
      .card {
        border-bottom: 1px solid $medium-grey-blue;
        width: 100%;
        min-height: $size48;
        height: max-content;
        margin-top: $size4;
        background-color: $white;
        padding: $size16;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;

        .card-item {
          display: flex;
          align-items: flex-start;

          .card-item-title {
            width: 110px;
            font-size: $size14;
            font-weight: bold;
            color: $primary-black;
            margin: 0;
          }

          .card-item-body {
            width: 143px;
            font-size: $size14;
            color: $primary-black;
            margin: 0 0 0 $size8;
            display: flex;
            align-items: flex-start;
          }

          .cycle-fault {
            color: $medium-grey-red;
          }
        }

        .align-center {
          align-items: center;
        }
      }
    }
  }
}

@import '../../../styles/shared';

.cycle-details-modal {
  
  .page-modal {
    .page-header {
      .page-title {
        display: none;
      }

      .page-header-content {
        margin-left: $size24;
        width: 100%;
        justify-content: space-between;

        .download-cycle-details-wrapper {
          display: flex;
          align-items: center;
          gap: $size8;
          background-color: transparent;
          cursor: pointer;
          outline: none;
          border: none;
        }
      }      
    }

    .page-content {
      padding: 0;

      .cycle-details-modal-content {
        padding: $size16 $size24;

        .device-info-wrapper {
          display: flex;
          gap: $size24;

          .product-image {
            height: 105px;
            width: 105px;
            object-fit: contain;
            flex-shrink: 0;
          }

          .product-identification-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;

            .product-name {
              font-size: 1.7rem;
              margin: 0;
            }

            .product-serial-number {
              font-size: 1.3rem;
              margin-top: 0;
            }

            .cycle-time-wrapper {
              display: flex;
              gap: $size24;
              width: 100%;

              .vertical-separator {
                height: 100%;
                width: 1px;
                background-color: $medium-grey-blue;
              }

              .product-date,
              .product-time,
              .product-cycle-number,
              .cycle-status {
                h3,
                p {
                  white-space: nowrap;
                  margin: 0;
                  font-size: 1.15rem;
                }
          
                h3 {
                  color: $dark-grey-blue;
                }
              }

              .troubleshoot-button {
                margin-left: auto;
              }
            }
            
          }
        }

        .report-graph {
          height: 400px;
          margin-top: 30px;
          background-color: $light-grey-blue;
          padding-top: 20px;
          padding-bottom: 20px;
          padding-right: 10px;
    
          .recharts-wrapper {
            .xAxis {
              .recharts-cartesian-axis-ticks {
                .recharts-cartesian-axis-tick {
                  &:first-child,
                  &:last-child {
                    display: none;
                  }
                }
              }
            }
          }
    
          .report-graph-legend-wrapper {
            @include display-flex;
            margin-left: 20px;
    
            div {
              @include display-flex;
              align-items: center;
    
              &:not(:first-child) {
                margin-left: 20px;
              }
    
              p {
                margin: 0;
                font-size: 12px;
              }
    
              .legend-dot {
                height: 8px;
                width: 30px;
                border-radius: 50px;
                margin-right: 5px;
              }
            }
          }
    
          .report-graph-tooltip {
            background-color: $white;
            border-radius: $element-border-radius;
            box-shadow: $modal-box-shadow;
            padding: 10px;
    
            p {
              margin: 0;
              font-size: 12px;
            }
    
            .tooltip-temperature,
            .tooltip-temperature2,
            .tooltip-pressure {
    
              &:not(:last-child) {
                margin-bottom: 10px;
              }
    
              .temperature {
                color: $primary-blue;
                font-weight: bold;
              }
    
              .temperature2 {
                color: $soft-blue;
                font-weight: bold;
              }
    
              .pressure {
                color: $green;
                font-weight: bold;
              }
            }
          }

          .no-cycle-data {
            min-height: 150px;
          }
        }

        .no-data {
          padding: 0 !important;
          background-color: $white;
          height: 380px;
        }
    
        .extra-info {
          margin-top: 20px;
          column-count: 3;
    
          @media screen and (max-width: 768px) {
            column-count: 1;
          }
    
          div {
            padding: 8px;
          }
        }
      }
    }

    .page-footer {
      .cycles-navigation-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: $size8;
      }
    }

    @media screen and (max-width: 768px) {
      .page-content {

        .device-info-wrapper {
          flex-direction: column;
          gap: $size4 !important;

          .image-mobile-wrapper {
            display: flex;
            gap: $size16;
            margin-bottom: $size24;

            .product-image {
              height: 52px !important;
              width: 56px !important;
            }
  
            .product-name-wrapper {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
  
              .product-name {
                margin: 0;
                font-size: 1.7rem;
              }
  
              .product-serial-number {
                margin: 0;
                font-size: 1.3rem;
              }
            }
          }          
        }

        .product-details-mobile-wrapper {
          display: flex;
          gap: $size16;
          flex-direction: column;

          .product-date-mobile,
          .product-time-mobile,
          .product-cycle-number,
          .cycle-status {
            display: flex;
            gap: $size8;

            h3,
            p {
              white-space: nowrap;
              margin: 0;
              font-size: 1.15rem;
            }

            h3 {
              color: $dark-grey-blue;
            }
          }

          .troubleshoot-button {
            width: 100%;
          }
        }

        .with-margin-top {
          margin-top: $size8;
        }
      }
    }
  }
}

.without-footer {
  .page-modal {
    .page-content {
      height: calc(100% - 69px);
    }
  
    .page-footer {
      display: none;
    }
  }  
}

@import '../../../styles/shared';

.Pagination-Wrapper {
  margin-top: 30px;
  .cursor-pointer {
    cursor: pointer;
  }
  height: 40px;
  .page-index-wrapper {
    max-width: 1224px;
    margin-top: 41px;
    height: 20px;

    .active {
      background-color: $primary-blue;
      border-radius: 50px;
      width: 20px;
      height: 20px;
      color: $white;
      text-align: center;
      cursor:unset;
      padding: 0;
    }
    div {
      line-height: 20px;
      padding: 0 5px 0 5px;
    }
  }
}

@import "../../styles/shared";

.online-access-wrapper {
  padding: $size24 $size16;

  .association-expand-header {
    padding: 0 $size16;
    height: 56px;
    box-sizing: border-box;
    cursor: pointer;

    .title {
      color: $primary-black;
      font-size: $size14;
      font-weight: 700;
    }
  }

  .association-expand-body {
    display: flex;
    flex-direction: column;
    padding: $size16;
    gap: $size4;
    width: 100%;
    box-sizing: border-box;

    .data {
      display: flex;
      gap: $size8;
      width: 100%;
      margin-bottom: $size8;
      text-align: left;
      font-size: $size14;
      color: $dark-grey-blue;
      box-sizing: border-box;

      .label {
        flex-shrink: 0;
        width: 25%;
        color: $primary-black;
        font-weight: 700;
      }

      .label-data {
        flex-shrink: 0;

        .close-icon {
          .fillable-area {
            fill: $danger-red !important;
          }
        }
      }

      .buttons {
        margin-left: auto;
        display: flex;
        gap: $size8;
      }

      .button-label-data {
        display: flex;
        gap: $size8;
        position: relative;
        margin-left: auto;
        top: -220px;
      }
    }
  }

  .highlight {
    background-color: $light-grey-blue;
  }

  .border-bottom {
    border-bottom: 1px solid $medium-grey-blue;
  }

  .empty-list {
    min-height: 250px;
    
    .title {
      font-size: $size18;
    }
  }

  @media screen and (max-width: 768px) {
    padding: $size16 0;
    display: flex;
    flex-direction: column;

    .association-expand-body {
      .data {
        justify-content: space-between;

        .label {
          width: unset;
        }
        
        .button-label {
          display: none;
        }

        .label-data {
          width: unset;
          text-align: right;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .button-label-data {
          width: 100% !important;
          position: initial !important;
          flex-direction: column;

          .association-button {
            width: 100%;
          }

          .remove-button {
            width: 100%;
          }
        }
      }
    }
  }
}

@import "../../styles/shared";

.firmware-latest-version-wrapper {
  padding: $size16;

  .instruction-box-text {
    display: flex;
    gap: $size8;
    margin: 0 0 $size24 0;
    padding: $size16;
    border-radius: $element-border-radius;
    background-color: $transparent-blue;
    color: $primary-black;

    .icon-wrapper {
      display: flex;
      align-items: flex-start;
    }

    .instruction-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size8;
      font-size: $size14;
      padding-top: $size4;

      .title {
        font-weight: 700;
      }
    }
  }

  .section-title {
    font-size: $size14;
    font-weight: 500;
    color: $dark-grey-blue;
    margin: $size24 0;
    text-transform: uppercase;
  }

  .firmware-documents-wrapper {
    margin-bottom: $size16;
    display: flex;
    flex-direction: column;
    gap: $size8;

    .firmware-documents {

      .name-wrapper {

        .doc-icon {
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 2px 3px #00000014;
          border-radius: 4px;
          opacity: 1;
        }

        .file-name {
          margin-left: $size8;
          font-size: $size14;
          color: $primary-black;
        }
      }

      .action-button {
        margin-left: auto;
      }
    }
  }

  .firmware-executables-wrapper {
    margin-bottom: $size16;
    display: flex;
    flex-direction: column;
    gap: $size24;

    .firmware-executable {
      display: flex;
      gap: $size16;
      align-items: center;

      .download-document {
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }

      .doc-icon {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 3px #00000014;
        border-radius: 4px;
        opacity: 1;
      }

      .file-name {
        font-size: $size14;
        color: $primary-black;
        font-weight: 500;
      }
    }
  }

  .firmware-videos-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: $size24;

    .section-title {
      font-size: $size14;
      font-weight: 500;
      color: $primary-black;
      margin: 0 0 $size8 0;
    }

    .videos-wrapper {
      display: flex;
      gap: $size24;
      width: 100%;
      margin: $size8 0 $size16 0;

      .video-item-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 263px;
        cursor: pointer;

        .thumbnail-wrapper {
          position: relative;
          display: flex;
          width: 100%;
          height: 160px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: $element-border-radius;

          .thumbnail-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            svg{
              width: $size32;
              height: $size32;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          margin: $size16 0 0 0;
          font-weight: 700;
          text-align: left;
          font-size: $size16;
          word-wrap: break-word;
        }
      }

      .no-videos-wrapper {
        display: flex;
        align-items: center;
        border-radius: $element-border-radius;

        .disclaimer {
          margin: 0;
          font-size: $size14;
          font-style: italic;
          color: $dark-grey-blue;
        }
      }
    }
  }

  .inner-separator {
    display: flex;
    height: 1px;
    background-color: $light-grey-blue;
    width: 100%;
  }

  @media screen and (max-width: 768px) {
    .instruction-box-text {
      margin: 0 -16px $size24 -16px;
    }

    .firmware-videos-wrapper {
      .videos-wrapper {
        flex-direction: column;
      }

      .simple-button {
        width: 263px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .firmware-videos-wrapper {
      .videos-wrapper {
        
        .video-item-wrapper {
          max-width: unset;
        }
      }

      .simple-button {
        width: 100%;
      }
    }
  }
}
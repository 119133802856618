@import "../../../styles/shared";

.dashboard-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: $size8 0 $size24 0;

  .dashboard-heading {
    font-size: $size24;
    font-weight: 700;
    color: $primary-black;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: $size16;
    margin: $size8 0 $size16 0;
    align-items: flex-start;
    padding: 0 $size16;

    .simple-button {
      width: 100%;
    }
  }
}

@import "../../styles/shared.scss";

.dealer-bulletins-wrapper {
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }
  
  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: $size8;
  }
  
  .download {
    cursor: pointer;
    background: transparent;
    border: 0px;
    width: 30px;
  }
  
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .image-wrapper {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }
  
  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }
  
  td {
    padding-left: 0px !important;
  }
  
  td:nth-child(1) {
    padding-left: $size16 !important;
    padding-right: 0px !important;
  }
  
  .pdf-icon {
    display: flex;
    width: $size32;
    height: $size32;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background-color: #f5f6fa;
    border-radius: 50%;
  }

  @media screen and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }
    
    .actions-wrapper {
      height: unset;
      flex-direction: column;
      padding: 0 $size16;
      box-sizing: border-box;

      .order-by-mobile-wrapper {
        margin-top: $size16;
        height: $size36;
        width: 100%;

        .click-outside-wrapper {
          margin: 0 $size16 0 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 100%;
          cursor: pointer;

          .height {
            div {
              color: $primary-black;
              font-size: $size14;
            }

            svg {
              width: 12px;
              height: 12px;
              path {
                fill: $primary-black;
              }
            }

            .icon-dropdown-open {
              transform: rotate(180deg);
            }
          }

          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 390px;
            position: relative;
            overflow: overlay;

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }
      }
    }
    
    .dashboard-container {
      align-items: flex-start;
    }

    .cards-container {
      display: flex;
      flex-direction: column;
      gap: $size4;
    }
    
    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 0 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;
      border: 1px solid $medium-grey-blue;

      .card-item {
        display: flex;
        align-items: flex-start;
        margin-left: 80px;

        .card-item-title {
          width: 35%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 65%;
          font-size: $size14;
          color: $primary-black;
          padding-right: $size16;
          display: flex;
          justify-content: flex-start;
          box-sizing: border-box;
          gap: $size8;

          p {
            margin: 0;
          }

          .card-description {
            margin: 0;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          
          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }
          
          .value {
            max-width: 200px;
          }
        }

        .with-capitalize {
          text-transform: capitalize;
        }
      }
      
      .full-row {
        gap: $size8;
        margin-left: 0px;
        
        .card-item-title {
          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }
        
        .card-item-body {
          width: calc(100% - 72px);
          text-align: left;
          
          .value {
            max-width: 100%;
          }
        }
      }

      .expander-wrapper {
        width: 100%;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 12px;
          height: 12px;

          path {
            fill : $primary-blue;
          }
        }
      }
    }
    
    .with-padding-bottom {
      padding-bottom: $size16;
    }
  }
}
@import "../../../styles/shared";

.tooltiped-icon-wrapper {
  position: relative;
  display: flex;

  .tooltip {
    position: absolute;
    z-index: 10;
    visibility: hidden;
    color: $primary-black;
    background-color: $white;
    border-radius: $element-border-radius;
    border: 1px solid $medium-grey-blue;
    font-size: $size14;
    padding: $size8 $size16;
    text-align: center;
    width: 86px;
    bottom: 108%;
    right: -52px;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.08);
  }

  .tooltip::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 6px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.08) transparent transparent transparent;
  }

  .tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -4px;
    border-width: 5px;
    border-style: solid;
    border-color: $white transparent transparent transparent;
  }

  .right {
    right: -15px !important;
  }

  .left {
    right: -85px !important;
  }

  .left::before {
    left: 20% !important;
  }

  .right::before {
    left: 80% !important;
  }

  .left::after {
    left: 20% !important;
  }

  .right::after {
    left: 80% !important;
  }

  .visible {
    visibility: visible;
    opacity: 1;
  }

  @media screen and (max-width: 768px) {
    .tooltip {
      bottom: 150%;
    }
  }
}


@import "../../styles/shared";

.cycle-calendar-wrapper {
  padding: $size24 $size16;
  border-radius: $element-border-radius;

  .calendar-legend {
    @include display-flex;
    justify-content: space-between;
    margin-bottom: 20px;

    > div {
      width: 50%;

      &.status-legend {
        width: 100%;
      }

      &:last-of-type {
        text-align: right;
      }

      @media screen and (max-width: 480px) {
        width: 36%;

        &:first-of-type {
          width: 64%;
        }
      }
    }

    .status-legend {
      margin-top: 0;

      li {
        margin-top: 0.8rem;
        margin-right: 3%;

        @media screen and (max-width: 480px) {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 $size16;
    }
  }

  .visually-hidden {
    display: none;
  }

  .calendar {
    position: absolute;
    background: #fff;
    top: 35px;
    padding: 10px;
    box-shadow: 0 5px 10px #00000029;
    width: 350px;
    max-width: 75vw;
    z-index: 9;

    .calendar-header {
      margin-bottom: 15px;
    }

    .calendar-content {
      > div,
      > div > div {
        padding: 3px;
        margin: 0;
      }
    }
  }

  .fc {
    .fc-header-toolbar {
      margin: 1.5rem 1rem;

      > div {
        position: relative;

        &:nth-child(2) {
          font-size: 1.05rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;

          h2 {
            margin: 0 10.5%;
            width: 52%;
            text-align: center;

            @media screen and (max-width: 900px) {
              margin: 0 5.5%;
              width: 60%;
              font-size: 1.5rem;
            }

            @media screen and (max-width: 414px) {
              font-size: 1.5rem;
              margin: 0 2.5%;
              width: 80%;
            }
          }

          .fc-button {
            font-size: 1.45rem;
            border: 0;
            padding: 0;
            margin: 0;
            min-width: auto;
          }
        }

        @media screen and (max-width: 584px) {
          &:first-of-type {
            order: 1;
          }

          &:last-of-type {
            order: 2;
          }

          &:nth-child(2) {
            order: 3;
            margin-top: $size32;
            width: 100%;
          }
        }
      }

      .fc-button {
        border: 1px solid $dark-grey-blue;
        color: $primary-black;
        background: #fff;
        padding: 9px 15px;
        min-width: 90px;
        line-height: 1.1;

        &.fc-button-active,
        &:disabled {
          background: $dark-grey-blue;
          color: #fff;
          box-shadow: none;
          opacity: 1;

          :focus,
          :active {
            outline: 0;
            box-shadow: none;
          }
        }

        @media screen and (max-width: 768px) {
          min-width: initial;
          padding: 5px 8px;
        }
      }

      :focus,
      :active {
        outline: 0;
        box-shadow: none;
      }

      @media screen and (max-width: 584px) {
        flex-wrap: wrap;
      }
    }

    .fc-view-harness {
      min-height: 415px;
    }

    .fc-event {
      cursor: pointer;
      min-height: 1.7rem;

      .fc-event-main {
        @include display-flex;
        justify-content: space-between;
      }

      &.fc-timegrid-event {
        min-height: 2.5rem;

        .fc-event-main {
          flex-direction: column;
          justify-content: normal;
        }
      }

      &.fc-daygrid-event {
        margin: 1px 5%;

        .fc-event-main {
          align-items: center;
          padding: 1% 5%;
          height: 100%;
          span {
            font-weight: bold;
          }
        }
      }

      &:not(.fc-daygrid-dot-event) {
        background: #26c875;
        border-color: #26c875;
        
        &[class*="cycle-pending"] {
          background: $tracking-grey;
          border-color: $tracking-grey;
        }

        &[class*="cycle-fault"] {
          background: #e2445c;
          border-color: #e2445c;
        }

        &[class*="interrupted"],
        &[class*="aborted"],
        &[class*="door-open"],
        &.stop-button-pressed {
          background: #fdab3d;
          border-color: #fdab3d;
        }
      }

      &.fc-daygrid-dot-event {
        .fc-daygrid-event-dot {
          border-width: 2px;
        }

        &[class*="complete"] {
          .fc-daygrid-event-dot {
            border-color: #26c875;
          }
        }

        &[class*="pending"] {
          .fc-daygrid-event-dot {
            border-color: $tracking-grey;
          }
        }

        &[class*="fault"] {
          .fc-daygrid-event-dot {
            border-color: #e2445c;
          }
        }

        &[class*="interrupted"],
        &[class*="aborted"],
        &[class*="door-open"],
        &.stop-button-pressed {
          .fc-daygrid-event-dot {
            border-color: #fdab3d;
          }
        }
      }

      @media screen and (max-width: 540px) {
        font-size: 0.5rem;
        margin: 1px 2px;
        height: auto;
        min-height: auto;
      }
    }

    table {
      width: 100% !important;

      th {
        height: 46px;
        padding: 1.1rem 0 0 0;
      }
    }

    .fc-scrollgrid {
      border: 0;

      .fc-scrollgrid-section-header {
        border: 0;

        td {
          background: #E5E7F0;
          border: 0;
          padding: 0;
        }

        th {
          border: 0;
        }

        .fc-col-header {
          background-color: $light-grey-blue;

          td {
            padding: 15px 0;
            border: none;
            background: #f5f6fa;
          }
        }
      }

      .fc-timegrid-divider {
        &.fc-cell-shaded {
          display: none;
        }
      }

      .fc-scrollgrid-section {
        &.fc-scrollgrid-section-body {
          &:not(.fc-scrollgrid-section-liquid) {
            display: none;
          }

          > td {
            padding: 0;
            border: 0;
          }
        }
      }
    }

    .fc-daygrid-body {
      width: 100% !important;
    }
    
    .fc-more-link {
      span {
        @media screen and (max-width: 540px) {
          font-size: 0.55rem;
        }
      }
    }

    .fc-daygrid {
      .fc-scrollgrid-section {
        &.fc-scrollgrid-section-body {
          > td {
            border: 1px solid #ddd;
          }
          .fc-daygrid-day {
            padding: 0;
          }
        }
      }
    }

    .fc-popover {
      z-index: 9997;
    }
    
    @media all and (min-width: 1202px) {
      .fc-daygrid-body-unbalanced {
        .fc-daygrid-day-events {
          max-height: 2em;
        }
      }
    }  
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px){
    padding: $size24 0;
  }
}

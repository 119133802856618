@import "../../../styles/shared";

.calendar {
  width: 100%;
  .calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    background-color: white;

    .month {
      font-size: 24px;
      margin: 0;
    }

    .current-month {
      font-size: $size16;
      font-weight: 700;
      color: $primary-black;
    }

    button {
      background-color: transparent;
      border: none;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;

        &.left,
        &.right {
          path {
            fill: $primary-black;
          }
        }

        &.left {
          transform: rotate(180deg);
        }
      }
    }
  }

  .calendar-content {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    text-align: center;
    background-color: white;

    div {
      padding: 8px;
      margin: 0 auto;
      font-weight: bold;

      &.cursor-pointer {
        cursor: pointer;
      }

      &.day-of-week {
        font-size: 10px;
        color: $grey;
      }

      &.date {
        padding-bottom: 0;
      }
    }

    .dots {
      padding: 0;

      .dot {
        padding: 0;
        height: 7px;

        &.ok,
        &.failed,
        &.warning {
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }

        &.ok {
          background-color: #26c875;
        }

        &.failed {
          background-color: #e2445c;
        }

        &.warning {
          background-color: #fdab3d;
        }
      }
    }
  }

  .prev,
  .next {
    cursor:not-allowed;
    color: $grey;
  }

  .selected {
    border-radius: $element-border-radius;
    background-color: $primary-blue;
    color: $white !important;
  }

  .current-date {
    color: $primary-blue;
  }
}

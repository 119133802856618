@import "../../../styles/shared.scss";

.request-join-modal {

  .page-modal {
    height: max-content;
    width: 640px;

    .request-join-modal-content {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      gap: $size24;
      margin-bottom: $size16;

      .input-wrapper {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size8;

        .input-option {
          margin: 0;
          font-weight: 500;
        }
        
        .email-input {
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;
      width: 100% !important;

      .request-join-modal-content {
        font-size: $size14;

        .input-wrapper {
          margin-bottom: $size16;
          flex-direction: column;
          gap: $size16;
          width: 100%;
          box-sizing: border-box;
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

@import "../../../styles/shared";

.my-account-wrapper {
  .my-orders-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 790px;
    margin-top: $size16;
    box-sizing: border-box;
    gap: $size16;

    .section-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size16;
      width: 100%;
      padding: 0 $size24 $size16 $size24;
      border-radius: $element-border-radius;
      background-color: $white;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;

      .wrapper-header { 
        display: flex;
        padding: $size24 0 $size8 0;
        box-sizing: border-box;

        .wrapper-title {
          font-size: $size14;
          font-weight: 500;
          color: $dark-grey-blue;
          margin: 0;
          text-transform: uppercase;
        }
      }

      .empty-page-wrapper {
        min-height: 330px;
      }

      .status-wrapper {
        display: flex;
        align-items: center;
        width: 150px;
    
        .processed {
          color: $success-green !important;
        }
    
        .processing {
          color: $dark-yellow !important;
        }
    
        .cancelled {
          color: $danger-red !important;
        }
      }

      .table-actions-wrapper {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: end;
      }

      .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
      }

      .order-details-wrapper {
        margin-top: $size24;
        padding: 0;
      }
    }

    .spinner-wrapper {
      position: fixed;
      z-index: 9999;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
  
      .loading-spinner {
        position: relative;
        top: 25%;
        left: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @media all and (max-width: 768px) {
    .my-orders-wrapper {
      .section-wrapper {
        padding: 0 $size16 $size16 $size16;
        gap: $size4;
        border-radius: 0;

        .wrapper-header {
          padding-bottom: $size12;
        }

        .card {
          width: 100%;
          min-height: $size48;
          height: max-content;
          background-color: $white;
          border-radius: $element-border-radius;
          border: 1px solid $medium-grey-blue;
          padding: $size16 $size16 0 $size16;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: $size8;
          cursor: pointer;
          

          .card-item {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            gap: $size8;
  
            .card-item-title {
              width: 35%;
              font-size: $size14;
              font-weight: 700;
              color: $primary-black;
              margin: 0;
            }
  
            .card-item-body {
              width: 70%;
              padding-right: $size16;
              font-size: $size14;
              color: $primary-black;
              display: flex;
              align-items: center;
              box-sizing: border-box;
              justify-content: flex-end;
  
              .card-actions {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                position: absolute;
                right: $size24;
    
                .actions-menu-wrapper {
                  right: 0;
                }
              }

              .card-item-body-status {
                display: flex;
    
                .processed {
                  color: $success-green;
                }
            
                .processing {
                  color: $dark-yellow;
                }
            
                .cancelled {
                  color: $danger-red;
                }
              }

              .align-center {
                align-items: center;
              }
            }
          }

          .expander-wrapper {
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 12px;
              height: 12px;

              path {
                fill : $primary-blue;
              }
            }
          }
        }

        .with-padding-bottom {
          padding-bottom: $size16;
        }

        .buttons-wrapper {
          margin-top: $size16;
          margin-bottom: $size16;
        }
      }
    }
  }
}

@import "../../../styles/shared";

.order-status-modal-wrapper {
  .page-modal {
    max-width: unset;
    width: 470px;
    height: max-content;

    .page-content {
      padding: 0 $size16;
      height: calc(100% - 69px);
      width: 470px;

      .order-status-modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: $size24;

        .title {
          margin-top: $size16;
          text-align: center;
          font-weight: 500;
          font-size: $size18;
          color: $primary-black;
        }

        .details {
          margin: $size8 0 $size24;
          text-align: center;
          font-size: $size14;
          padding: 0 $size24;
          color: $dark-grey-blue;

          .strong {
            color: $primary-black;
            font-weight: 700;
          }

          a {
            color: $light-blue-primary;
            text-decoration: underline;
            cursor: pointer;
          }
        }          
      }
    }

    .page-footer {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      width: unset;
      top: unset;

      .page-content {
        width: unset;
      }
    }
  }
}



@import "../../styles/shared.scss";

.invite-member-modal {

  .page-modal {
    height: max-content !important;
    width: 640px;
    overflow: unset;

    .page-content {
      overflow: unset;

      .invite-member-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size16;
        margin-bottom: $size16;
  
        .row-wrapper {
          width: 100%;
          display: flex;
          gap: $size16;
  
          .input-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: $size8;
    
            .input-option {
              margin: 0;
              font-weight: 500;
              position: relative;

              svg {
                position: absolute;
                width: 18px;
                height: 18px;
                bottom: 6px;
                cursor: pointer;
              }
            }
            
            .email-input {
              width: 100%;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;
      width: 100% !important;

      .invite-member-modal-content {
        font-size: $size14;

        .row-wrapper {
          flex-direction: column;
          gap: $size16;

          .input-wrapper {
            flex-direction: column;
            gap: $size8;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

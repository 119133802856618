@import '../../styles/shared';

.troubleshoot-details-modal-wrapper {
  .page-modal-wrapper {
    .page-modal {
      .page-header {
        .page-title {
          display: none;
        }

        .page-header-content {
          margin-right: 0 !important;
          width: 100%;

          .troubleshoot-header {
            display: flex;
            align-items: center;
            gap: $size16;
            height: 100%;
            width: 100%;
            cursor: pointer;

            .back-button {
              display: flex;
              align-items: center;
              padding-left: $size16;
              width: 24px;
              height: 24px;

              .back-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                flex-shrink: 0;
              }
            }

            .title {
              margin: 0 $size16 0 0;
              font-weight: 500;
              font-size: $size14;
            }
          }

          .page-close {
            display: none;
          }
        }
      }

      .page-content {
        padding: 0 $size16;
        height: calc(100% - 69px);

        .troubleshoot-details-modal-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          min-height: 540px;

          .product-card {
            display: flex;
            align-items: center;
            height: 96px;
            background-color: $light-grey-blue;
            border-radius: $element-border-radius;
            box-sizing: border-box;
            flex-shrink: 0;

            .image-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 $size16 0 $size16;
              height: 64px;
              width: 64px;
              background-color: $white;
              border-radius: $element-border-radius;
              flex-shrink: 0;

              img {
                width: 44px;
                height: 44px;
              }
            }

            .product-name {
              font-size: $size16;
              color: $primary-black;
              font-weight: 700;
              margin: 0 $size16 0 0;
            }
          }

          .cycle-select-title {
            font-size: $size14;
            margin: $size16 0 $size8 0;
            font-weight: 500;
            color: $primary-black;
          }

          .click-outside-wrapper {
            margin: 0 0 $size32 0;
            border: 1px solid $medium-grey-blue;
            box-shadow: none;
            max-width: 100%;
            cursor: pointer;

            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }         

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }

              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }

            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 390px;
              position: relative;
              overflow: overlay;

              .options-filter {
                width: 100%;
                height: 100%;
                background-color: $white;
                z-index: 1;

                .search {
                  background-color: $light-grey-blue;
                  border-radius: $element-border-radius;
                  margin: $size16 $size16 $size4 $size16 !important;
                  box-shadow: none !important;
                  z-index: 1;

                  .search-icon {
                    display: none;
                  }
                }
              }

              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }

          .fit-within-page {
            width: unset;
            height: unset;
            min-height: unset;
          }

          .cycle-details-wrapper {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 0 $size24 0;
            gap: $size32;
            box-sizing: border-box;

            .cycle-title-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size8;

              .cycle-title {
                display: flex;
                font-size: $size24;
                font-weight: 700;
                gap: $size4;

                .cycle-title-number {
                  color: $primary-blue
                }
              }

              .cycle-description {
                font-size: $size14;
                color: $primary-black;
              }
            }      

            .cycle-category {
              .cycle-category-title {
                font-size: $size14;
                font-weight: 700;
                margin: 0 0 $size8 0;
              }
              
              .cycle-categories {
                margin: 0px;
              }
              
              .list-none {
                list-style: none;
                margin: 0px;
                padding: 0px;
              }
              
              .cycle-category-issue-list {
                margin: 0 0 0 $size16;
                padding: 0;
                list-style: none;

                .cycle-category-issue {
                  font-size: $size14; 
                  margin-bottom: $size4;
                  color: $primary-black;
                }

                li::before {
                  content: "\2022";
                  color: $primary-blue;
                  font-weight: bold;
                  font-size: $size16;
                  display: inline-block;
                  width: 12px;
                  margin-left: -16px;
                }
              }          
            }

            .product-videos-wrapper {
              display: flex;
              flex-direction: column;
              border-top: 1px solid $medium-grey-blue;

              .section-title {
                font-size: $size14;
                font-weight: 500;
                color: $primary-black;
                margin: $size24 0 $size8 0;
              }

              .videos-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size24;
                width: 100%;
                margin-bottom: $size32;

                .video-item-wrapper {
                  display: flex;
                  flex-direction: column;
                  width: 290px;
                  cursor: pointer;

                  .thumbnail-wrapper {
                    display: flex;
                    position: relative;
                    width: 100%;
                    height: 160px;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    border-radius: $element-border-radius;

                    .thumbnail-icon {
                      position: absolute;
                      top: 50%;
                      left: 50%;
                      transform: translateX(-50%) translateY(-50%);

                      svg {
                        width: $size32;
                        height: $size32;
                      }
                    }

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                    }
                  }

                  .title {
                    margin: $size16 0 0 0;
                    font-weight: 700;
                    text-align: left;
                    font-size: $size16;
                    word-wrap: break-word;
                  }
                }

                .no-videos-wrapper {
                  display: flex;
                  align-items: center;
                  background-color: $light-grey-blue;
                  border-radius: $element-border-radius;
                  height: 52px;

                  .disclaimer {
                    margin: 0 $size16;
                    font-size: $size14;
                    font-style: italic;
                    color: $dark-grey-blue;
                  }
                }
              }

              .simple-button {
                width: 290px;
              }
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 425px) {
    .video-item-wrapper {
      width: 100% !important;
    }

    .simple-button {
      width: 100% !important;
    }
  }
}
@import "../../../styles/shared";

.generic-table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  overflow-x: auto;

  .table {
    width: 100%;
    max-width: 100%;
    border: none;
    border-spacing: 0;
  
    tr {
      min-height: 50px;
      background-color: $white;

      th {
        text-align: left;
        padding: 0 $size16;

        .header-item {
          display: flex;
          align-items: center;
          width: max-content;

          .selectable-wrapper {
            max-width: 24px;
            user-select: none;
        
            .checkbox-wrapper {
              gap: 0;
            }
          }

          .header-sort {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;
            margin-right: 10px;
    
            svg {
              width: 8px;
              height: 8px;
              cursor: pointer;
              fill: $dark-grey-blue;
            }
    
            svg:last-of-type {
              transform: rotateX(180deg);
            }
    
            .active-sort {
              fill: $primary-blue;
            }
          }
        }        
      }

      td {
        text-align: left;
        padding: 0 $size16;
        height: 50px;
        border-bottom: 1px solid $medium-grey-blue;
      }
    }

    .row-effects {
      cursor: pointer;
    }

    .row-effects:hover {
      background-color: $light-grey-blue;
    }

    .header {
      height: $size36;
      background-color: $table-header-grey !important;
      font-weight: 700;
      border-bottom: 0;
      min-height: unset;
    }
  }
}
@import "../../../styles/shared";

.expandable-text-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;

  .expandable-text {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    word-break: break-word;
    text-overflow: ellipsis;
    margin: 0;
    width: 100%;
    font-size: $size14;
    color: $primary-black;
  }

  .text-expanded {
    overflow: inherit;
    white-space: inherit;
    padding: $size8 0;
  }

  .expand-action {
    font-size: $size14;
    color: $primary-blue;
    cursor: pointer;
    text-transform: capitalize;
  }
}

.wrapper-expanded {
  flex-wrap: wrap;

  .expand-action {
    padding-left: $size4;
  }
}

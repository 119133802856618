@import '../../styles/shared';

.firmware-details-modal-wrapper {
  .page-modal-wrapper {
    .page-modal {
      .page-header {
        .page-title {
          display: none;
        }

        .page-header-content {
          margin-right: 0 !important;
          width: 100%;

          .firmware-header {
            display: flex;
            align-items: center;
            gap: $size16;
            height: 100%;
            width: 100%;
            cursor: pointer;

            .back-button {
              display: flex;
              align-items: center;
              padding-left: $size16;
              width: 24px;
              height: 24px;

              .back-icon {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 24px;
                height: 24px;
                border-radius: 12px;
                flex-shrink: 0;
              }
            }

            .title {
              margin: 0 $size16 0 0;
              font-weight: 500;
              font-size: $size14;
            }
          }

          .page-close {
            display: none;
          }
        }
      }

      .page-content {
        padding: 0;
        height: calc(100% - 69px);

        .firmware-details-modal-content {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          min-height: 540px;

          .device-card {
            display: flex;
            background-color: $white;
            padding: 0 $size16 $size24 $size16;
            box-sizing: border-box;
            flex-shrink: 0;

            .image-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 0 $size8 0 0;
              height: 64px;
              width: 64px;
              background-color: $white;
              border-radius: $element-border-radius;
              flex-shrink: 0;

              img {
                width: 64px;
                height: 64px;
              }
            }

            .device-name-wrapper {
              display: flex;
              width: 100%;
              gap: $size4;

              .device-name {
                font-size: $size16;
                color: $primary-black;
                font-weight: 700;
                margin: 0;
              }

              .firmware-latest-release {
                font-size: $size14;
                color: $dark-grey-blue;
              }
    
              .firmware-version-available {
                font-size: $size14;
                color: $primary-blue;
              }
            }
          }

          .wrapped-tabs-wrapper {
            .tabs-container {
              padding: 0 $size16;
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }
}
@import "../../styles/shared";

.template-editor-wrapper {
  //padding: $size16;
  border-radius: $element-border-radius;
  height: 100vh;
  width: auto;
  //background-color: $white;
  display: flex;
  flex-direction: column;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;
    margin: $size8 0 $size24 0;

    .title {
      font-size: $size24;
      font-weight: 700;
      color: $primary-black;
    }

    .edit-title {
      font-weight: 400;
      color: $primary-blue;
      font-size: $size16;
      margin-left: $size16;
      cursor: pointer;
    }
  }

  .editor-container {
    display: block;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid $medium-grey-blue;

    .hero-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      background-image: url(./hero_background.png);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      padding-left: 10%;
      height: 100vh;
    }

    .editable-text {
      background: none;
      border: none;
      color: white;
      outline: none;
      resize: none;
      height: auto;
    }

    .link {
      height: 62px;
      width: max-content;
      background-color: $primary-blue;
      color: white;
      padding: 24px 54px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      cursor: pointer;
      font-size: 20px;
      border-radius: 8px;
      text-decoration: none;
    }
  
    .section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      width: 100%;

      .editable-text {
        width: 100%;
        text-align: center;
      }
    }
  }
}
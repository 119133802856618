@import '../../styles/shared';

.products-wrapper {
  
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;

    .search-with-filter-wrapper {
      .search-wrapper {
        display: none;
      }
    }
  }

  .product {
    display: flex;
    align-items: center;

    img {
      width: 75px;
      height: 75px;
    }

    .serial-number,
    .name {
      margin: 0;
    }

    .serial-number {
      font-weight: bold;
    }

    .name {
      color: $dark-grey-blue;
    }
  }

  .cycle-fault-wrapper {
    color: $danger-red;
  }

  .comment-wrapper {
    max-width: 250px;
  }

  .posted-by-wrapper {
    display: flex;
    align-items: center;
    gap: $size8;

    img {
      width: $size24;
      height: $size24;
      border-radius: 50%;
      object-fit: cover;
    }

    .no-image {
      background-color: $dark-grey-blue;
      border-radius: 50%;
      color: $white;
      font-size: $size14;
      width: $size24;
      height: $size24;
      text-align: center;
      text-transform: uppercase;
    }
  }

  ::-webkit-scrollbar-track {
    background: none;
  }

  @media all and (max-width: 768px) {
    .actions-wrapper {
      height: unset;
      flex-direction: column;
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      border-radius: $element-border-radius;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;
          
          .card-item-body-posted-by {
            display: flex;
            gap: $size8;

            img {
              width: $size24;
              height: $size24;
              border-radius: 50%;
              object-fit: cover;
            }
    
            .no-image {
              background-color: $dark-grey-blue;
              border-radius: 50%;
              color: $white;
              font-size: $size14;
              width: $size24;
              height: $size24;
              text-align: center;
              text-transform: uppercase;
            }
          }
        }

        .cycle-fault {
          color: $danger-red;
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }
  }
}

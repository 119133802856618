@import '../../../styles/shared';

.search-wrapper{
  .search {
    height: 36px;
    display: flex;
    align-items: center;
    margin: 0 6px;
    background-color: $white;
    box-shadow: $card-box-shadow;
    border-radius: $element-border-radius;

    .search-icon {
      width: 16px;
      padding: 0 14px;

      path {
        stroke: #788090;
      }
    }

    input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: transparent;
      padding: 0 16px;
      font-size: inherit;
      border: 0;
      outline: 0;
      width: 100%;

      &:-webkit-autofill,
      &:-webkit-autofill:hover,
      &:-webkit-autofill:focus,
      &:-webkit-autofill:active,
      &:-internal-autofill-selected {
        -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
        box-shadow: 0 0 0 30px #f5f6fa inset !important;
      }

      &::-webkit-input-placeholder { /* Edge */
        color: #788090;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #788090;
      }

      &::placeholder {
        color: #788090;
      }
    }

    .reset-search-button {
      background: none;
      border: none;
      width: 24px !important;
      height: 24px !important;
      cursor: pointer;
      margin-right: $size4;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        flex-shrink: 0;
        width: 16px !important;
        height: 16px !important;

        g {
          stroke: $dark-grey-blue;
        }
      }
    }
  }
}

@import "../../../styles/shared.scss";

.google-address-input-wrapper {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: $size16;

  .row-wrapper {
    width: 100%;
    display: flex;
    gap: $size16;

    .input-wrapper-google-address {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      position: relative;

      .input-option {
        margin: 0;
        font-weight: 500;
      }
      
      .input-item-wrapper {
        display: flex;
        align-items: center;
        gap: $size8;
        padding: 0 $size16;
        width: 100% !important;
        height: $size36;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        box-sizing: border-box;

        .input-item {
          border: none;
          background-color: $white;
          outline: none;
          box-sizing: border-box;
          width: 100%;
        }

        .input-item:disabled {
          opacity: 0.7;
        }

        .input-item-icon {
          width: $size16;
          height: $size16;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
        }

        .error-message {
          position: absolute;
          font-size: $size12;
          color: $error-color;
          margin-top: 62px;
        }
      }

      .has-error {
        border: 1px solid $required-red;
      }

      .google-places-wrapper {
        position: absolute;
        background: $white;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        max-height: 50vh;
        z-index: 2;
        width: 100%;
        top: 64px;

        .google-place {
          cursor: pointer;
          text-align: left;
          padding: 9px 21px;

          &:hover {
            background-color: $grey-background;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .google-address-input-wrapper {
    font-size: $size14;
    gap: unset;

    .row-wrapper {

      .input-wrapper-google-address {
        margin-bottom: $size16;
        flex-direction: column;
        gap: $size8;
        width: 100%;
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: 485px) {
  .google-address-input-wrapper {

    .row-wrapper {
      flex-direction: column;
      gap: unset;
    }
  }
}
@import "../../../styles/shared";

.click-outside-wrapper {
  cursor: pointer;
  background-color: $white;
  padding: 0;
  max-width: 307px;
  width: 100%;
  border: none;
  height: 36px;
  text-indent: 18px;
  box-shadow: 0 1px 1px #0000002f;
  border-radius: $element-border-radius;

  .select-input {
    width: 100%;
    margin: 0;
  }

  .scroll {
    .options-wrapper {
      overflow: scroll;
      overflow-x: hidden;
    }
  }

  .default-option {
    text-align: center;
  }

  .height {
    height: 36px;

    div {
      width: 100%;
      color: $grey-text;
      font-size: $size16;
    }

    svg {
      path {
        fill: $grey;
      }
    }
  }

  .options-wrapper {
    width: 100%;
    max-height: 250px;
    overflow: scroll;
    overflow-x: hidden;
    height: 100%;
    z-index: 1;
    box-sizing: border-box;
    border: 0;
    box-shadow: 0px 1px 6px #00000024;
    border-radius: $element-border-radius;
  }

  .options-wrapper > .option {
    background-color: $white;
    height: 36px;
    padding: 0;
    width: 100%;
    text-indent: 18px;
    z-index: 1;
    line-height: 36px;
    &:hover {
      background-color: $grey-background;
    }
  }

  .input-dropdown-container {
    padding-bottom: $size24;
  }
}

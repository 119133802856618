@import "../../../styles/shared";

.page-modal-wrapper {
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .page-modal-overflow {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-black;
    opacity: 0.32;
  }

  .page-modal {
    width: 800px;
    height: 84%;
    z-index: 1;
    overflow: hidden;
    background-color: $white;
    border-radius: $element-border-radius;
    box-sizing: border-box;

    .page-header {
      position: sticky;
      top: 0;
      z-index: 1;
      background-color: $white;
      height: 68px;
      display: flex;
      align-items: center;
      max-width: 100vw;

      .page-title {
        margin: 0 $size24;
        font-size: $size24;
        font-weight: 700;
        color: $primary-black;
      }
      
      .page-subtitle {
        margin: 0 $size24;
        margin-top: $size4;
        font-weight: 400;
        color: #758091;
      }

      .page-header-content {
        margin-left: auto;
        margin-right: $size24;
        display: flex;
        align-items: center;

        .page-close {
          margin-left: $size24;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }

    .page-content {
      padding: $size16 $size24;
      box-sizing: border-box;
      height: calc(100% - 137px);
      overflow: auto;
    }

    .page-footer {
      position: sticky;
      bottom: 0;
      z-index: 1;
      background-color: $white;
      height: 68px;
      display: flex;
      align-items: center;
      border-top: 1px solid $medium-grey-blue;
      gap: $size8;
      justify-content: flex-end;
      padding: 0 $size24;
      box-sizing: border-box;
    }

    .header-border {
      border-bottom: 1px solid $medium-grey-blue;
    }
  }

  @media all and (max-width: 768px) {
    .page-modal {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;
      min-width: unset;
      border-radius: 0;

      .page-footer {
        .simple-button {
          width: 100%;
        }
      }
    }
  }
}

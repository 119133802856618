@import "../../styles/shared";

.account-menu-container {
  position: fixed;
  top: 73px;
  right: $size16;
  width: 320px;
  padding: $size24 0 $size16 0;
  border-radius: $element-border-radius;
  border: 1px solid $medium-grey-blue;
  box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.08);
  background-color: $white;
  z-index: 998;

  .avatar-wrapper {
    height: 72px;
    display: flex;
    align-items: flex-start;
    padding: 0 $size24;
    border-bottom: 1px solid $medium-grey-blue;

    .avatar-img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $size16;
      flex-shrink: 0;

      .image {
        border-radius: 50%;
        background-size: auto;
        background-position: center;
        height: 48px;
        width: 48px;
        object-fit: cover;
      }

      .avatar-initials {
        background-color: $dark-grey-blue;
        color: $white;
        width: inherit;
        height: inherit;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $size16;
        flex-shrink: 0;
      }
    }

    .avatar-data {
      display: flex;
      flex-direction: column;
      gap: $size4;

      .name {
        font-size: $size16;
        font-weight: 700;
        color: $primary-black;
        margin: 0;
      }

      .role {
        font-size: $size14;
        color: $primary-blue;
        margin: 0;
      }
    }
  }

  a {
    text-decoration: none;
    color: $primary-black;
    font-size: $size14;
  }

  .nav-option-wrapper {
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 $size24;
    font-size: $size14;
    color: $primary-black;
    cursor: pointer;
  }

  .separator {
    height: 1px;
    width: 100%;
    background-color: $medium-grey-blue;
  }

  .with-margin-top {
    margin-top: $size16;
  }

  @media screen and (max-width: 768px) {
    top: 54px;
    right: $size8;
  }
}

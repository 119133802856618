@import "../../styles/shared";

.users-management-wrapper {
  
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
  }

  .email-wrapper {
    max-width: 250px;
  }

  .name-wrapper {
    max-width: 250px;
  }

  .confirmation-status-wrapper {
    text-transform: capitalize;
  }

  .status-wrapper {
    display: flex;
    gap: $size8;
    align-items: center;
    font-size: $size14;
    white-space: nowrap;

    .status-active {
      color: $success-green;
      display: flex;
      align-items: center;
    }

    .status-pending {
      color: $dark-yellow;
      display: flex;
      align-items: center;
    }

    .status-inactive {
      display: flex;
      align-items: center;
      color: $dark-grey-blue;      
    }

    p {
      margin: 0;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .page-modal-wrapper {
    
    .page-modal {

      .page-header {
        .status-wrapper {
          display: flex;
          align-items: center;

          .input-option {
            margin: 0 $size16 0 0;
            font-size: $size14;
            font-weight: 500;
            color: $primary-black;
          }

          .status {
            display: flex;
            align-items: center;
            font-size: $size12;

            p {
              margin: 0 0 0 $size4;
            }

            .active {
              color: $primary-black;
            }

            .pending {
              color: $dark-yellow;
            }

            .inactive {
              color: $dark-grey-blue;      
            }
          }
        }        
      }

      .user-details-modal-content {
        width: 100%;
        box-sizing: border-box;

        .unconfirmed-user-disclaimer {
          display: flex;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          width: 100%;
          height: 56px;
          border-radius: $element-border-radius;
          border: 1px solid $dark-yellow;
          background-color: $dark-yellow-transparent;
          font-size: $size14;
          color: $dark-yellow;
        }

        .user-details-item-wrapper {
          width: 100%;
          height: 65px;
          margin-top: $size16;
          display:flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          gap: $size16;

          .user-details-item {
            height: 100%;
            width: 50%;
            box-sizing: border-box;

            .input-item {
              width: 100%;
              margin-top: 4px;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }

            .input-item:disabled {
              background-color: $white;
              color: $primary-black;
              opacity: 0.7;
            }

            .click-outside-wrapper {
              margin: 4px 0 0 0;
              border: 1px solid $medium-grey-blue; 
              box-shadow: none;
              max-width: 100%;
              box-sizing: border-box;

              .height {
                div {
                  color: $primary-black;
                  font-size: $size14;
                }         

                svg {
                  width: 12px;
                  height: 12px;
                  path {
                    fill : $primary-black;
                  }
                }

                .icon-dropdown-open {
                  transform: rotate(180deg);
                }
              }

              .options-wrapper {
                margin-top: 4px;
                border: 1px solid $medium-grey-blue;
                max-height: 160px;
                position: relative;
                overflow: overlay;

                .selected-option {
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }

            .has-error {
              border: 1px solid $required-red;
            }
          }

          .title {
            margin: 0;
            font-weight: 500;

            p {
              margin: 0;
            }
          }

          .value {
            margin: 0;
            color: $dark-grey-blue;
          }

          .with-margin-left {
            margin-left: $size8;
          }

          .capitalized {
            text-transform: capitalize;
          }
        }

        .user-details-item-wrapper:first-of-type {
          margin-top: 0;
        }

        .with-big-margin-top {
          margin-top: 32px !important;
        }

        .units-subscriptions-wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
          box-sizing: border-box;

          .separator {
            width: 100%;
            height: 1px;
            background-color: $medium-grey-blue;
            margin: 0;
          }

          .units-header,
          .subscriptions-header {
            display: flex;
            height: 56px;
            width: 100%;
            align-items: center;
            box-sizing: border-box;
            cursor: pointer;

            .header-title {
              display: flex;
              flex-direction: column;
              gap: $size4;

              .title {
                margin: 0;
                font-size: $size14;
                color: $primary-black;
                font-weight: 700;
              }

              .subtitle {
                margin: 0;
                font-size: $size14;
                color: $dark-grey-blue;
                min-height: 17px;

                .highlight {
                  color: $primary-black;
                }
              }
            }

            .expand-button {
              margin-left: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              height: $size24;
              width: $size24;
            }
          }
          
          .item-wrapper {
            display: flex;
            align-items: center;
            height: 64px;
            width: 100%;
            gap: $size16;
            border-bottom: 1px solid $medium-grey-blue;
            box-sizing: border-box;

            .item-image-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 48px;
              width: 64px;
              box-sizing: border-box;

              .item-image {
                height: 48px;
                width: 48px;
                object-fit: cover;
              }
            }

            .item-title-wrapper {
              display: flex;
              flex-direction: column;
              height: 100%;
              gap: $size4;
              justify-content: center;

              .title {
                margin: 0;
                font-size: $size16;
                color: $primary-black;
                font-weight: 700;
              }

              .subtitle {
                margin: 0;
                font-size: $size14;
                color: $primary-blue;
              }
            }

            .device-status-wrapper {
              display: flex;
              margin-left: auto;
              align-items: center;
              gap: $size8;
            }

            .item-status-wrapper {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              font-size: $size14;
              font-weight: 500;
              margin-left: auto;

              .is-registered {
                margin: 0;
                color: $success-green;
              }

              .not-registered {
                margin: 0;
                color: $medium-grey-red;
              }

              .without-margins {
                margin: 0;
              }

              .active {
                color: $green;
              }

              .unpaid,
              .incomplete,
              .trialing {
                color: $dark-yellow;
              }

              .expired,
              .incomplete_expired,
              .past_due,
              .canceled {
                color: $error-color;
              }
            }
          }

          .first-item-wrapper {
            margin-top: $size16 !important;
          }

          .last-item-wrapper {
            border-bottom: none !important;
          }
        }
      }

      .page-footer {
        .action-delete-button {
          margin-right: auto;
        }
      }
    }
  }

  .confirmation-modal-wrapper {
    .modal {
      .reset-password-tooltip {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;

        p {
          margin: $size8 0px 0px -16px;
        }
      }

      .delete-user-tooltip {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 58px;
        box-sizing: border-box;
        
        p {
          margin: $size8 0px 0px 0;
          opacity: 0.7;
        }

        .input-title {
          opacity: 1;
          font-weight: 500;
          margin: $size24 0 $size8 0;
        }

        .confirm-email-input {
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
        }
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .actions-wrapper {
      height: unset;
      flex-direction: column;
      padding: 0 $size16;
      box-sizing: border-box;

      .order-by-mobile-wrapper {
        margin-top: $size16;
        height: $size36;
        width: 100%;

        .click-outside-wrapper {
          margin: 0 $size16 0 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 100%;
          cursor: pointer;

          .height {
            div {
              color: $primary-black;
              font-size: $size14;
            }

            svg {
              width: 12px;
              height: 12px;
              path {
                fill: $primary-black;
              }
            }

            .icon-dropdown-open {
              transform: rotate(180deg);
            }
          }

          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 390px;
            position: relative;
            overflow: overlay;

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }
          
          .card-item-body-status {
            display: flex;

            .status-active {
              color: $success-green;
              display: flex;
              align-items: center;
            }

            .status-pending {
              color: $dark-yellow;
              display: flex;
              align-items: center;
            }

            .status-inactive {
              display: flex;
              align-items: center;
              color: $dark-grey-blue;      
            }

            svg {
              width: $size24;
              height: $size24;
            }

            p {
              margin: 0;
            }
          }
        }

        .confirmation-status {
          text-transform: capitalize;
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
      margin-bottom: $size16;
    }

    .page-modal-wrapper {

      .page-modal {

        .user-details-modal-content {
          width: 100%;
          box-sizing: border-box;
          font-size: $size14;

          .user-details-item-wrapper {
            height: 130px;
            margin-top: $size16;
            flex-direction: column;
            align-items: flex-start;
            gap: $size8;

            .user-details-item {
              height: 65px;
              width: 100%;
              box-sizing: border-box;

              .input-item {
                width: 100%;
                margin-top: 4px;
                height: $size36;
                border: 1px solid $medium-grey-blue;
                text-indent: $size16;
                outline: none;
                box-sizing: border-box;
              }

              .input-item:disabled {
                background-color: $white;
                color: $primary-black;
                opacity: 0.7;
              }

              .click-outside-wrapper {
                margin: 4px 0 0 0;
                border: 1px solid $medium-grey-blue;
                box-shadow: none;
                max-width: 100%;
                cursor: pointer;
                box-sizing: border-box;

                .height {
                  div {
                    color: $primary-black;
                    font-size: $size14;
                  }         

                  svg {
                    width: 12px;
                    height: 12px;
                    path {
                      fill : $primary-black;
                    }
                  }

                  .icon-dropdown-open {
                    transform: rotate(180deg);
                  }
                }

                .options-wrapper {
                  margin-top: 4px;
                  border: 1px solid $medium-grey-blue;
                  max-height: 160px;
                  position: relative;
                  overflow: overlay;

                  .selected-option {
                    color: $primary-black;
                    font-weight: 500;
                  }
                }
              }
            }

            .mobile-hidden {
              display: none;
            }
          }

          .single-item {
            height: 65px !important;
          }

          .units-subscriptions-wrapper {
            .item-wrapper {
              min-height: 64px;
              height: unset;

              .device-status-wrapper {
                flex-direction: column;
                align-items: flex-end;
                padding: $size8 0;
              }

              .item-status-wrapper {
                text-align: end;
                font-weight: unset !important;
                order: 2;
              }
            }
          }
        }
      }

      .page-footer {
        .is-edit-button {
          width: 100%;
        }
      }
    }

    .confirmation-modal-wrapper {
      .modal {
        .reset-password-tooltip {

          p {
            margin: $size8 0px $size8 0px;
          }
        }

        .delete-user-tooltip {
          align-items: center;
          padding-left: 0;
          text-align: center;
        }
      }
    }
  }
}

.approval-wrapper {
  position: relative;
  > img {
    width: 100%;
    height: 90vh;
    // object-fit: cover;
  }
  .message {
    padding: 1.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #758091;
    border-radius: 80px;
    background-color: #fff;
    
    @media all and (max-width: 768px) {
      padding: 1rem;
    }
    
    .icon-text {
      font-size: 1.8rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      
      @media all and (max-width: 768px) {
        font-size: 1rem;
      }
    }
    
    .email-text {
      cursor: pointer;
      color: #00A0E9;
      font-size: 1rem;
      font-weight: 400;
      line-height: 21px;
      
      @media all and (max-width: 768px) {
        font-size: 0.75rem;
      }
    }

    svg {
      margin-right: 15px;
      height: 47px;
      width: 47px;
    }
  }
}

@import '../../styles/shared';


.online-activation-wrapper {
  max-height: 292px;
  height: 100%;

  .title {
    max-height: 32px;
    height: 100%;
    margin: 0 0 4px 0;
    font-size: $size24;
    color: $purple-black;
  }

  .sub-title {
    max-width: 303px;
    width: 100%;
    max-height: 63px;
    height: 100%;
    line-height: 18px;
    text-align: center;
    font-size: $size16;
    color: $primary-black;
    // padding-bottom: 69px;
    padding-bottom: 50px;
    &.token {
      padding-bottom: 27px;
    }
  }

  .label {
    font-weight: bold;
    font-size: $size16;
    color: $primary-black;
    padding-bottom: 15px;
    max-height: 22px;
    height: 100%;
  }

  .serial-number {
    color: $primary-blue;
    font-size: $size30;
    max-height: 39px;
    height: 100%;
    text-transform: uppercase;
    line-height: 21px;
    letter-spacing: 0;
    font-weight: bold;
    padding-bottom: 45px;
  }
  .onlineaccess-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #F7F8FA;
    margin-top: auto;
    padding: 19px 0px;
  }
  .submit {
    &.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 334px;
      height: 35px;
      padding: 0;
      width: 100%;
      line-height: 35px;
    }
  }

  .token-input {
    max-width: 294px;
    width: 100%;
    height: 35px;
    line-height: 35px;
    text-indent: 15px;
    background: $grey-background 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 1px #00000029;
    border-radius: $element-border-radius;
    border: none;
    padding: 0;
    &:focus{
      outline: none;
    }
  }

  .input {
    width: 100%;
  }

  .plus, .minus {
    margin:0 0 6px 5px;
    height: 35px;
    width: 35px;
    text-align: center;
    font-size: $size22;
    font-weight: unset;
    color: $grey-text;
    background: $grey-background 0% 0% no-repeat padding-box;
    box-shadow: 0 1px 1px #00000029;
    border-radius: $element-border-radius;
    line-height: 35px;
    cursor: pointer;
  }

  .modal-content {
    margin: 0;
    padding: 0;
    width: 100%;
  }
}

.g4-container {
  max-height: 292px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
    .header-title {
      margin: 0px;
      font-size: 1.5rem;
      color: #192C49;
    }
    .message {
      width: 100%;
      max-height: 63px;
      height: 100%;
      line-height: 1.5rem;
      text-align: center;
      font-size: 1.4rem;
      color: #08232f;
      margin-top: 18px;
    }
  }
  
  .serial-container {
    display: flex;
    flex-direction: column;
    align-items: center;  
    .title {
      font-size: 16px;
      color: #192C49;
      margin: 0;
    }
    .serial {
      margin: 0px;
      color: #00A0E9;
      font-size: 18px;
      margin-bottom: 5px;
    }
  }

  .btn-container {
    background-color: #F7F8FA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 19px 0px;

    button.btn {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 334px;
      height: 35px;
      padding: 0;
      width: 100%;
      line-height: 35px;
      margin: 0 20px;
    }

    button.btn.continue {
      max-width: 150px;
    }
  }
}

@import "../../styles/shared";

.reports-wrapper {

  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin: $size16 0;
    box-sizing: border-box;
    justify-content: space-between;

    .search-with-filter-wrapper {
      .search-wrapper {
        display: none;
      }
    }

    .action-button {
      margin-left: auto;
    }
  }
  
  .buttons-wrapper {
    display: flex;
    gap: $size12;
    
    .refresh-icon {
      cursor: pointer;
      height: $size36;
      border-radius: $element-border-radius;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 $size16 0 $size16;
      box-sizing: border-box;
      background-color: $white;
      color: $primary-blue;
      border: 1px solid $primary-blue;
      
      svg {
        outline: unset !important;
        path {
          fill: $primary-blue;
        }
      }
      svg::focus {
        outline: unset !important;
      }
    }
  }

  .table-view {
    background-color: #fff;
    overflow-x: auto;
  }

  .content {
    border: 0px;
    margin: 0px;
    background-color: #f5f6fa;
  }

  .enter-mail-box {
    padding-left: 20px;
    background: #f5f6fa 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: $element-border-radius;
    margin: 6px;
    position: relative;
    border: 0px;
    height: 40px;
  }
  
  .form-field-date {
    display: flex;
    padding-left: 20px;
    background: #f5f6fa 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 1px #00000029;
    border-radius: $element-border-radius;
    margin: 6px;
    position: relative;
    border: 0px;
    height: 40px;
    width: 200px;
    .sterilization-img {
      padding: 10px 3px 10px 6px;
      path.a {
        fill: $primary-black;
      }
    }
  }

  .enter-mail-box {
    width: 450px;
    @media all and (max-width: 600px) {
      width: 90%;
    }
  }

  .legend {
    display: flex;
    justify-content: space-between;

    .icon {
      display: flex;
      text-align: left;
      font-size: $size12;
      letter-spacing: 0px;
      color: $primary-black;
      opacity: 1;
      margin: 0 $size8 $size16 $size8;
      cursor: pointer;
      align-items: center;
      img {
        margin-right: $size4;
      }
    }

    .left {
      display: flex;
      flex-direction: row;
    }
  }

  .email-submission-mobile {
    display: none;
  }
  .manage-wrapper {
    background-color: white;
    padding: 20px;

    .email-submission {
      h3 {
        margin-bottom: 0;
      }
      button {
        width: 200px;
        // padding: 10px;
        background-color: $primary-blue;
        color: white;
      }
    }

    h3 {
      img {
        margin-left: 10px;
      }
    }
    table {
      width: 100%;
      border-collapse: collapse;
      border-radius: $element-border-radius;
      border: 1px solid $medium-grey-blue;
      text-align: left;

      #td-spinner {
        .loading-spinner {
          position: relative;
          margin-top: 0%;
          width: 3em;
          height: 3em;
          border-width: 0.3rem;
          border-left-width: 0.3rem;
        }
      }

      tr {
        background-color: $table-header-grey;
        text-align: initial;

        th {
          height: 30px;
          text-align: initial;
          padding-left: 10px;
          width: 350px;
        }

        td {
          background-color: $white;
          padding: 10px;
          text-align: initial;

          button {
            margin-left: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .table-body {
    vertical-align: top;
  }

  .table-mobile-view {
    display: none;
  }

  .bold-table-content {
    text-align: left;
    letter-spacing: 0px;
    color: $primary-black;
    opacity: 1;
  }
  .normal-table-content,
  .normal-table-content2 {
    text-align: left;
    letter-spacing: 0px;
    color: $primary-black;
    opacity: 1;

    .params-wrapper {
      gap: $size8;
      margin-top: $size8;

      @media screen and (max-width: 1130px) {
        flex-direction: column;
      }
    }
  }

  .tooltip-content {

    .tooltiped-icon-wrapper {
      position: unset !important;

      .tooltip {
        bottom: unset;
        right: 5px;
        transform: translateY(-108%) scale(1);
        width: 70px;

        @media screen and (min-width: 2848px) {
          right: 25px;
        }

        @media screen and (min-width: 3548px) {
          right: 40px;
        }
      }

      svg {
        cursor: pointer;
      }
  
      svg:hover {
        .fillable-area {
          fill: $primary-blue !important;
        }
      }
    }
  }

  .list-content {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-auto-rows: auto;
    gap: $size8;

    li {
      display: flex;
      align-items: center;
    }

    li::before {
      content: "\2022";
      color: $primary-blue;
      font-weight: bold;
      font-size: $size16;
      display: inline-block;
      width: 12px;
      margin-left: 0;
    }

    @media screen and (max-width: 1460px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 1320px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .page-numbers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    gap: $size8;

    .page-number {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 6px;
      cursor: pointer;
      margin-right: 5px;

      &:hover {
        padding: 3px 6px;
        background-color: $dark-grey-blue;
        border-radius: 50%;
        color: #fff;
      }
    }

    .page-number-active {
      padding: 3px 6px;
      background-color: $primary-blue;
      border-radius: 50%;
      color: #fff;
    }

    .num {
      display: flex;
      justify-content: center;
      cursor: pointer;

      .jump-navigation-wrapper {
        margin-bottom: $size4;
        align-self: flex-end;

        .skip-arrow {
          display: none;
          width: 9px;
          height: 9px;
          fill: $primary-blue;
          margin-bottom: 2px;
        }

        .arrow-left {
          rotate: 180deg;
        }

        &:hover {
          .skip-arrow {
            display: block;
          }

          .dots {
            display: none;
          }
        }
      }
    }
  
    .prev-next {
      display: flex;
      align-items: center;
      height: 100%;
      color: $primary-blue;
      cursor: pointer;
    }
  }

  .cycle-number {
    color: $primary-blue;
    font-weight: 700;
    cursor: pointer;
  }

  .cycle-number-disabled {
    color: $primary-black;
    font-weight: 400;
    cursor: default;
  }

  .request {
    display: block;
    position: relative;

    button {
      width: 67px;
      height: 25px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #788090;
      border-radius: $element-border-radius;
      color: #768092;
      padding: 3px 9px;
    }
  }

  .request-mobile {
    display: none;
  }

  .table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    border-radius: $element-border-radius;

    .max-width-cell {
      width: max-content;
    }

    #td-spinner {
      position: relative;
      vertical-align: middle;
    }

    #td-spinner .loading-spinner {
      font-size: 16px;
      margin-top: 0%;
      position: absolute;
      top: calc(50% - 2em);
      left: calc(50% - 2em);
      width: 3em;
      height: 3em;
    }

    tr {

      th {
        border-right: 1px solid $medium-grey-blue;
      }

      td {
        border-right: 1px solid $medium-grey-blue;
        border-bottom: 1px solid $medium-grey-blue;
        padding: $size4 $size8;
      }

      th:last-of-type,
      td:last-of-type {
        border-right: none;
      }

      th {
        height: $size36;
        background-color: $table-header-grey !important;
        font-weight: 700;
        color: $primary-black;
        font-size: $size14;
        padding: 0 $size8;
        vertical-align: middle;
      }
    }

    .notes-icon {
      width: 18px;
      height: 18px;
      cursor: pointer;
    }
  }

  .table-data {
    display: flex;
    flex-direction: row;
    align-items: center;

    .table-image {
      display: inline-block;
      margin-right: $size4;
      flex-shrink: 0;

      img {
        width: 12px;
        height: 12px;
      }
    }

    .table-value {
      display: inline-block;
    }
  }

  .info {
    position: relative;
    display: inline-block;

    .info-modal {
      width: 300px;
      position: absolute;
      border-radius: $element-border-radius;
      border: 1px solid #e2e2e2;
      padding: 20px;
      background-color: #f5f6fa;
      text-align: left;
      font: normal normal normal 20px/24px Open Sans;
      letter-spacing: 0px;
      color: #11232e;
      opacity: 1;
      margin-top: 26px;
      box-shadow: $input-box-shadow;
      &:before {
        content: "";
        position: absolute;
        right: 100%;
        top: 26px;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-right: 26px solid #f5f6fa;
        border-bottom: 13px solid transparent;
      }
    }
    .info-modal-1 {
      left: 235px;
      top: -60px;
    }
    .info-modal-2 {
      left: 215px;
      top: -60px;
    }
    .info-modal-3 {
      left: 180px;
      top: -60px;
    }
    .info-modal-4 {
      left: 180px;
      top: -60px;
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 1080px) {
    .form-field-date {
      display: flex;
      padding-left: 5px;
      background: #f5f6fa 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 1px #00000029;
      border-radius: $element-border-radius;
      margin: 6px;
      position: relative;
      border: 0px;
      height: 40px;
      img {
        padding: 0px;
        text-align: left;
      }
    }
    
    .notes-column {
      width: 200px;
    }
  }

  @media all and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .actions-wrapper {
      padding: 0 $size16;
    }

    .legend {
      display: none;
    }

    .table-view {
      display: none;
    }

    .table-mobile-view {
      display: block;
      background: #fff;
      margin: 10px 0px;
      padding: 0 0 $size8 0;

      .table-head {
        display: flex;
        justify-content: space-between;
        padding: 10px $size16;
        .table-date {
          text-align: left;
          font: normal normal bold 14px/16px Roboto;
          letter-spacing: 0px;
          color: #00a0e9;
          opacity: 1;
        }

        .table-dots {
          font: normal normal bold 18px/16px Roboto;
        }

        .table-values {
          font: normal normal 14px/16px Roboto;
          letter-spacing: 0px;
          opacity: 1;
          
          display: flex;
          align-items: center;
          gap: $size4;
        }

        .cycle-number {
          color: $primary-blue;
          font-weight: 700;
          cursor: pointer;
        }

        .cycle-number-disabled {
          color: $primary-black;
          font-weight: 400;
          cursor: default;
        }
      }

      .parameters-wrapper {
        justify-content: flex-start;
        gap: $size24;
      }

      .table-values-out {
        width: 100%;
        padding: 5px $size16;
        font: normal normal 14px/16px Roboto;
        letter-spacing: 0px;
        opacity: 1;
        box-sizing: border-box;

        .left {
          display: inline-flex;
          width: 30%;

          @media (min-width: 475px) {
            width: 25% !important;
          }

          @media (min-width: 750px) {
            width: 15% !important;
          }
        }
        .right {
          display: inline-flex;
          width: 70%;
        }
      }
    }

    .manage-wrapper {
      .email-submission-mobile {
        display: flex;
        flex-direction: column;

        input {
          width: 90%;
        }
        button {
          border-style: none;
          border: 0px;
          width: 100%;
          padding: 10px;
          background-color: #768092;
          color: white;
        }
      }
    }

    .request-mobile {
      display: block;
      .labels {
        font-weight: bold;
      }

      .card {
        background-color: #f5f6fa;
        font-size: 14px;
        display: flex;
        justify-content: center;
        padding: 15px;
        box-shadow: 0px 1px 1px #00000029;
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 5px;
      }

      .right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 5px;
        font-weight: normal;
      }

      .buttons {
        background-color: #f5f6fa;
        text-align: right;
        padding: 10px;

        button {
          margin-left: 10px;
        }
      }
    }

    .request {
      display: none;
    }
    .email-submission {
      display: none;
    }
  }
  
  .capitalize {
    text-transform: capitalize;
  }
}
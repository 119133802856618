@import "../../styles/shared.scss";

.admin-subscriptions-history-wrapper {

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;

    .action-button {
      margin-left: auto;
    }
  }

  .date-wrapper {
    max-width: 150px;
  }

  .status-wrapper {
    display: flex;
    gap: $size8;
    align-items: center;

    svg {
      width: $size16;
      height: $size16;
    }

    .success-svg {
      .a {
        fill: $success-green;
      }
    }
    
    .pending-svg {
      width: $size24;
      height: $size24;
      margin-left: -4px;
      path {
        fill: $dark-yellow;
      }
    }

    .cancel-svg {
      width: $size24;
      height: $size24;
      margin-left: -4px;
    }

    .processed {
      width: $size16;
      height: $size16;
      color: $success-green;
    }

    .cancelled-subscription {
      color: $dark-grey-blue;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }


  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .actions-wrapper {
      flex-direction: column;
      gap: $size16;
      height: unset;
      align-items: flex-start;
      box-sizing: border-box;
      padding: 0 $size16;

      .action-button {
        margin-left: unset;
        width: 100%;
      }
    }

    .order-by-mobile-wrapper {
      height: $size36;
      width: calc(100% - 32px);
      margin: $size16;

      .click-outside-wrapper {
        border: 1px solid $medium-grey-blue;
        box-shadow: none;
        max-width: 100%;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: $primary-black;
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: hidden;

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }
        }

        .card-item-body-status {
          display: flex;
          gap: $size4;

          svg {
            width: $size16;
            height: $size16;
          }

          .success-svg {
            .a {
              fill: $success-green;
            }
          }

          .cancel-svg {
            width: $size24;
            height: $size24;
          }

          .processed {
            color: $success-green;
          }

          .cancelled-subscription {
            color: $dark-grey-blue;
          }
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
      margin-bottom: $size16;
    }
  }
}
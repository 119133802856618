@import '../../styles/shared';

.spareparts-modern-wrapper {

  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .content {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: $size16;
    min-height: 790px;
    border-radius: $element-border-radius;
    background-color: $white;
    box-shadow: 0px 0px 25px #0000000D;
    box-sizing: border-box;

    .product-types {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 33%;
      max-width: 300px;
      border-radius: $element-border-radius 0px 0px $element-border-radius;
      padding: $size16;
      box-sizing: border-box;
      border-right: 1px solid $medium-grey-blue;

      .card {
        display: flex;
        align-items: center;
        height: 80px;
        background-color: $light-grey-blue;
        border-radius: $element-border-radius;
        border: 1px solid transparent;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
          }
        }

        .product-type-name {
          font-size: $size16;
          color: $primary-black;
          font-weight: 700;
          margin: 0 $size16 0 0;
        }
      }

      .selected-card {
        border: 1px solid $primary-blue;
      }
    }
    
    .models-wrapper {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: $element-border-radius;

      .model-select-title {
        font-size: $size14;
        margin: $size24 $size16 $size8 $size16;
        font-weight: 500;
        color: $primary-black;
      }

      .click-outside-wrapper {
        margin: 0px $size16 $size32 $size16;
        border: 1px solid $medium-grey-blue;
        box-shadow: none;
        max-width: 328px;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }         

          svg {
            width: 12px;
            height: 12px;
            path {
              fill : $primary-black;
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: overlay;

          .options-filter {
            width: 100%;
            height: 100%;
            background-color: $white;
            z-index: 1;

            .search {
              background-color: $light-grey-blue;
              border-radius: $element-border-radius;
              margin: $size16 $size16 $size4 $size16 !important;
              box-shadow: none !important;
              z-index: 1;

              .search-icon {
                display: none;
              }
            }
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }

      .table-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 $size16 $size24 $size16;
        box-sizing: border-box;
        
        .product-header-wrapper {
          display: flex;
          align-items: flex-start;
          height: 64px;
          margin-bottom: $size16;

          .image-wrapper {
            width: 64px;
            height: 64px;
            justify-content: center;
            background-color: $white;
            border-radius: $element-border-radius;
            flex-shrink: 0;
            margin: 0 $size8 0 0;

            img {
              width: 64px;
              height: 64px;
            }
          }

          .product-name-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            gap: $size4;
            margin: 0 $size16 0 0;

            .product-name {
              color: $primary-black;
              font-weight: 700;
              font-size: $size16;
            }

            .product-quantity {
              color: $primary-blue;
              font-size: $size14;
            }
          }

          .search-with-filter-wrapper {
            margin-left: auto;
            border-radius: $element-border-radius;

            .search-wrapper {
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
            }

            .filter-button-wrapper {
              display: none;
            }
          }
        }

        .spare-parts-table {
          width: 100%;
          border-collapse: collapse;
          table-layout: fixed;

          .table-row {
            display: table-row;
            height: 50px;
            border-bottom: 1px solid $medium-grey-blue;
          }

          .header-row {
            height: $size36 !important;
            border-bottom: 1px solid transparent !important;

            th {
              position: sticky;
              top: 0;
              background-color: $light-grey-blue;
            }
          }

          .without-border {
            border: none !important; 
          }

          .table-column {
            margin: 0;
            padding: 0 $size8 0 $size16;
          }

          .last-column {
            padding: 0 $size16 0 $size16 !important;
          }

          .image {
            width: 40px;
            text-align: start;

            img {
              width: 40px;
              height: 40px;
              object-fit: cover;
              display: block;
              margin: 0 auto;
            }
          }

          .item-id {
            width: 76px;
            text-align: start;
          }

          .description {
            width: auto;
            min-width: 100px;
            text-align: start;
          }

          .components {
            width: 120px;
            text-align: start;

            .components-column-wrapper {
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 100%;
              width: 100%;

              svg {
                width: 24px;
                height: 24px;
              }
            }
          }

          .is-expandable {
            cursor: pointer;
          }

          .expanded-components {
            width: 100%;
            padding-bottom: $size4;
            color: $dark-grey-blue;

            .components-list-wrapper {
              display: flex;
              margin-left: 178px;
              width: 70%;

              .components-list {
                list-style: none;
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: $size4;
                align-items: flex-start;
                justify-content: center;
                width: max-content;

                li::before {
                  content: "\2022";
                  color: $primary-blue;
                  font-weight: bold;
                  font-size: $size16;
                  display: inline-block;
                  width: 12px;
                  margin-left: -16px;
                  align-self: flex-start;
                }

                .component-wrapper {
                  display: flex;
                  align-items: center;
                  width: 100%;

                  .component-description {
                    margin: 0;
                  }
                }                
              }
            }
          }
        }

        .buttons-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 30px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 100%;
      height: 100%;
      min-height: 250px;
      margin-top: $size16;
      border: none;
      box-shadow: none;
      background-color: inherit;

      .card {
        display: flex;
        align-items: center;
        height: 96px;
        width: 100%;
        background-color: $white;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
          }
        }

        .product-type-name {
          font-size: $size16;
          color: $primary-black;
          font-weight: 700;
          margin: 0 $size16 0 0;
        }
      }
    }
  }
}
@import "../../styles/shared";

.add-product-modal {

  .page-modal {

    .page-content {

      .add-product-modal-content-wrapper {
      
        .product-modal-tab {
          margin-top: $size16;
        }

        .section-delimiter {
          height: 2px;
          background: $medium-grey-blue;
          margin: $size32 -24px;
        }

        .internal-section-delimiter {
          height: 1px;
          background: $medium-grey-blue;
          margin: $size32 0;
        }

        .section-title {
          font-size: $size14;
          font-weight: 500;
          color: $dark-grey-blue;
          text-transform: uppercase;
        }

        .section-subtitle {
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
        }

        .input-label {
          display: flex;
          font-size: $size14;
          font-weight: 500;
          color: $primary-black;

          .required-mark {
            margin-left: $size4;
          }
        }

        .fixed-input-wrapper {
          display: flex;
          flex-direction: column;
          gap: $size8;
          height: 63px;
        }

        .one-third-wrapper {
          width: calc(calc(100% - 32px) / 3) !important;
        }

        .two-third-wrapper {
          width: calc(((100% - 32px) / 3) * 2 + 16px) !important;
        }

        .without-fixed-height {
          height: unset;
        }

        .with-flex-grow {
          flex-grow: 1;
        }

        .input-item {
          display: flex;
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
          align-items: center;
        }

        .text-area-input {
          width: 100%;
          height: 144px;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          font-size: $size14;
          padding: 10px $size16 10px $size16;
          line-height: 20px;
          box-sizing: border-box;
          outline: none;
          font-family: "Roboto";
        }

        .click-outside-wrapper {
          margin: 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 100%;
          box-sizing: border-box;
          cursor: pointer;

          .height {

            div {
              color: $primary-black;
              font-size: $size14;
            }         

            svg {
              width: 12px;
              height: 12px;
              path {
                fill : $primary-black;
              }
            }
  
            .icon-dropdown-open {
              transform: rotate(180deg);
            }

            .text-wrapper {
              text-indent: $size16;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .reset-filter-button {
              background: none;
              border: none;
              width: 24px !important;
              height: 24px !important;
              cursor: pointer;
              margin-right: $size4;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                flex-shrink: 0;
                width: 16px !important;
                height: 16px !important;

                g {
                  stroke: $dark-grey-blue;
                }
              }
            }
          }
  
          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 160px;
            position: relative;
            overflow: overlay;

            .option {
              font-size: $size14;
              line-height: 0px;
              padding: $size16 $size16 $size16 $size16;
              text-indent: 0;
              box-sizing: border-box;
              height: unset;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }

        .delete-option-wrapper {
          margin-top: $size32;
          width: $size24;
          height: $size24;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 12px;
        }

        .delete-option-wrapper:hover {
          background: $light-grey-blue;
          
          svg {
            .fillable-area {
              fill: $danger-red !important; 
            }
          }
        }

        .add-option-wrapper {
          margin-top: $size24;
          font-size: $size14;
          color: $primary-blue;
          cursor: pointer;
        }

        .input-error {
          border: 1px solid $required-red !important;
        }

        .with-margin-bottom {
          margin-bottom: $size8;
        }

        .with-margin-top {
          margin-top: $size16;
        }

        .product-details-wrapper {
          display: flex;
          flex-direction: column;
          gap: $size16;

          .product-options-wrapper {
            display: flex;
            gap: $size16;
          }
        }

        .product-images-wrapper {
          display: flex;
          flex-direction: column;
          gap: $size16;

          .images-instruction-box-text {
            display: flex;
            padding: $size16;
            border-radius: $element-border-radius;
            background-color: $transparent-blue;
            color: $primary-black;
            font-size: $size14;
            text-align: left;
          }

          .images-wrapper {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: $size16;

            .image-wrapper {
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid transparent;
              border-radius: $element-border-radius;
              background-color: $light-grey-blue;
              width: 100%;
              height: 0;
              padding-bottom: 100%;
              position: relative;

              .image {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: $element-border-radius;
                border: none;
              }

              svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }
              
              .remove-image {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 24px;
                height: 24px;
                border-radius: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              }

              .remove-image:hover {
                background-color: $light-grey-blue;

                svg {

                  .fillable-area {
                    fill: $danger-red !important; 
                  }
                }
              }

              .add-image-wrapper {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                gap: $size8;
                cursor: pointer;
                text-align: center;
                color: $primary-blue;
                font-size: $size14;

                .upload-svg {
                  position: initial !important;
                  transform: unset !important;
                }
              }

              .with-transparency {
                opacity: 0.4;
              }
            }
          }
        }

        .product-description-wrapper {
          display: flex;
          flex-direction: column;

          .product-options-wrapper {
            display: flex;
            gap: $size16;
            margin-top: $size16;
          }

          .title-with-action {
            display: flex;
            gap: $size16;
            align-items: center;

            .delete-option-wrapper {
              margin-top: 0;
            }
          }
        }

        .product-prices-wrapper {
          display: flex;
          flex-direction: column;

          .prices-options-wrapper {
            display: flex;
            gap: $size16;
            margin-top: $size16;
          }

          .price-plans-wrapper {
            display: flex;
            flex-direction: column;
            margin-top: $size16;
            gap: $size8;
            
            .one-third-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size8;
            }
          }
        }
      }
    }
  }
  
  .disabled {
    pointer-events: none;
    cursor: default;
    background-color: light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));
    color: light-dark(rgb(84, 84, 84), rgb(170, 170, 170)) !important;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .page-modal {

      .page-content {
        padding: $size16;

        .add-product-modal-content-wrapper {

          .section-delimiter {
            margin: $size32 -16px;
          }

          .fixed-input-wrapper {
            width: 100% !important;
          }

          .with-reduced-width {
            width: calc(100% - 40px) !important;
          }

          .with-big-margin-top {
            margin-top: $size32 !important;
          }

          .title-with-action-wrapper-mobile {
            display: flex;
            gap: $size16;
            align-items: center;

            .delete-option-wrapper {
              margin-top: 0;
            }
          }

          .product-images-wrapper {

            .images-wrapper {
              grid-template-columns: repeat(2, 1fr);
            }
          }
        }
      }
    }
  }
}

@import "../../styles/shared";

.firmware-release-notes-wrapper {
  padding: $size16;

  .product-name-latest {
    font-size: $size24;
    font-weight: 700;
    color: $primary-black;
  }

  .label-date {
    font-size: $size14;
    color: $dark-grey-blue;
    margin: $size8 0 0 0;
  }

  .release-notes-list {
    display: flex;
    flex-direction: column;
    padding: $size16 $size16 0 $size16;
    font-size: $size14;
    gap: $size4;

    .note-item {
      display: flex;
      gap: $size8;
      align-items: flex-start;
      

      .bullet {
        background-color: $primary-blue;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        flex-shrink: 0;
        margin-top: 6px;
      }
    }
  }
}
@import '../../../styles/shared';

.data-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $white;
  border-radius: $element-border-radius;
  align-self: center;

  .item-placeholder {
    display: flex;
    width: 129px;
    height: 19px;
    margin-bottom: 10px;
    padding: 5px 15px 5px 15px;
    align-items: center;
    color: $black;
    border: 1px solid $dark-grey-placeholder;
    border-radius: $element-border-radius;
  }

  .item-selected {
    border: 1px solid $dark-blue !important;
  }

  .icon-placeholder {
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $light-grey-placeholder;
    align-items: center;
    justify-content: center;

    .icon-checkmark {
      color: $white;
      font-size: $size10;
      -webkit-transform: scaleX(-1) rotate(-35deg);
      transform: scaleX(-1) rotate(-35deg);
    }
  }

  .icon-selected {
    background-color: $primary-blue !important;
  }

  .lines-placeholder {
    display: block;
    width: 80%;
    margin-left: 5px;

    .short-line {
      width: 80%;
      height: 4px;
      background-color: $light-grey-placeholder;
      margin-bottom: 4px;
      border-radius: 2px;
    }

    .long-line {
      width: 100%;
      height: 4px;
      border-radius: 2px;
      background-color: $light-grey-placeholder;
    }

    .line-selected {
      background-color: $dark-grey-placeholder !important;
    }
  }

  .title-placeholder {
    color: $black;
    font-size: $size20;
    font-weight: 600;
    margin: 44px 0 0 0 ;
  }

  .subtitle-placeholder {
    color: $dark-grey-placeholder;
    font-size: $size14;
    margin-top: 10px;
  }
}
@import '../../styles/shared';

.signup-container {
  margin-top: 22px;
  margin-bottom: 5.4%;

  div,
  p,
  input {
    font-size: 1rem;
  }

  a {
    color: #11232e;
    text-decoration: none;
  }

  .form-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 64px;
    border-radius: $element-border-radius;
    box-shadow: 0px 0px 25px #0000000D;
    margin: auto;
    max-width: 758px;
    width: 100%;

    .input-fields {
      @include display-flex;
      flex-direction: row;
      justify-content: space-between;
      
      .usergroup {
        margin: 0 auto;
      }

      @media all and (max-width: 480px) {
        flex-direction: column;
        flex: 1;
      }
    }
    
    .account-type-input {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: $size12;
    }

    .full-row {
      .input-col-wrapper {
        max-width: 100% !important;

        .input-box {
          position: unset;
          align-items: center;

          .address-svg {
            margin-left: $size8 !important;
          }

          .active {
            .a {
              fill: $success-green !important;
            }
          }
        }
      }
    }
    
    .one-third-row {
      .input-col-wrapper {
        max-width: 32%;
        @media all and (max-width: 480px) {
          max-width: 100%;
        }
      }
    }

    .disabled-row {
      .input-col-wrapper {

        .input-box {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .address-row {
      position: relative;

      .google-places-wrapper {
        position: absolute;
        background: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        max-height: 50vh;
        z-index: 2;
        width: 100%;
        top: 52px;

        .google-place {
          cursor: pointer;
          text-align: left;
          padding: 9px 21px;

          &:hover {
            background-color: $grey-background;
          }
        }
      }
    }

    .required {
      color: $error-color;
    }

    .input-col-wrapper {
      margin-bottom: 16px;
    }

    .select-col-wrapper {
      margin: 0 0 16px;
      
      .select-wrapper {
        background: #F5F6FA 0% 0% no-repeat padding-box;
        font-size: 1rem;;
        box-shadow: 0px 1px 1px #00000029;
        border-radius: $element-border-radius;
      }

      &.usergroup, &.companyType {
        margin-bottom: 29px;
        max-width: 255px;
        
        .select-wrapper {
          padding: 2px 10px 0 13px;
          background: transparent;
          box-shadow: 0px 3px 6px #00000014;
          border: 1px solid #E5E7F0;
          border-radius: $element-border-radius;
          // padding: 8px 20px;
        }

        input {
          background: #fff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        input {
          padding: 5px;
        }

        .placeholder {
          left: unset;
          top: 11px;
          bottom: unset;
          text-align: center;
        }
      }

      
    }

    .subscribe-wrapper,
    .privacy-wrapper {
      font-size: 1rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 16px auto;
      text-align: left;

      .privacy,
      .subscribe {
        display: flex;
      }

      span {
        font-weight: bold;
      }

      input {
        margin-right: 9px;
      }
    }

    .login-link {
      font-weight: bold;
    }

    .signup-link {
      margin-top: 50px;
      margin-bottom: 10px;

      a {
        font-weight: bold;
      }
    }

    .confirm-account {
      .input-fields {
        justify-content: center;
      }
    }

    @media all and (max-width: 768px) {
      padding: 40px 20px;
      max-width: 690px;
    }

    @media all and (max-width: 480px) {
      padding: 29px 11px;
      max-width: 320px;
    }
  }
  

  
}
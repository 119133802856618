@import "../../styles/shared";

$headerHeight: 5px;

.Toastify__toast-container {
  z-index: 10001;
}

.tracking-wrapper {
  .separator {
    width: 100%;
    margin-bottom: $size16;
    height: 1px;
    background-color: $medium-grey-blue;

    @media screen and (max-width: 763px) {
      width: calc(100% - 32px);
      margin: 0 $size16 $size16 $size16;
    }
  }

  .spinner-wrapper {
    margin-top: 150px;
    margin-bottom: 150px;

    .loading-spinner {
      left: calc(50% - 2em);
    }
  }

  .device-icon-image {
    width: 16px;
    height: 16px;
    display: flex;
    margin-left: $size8;
    margin-right: $size8;
  }

  .instrument-tracking-wrapper {
    display: flex;
    width: 100%;
    background-color: $white;
    box-sizing: border-box;
    border-radius: $element-border-radius;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    .instrument-tracking-left-container {
      width: 100%;
      max-width: 352px;
      padding: $size16;
      box-sizing: border-box;

      @media screen and (max-width: 768px) {
        border-radius: $element-border-radius;
      }

      &.extend-filters {
        height: 436px;
      }

      &.shrink-filters {
        height: 130px;
        overflow: hidden;
      }

      .it-filters-wrapper {
        width: 100%;
      }

      .arrow-dropdown-container {
        display: flex;

        .arrow-dropdown {
          padding: 14px;

          .it-caret {
            &.arrow-down {
              transform: rotate(180deg);
            }

            &.arrow-up {
              transform: rotate(0);
            }

            path {
              fill: #758091;
            }
          }
        }
      }

      .it-interval-current-date {
        display: flex;
        padding: 0 $size16;
        border-radius: $element-border-radius;
        background-color: $light-grey-blue;
        align-items: center;
        height: $size36;
        width: 100%;
        font-size: $size16;
        color: $primary-black;
        font-weight: 700;
        box-sizing: border-box;
      }

      .it-week-days-container {
        display: flex;
        gap: $size8;
        width: 100%;
        box-sizing: border-box;

        .week-day-letter {
          width: 40px;
          height: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          text-transform: uppercase;
          font-size: 10px;
          font-weight: 700;
          color: $dark-grey-blue;

          @media screen and (max-width: 768px) {
            width: 33px;
          }
        }
      }

      .it-days-nr-container {
        display: flex;
        gap: $size8;
        width: 100%;
        box-sizing: border-box;
        margin: 0 0 $size32 0;

        .week-day-nr {
          width: 40px;
          height: 40px;
          display: flex;
          flex-direction: column;
          gap: $size4;
          justify-content: flex-end;
          align-items: center;
          text-align: center;
          font-size: $size12;
          color: $primary-black;
          font-weight: 700;
          border-radius: 20px;
          cursor: pointer;

          &.current-day {
            color: $primary-blue;
          }

          &.selected-day {
            background: $light-grey-blue;
          }

          .cycles-dot-container {
            width: 6px;
            height: 6px;
            border-radius: 3px;
            background-color: transparent;
            margin-bottom: 4px;
          }

          .cycles-dot-visible {
            background-color: $danger-red;
          }

          @media screen and (max-width: 768px) {
            width: 32px;
            height: 32px;
          }
        }
      }

      .it-show-in-calendar-text {
        font-weight: 500;
        font-size: $size14;
        color: $dark-grey-blue;
        text-transform: uppercase;
        margin: $size16 0;
      }

      .filters-event-container {
        margin: 0 0 25px 0;

        .checkbox-rapport {
          margin-bottom: 5px;

          &.cycle_interrupted {
            input:checked ~ .checkmark {
              background-color: $tracking-yellow;
              border: 1px solid #ef9a11;
            }

            label {
              .checkmark:after {
                content: '';
                left: 5px;
                top: 2px;
                width: 4px;
                height: 8px;
                border: solid #ef9a11;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }

          &.complete {
            input:checked ~ .checkmark {
              background-color: $tracking-green;
              border: 1px solid #12b76a;
            }

            label {
              .checkmark:after {
                content: '';
                left: 5px;
                top: 2px;
                width: 4px;
                height: 8px;
                border: solid #12b76a;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }

          &.error {
            input:checked ~ .checkmark {
              background-color: $tracking-red;
              border: 1px solid #f04438;
            }

            label {
              .checkmark:after {
                content: '';
                left: 5px;
                top: 2px;
                width: 4px;
                height: 8px;
                border: solid #f04438;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
              }
            }
          }
        }

        label {
          display: flex;
          align-items: center;
          position: relative;
          margin-bottom: 0;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
          line-height: 100%;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          width: fit-content;

          .label-text {
            display: flex;
            padding-left: 10px;
            align-items: center;
          }

          /* Hide the browser's default checkbox */
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
          }

          input[disabled] + .checkmark {
            border: none;
          }

          /* Create a custom checkbox */
          .checkmark {
            display: inline-block;
            height: 16px;
            width: 16px;
            border: 1px solid #5f6a7b;
            border-radius: 4px;
            background: #F4F4F4;
            position: relative;
          }

          /* When the checkbox is checked, add a blue background */
          input:checked ~ .checkmark {
            background-color: $tracking-grey;
          }

          /* Create the checkmark/indicator (hidden when not checked) */
          .checkmark:after {
            content: "";
            position: absolute;
            display: none;
          }

          /* Show the checkmark when checked */
          input:checked ~ .checkmark:after {
            display: block;
          }

          /* Style the checkmark/indicator */
          .checkmark:after {
            content: '';
            left: 5px;
            top: 2px;
            width: 4px;
            height: 8px;
            border: solid #5f6a7b;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
      }

      .buttons-view-container {
        .button-view-change {
          font-size: $size14;
          color: $primary-black;
          border-radius: $element-border-radius;
          padding: $size8 $size16;
          cursor: pointer;

          &.active-btn {
            background: $light-grey-blue;
            font-weight: 500;
          }
        }
      }
    }

    .instrument-tracking-right-container {
      height: 100%;
      width: 100%;
      border-radius: $element-border-radius;

      .instrument-tracking-calendar-container {
        height: 100%;
        border-left: 1px solid $medium-grey-blue;

        @media screen and (max-width: 768px) {
          border-left: none;
          border-top: 12px solid $light-grey-blue;
        }

        &.week {
          .fc-timegrid-event-harness {
            width: 100%;
          }

          .fc-theme-standard {
            td {
              border: 1px solid $medium-grey-blue !important;
            }
          }

          .fc-timegrid-more-link-inner {
            color: $primary-blue;
            background-color: $white;
            font-weight: 500;
          }

          @media screen and (max-width: 1150px) {
            .calendar-event-wrapper {
              .calendar-event-details {
                display: none;
              }

              .container-event {
                width: 100%;
                justify-content: center;
              }

              .device-icon-image {
                margin: 0;
              }
            }

            .fc-popover {
              .calendar-event-wrapper {
                .calendar-event-details {
                  display: block;
                }

                .container-event {
                  justify-content: start;
                  width: calc(100% - 18px);
                }

                .device-icon-image {
                  margin-left: 5px;
                }
              }
            }
          }
        }

        &.day {
          .fc-timegrid-slot {
            font-weight: 700;
            font-size: $size12;
            height: 40px;
          }
        }

        .fc-theme-standard {
          td {
            border: none;
            border-top: 1px solid $medium-grey-blue;
            border-top-style: dotted;
          }
        }

        .fc-scrollgrid {
          border-left: 0;
        }

        .fc .fc-scrollgrid-section > * {
          border-right-width: 0;
        }

        .fc-media-screen {
          overflow: hidden;
          background: $white;
        }

        .fc-toolbar-title {
          margin-top: 16px;
          margin-left: 16px;
          font-size: $size16;
          font-weight: 700;
        }

        .fc-direction-ltr {

          .fc-timegrid-now-indicator-arrow {
            border-left-color: #04A0E9;
          }
        }

        .fc-timegrid-col {
          &.fc-day-today {
            background: rgba(4, 160, 233, 0.05);
          }
        }

        .fc-timeGridDay-view {
          .fc-timegrid-col {
            &.fc-day-today {
              background: white;
            }
          }
        }

        .fc-timegrid-now-indicator-line {
          border-color: #04A0E9;
        }

        .fc-timegrid-event-harness {
          width: 33%;
          max-height: 39px;
          overflow: hidden;
          border-radius: $element-border-radius;

          @media screen and (max-width: 768px) {
            width: 100%;
          }

          .fc-timegrid-event {
            height: 100%;
          }
        }

        .fc-timegrid-more-link {
          height: 18px;
        }

        .fc-popover {

          @media screen and (max-width: 393px) {
            left: initial!important;
            right: 7px;
            width: 280px;
          }
        }

        .fc-popover-body {
          > div {
            overflow: hidden;
            border-radius: $element-border-radius;
          }
        }

        .calendar-event-wrapper {
          position: relative;
          border-radius: $element-border-radius;
          cursor: pointer;
          height: 100%;
          padding-top: $size4;

          .calendar-event-line {
            position: absolute;
            width: 2px;
            top: -2px;
            left: -2px;
            background: transparent;
            height: calc(100% + 3px);

            &.sterilizer {
              background: #FFCB33;
            }

            &.washer {
              background: #3361FF;
            }
          }

          .container-event {
            height: 100%;
            width: calc(100% - 18px);
          }

          .calendar-event-details {
            width: 90%;
          }

          .calendar-event-title {
            margin: 0 5px 0;
            font-size: 14px;
            line-height: 100%;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .calendar-event-model {
              font-weight: 500;
              color: $primary-black;
            }

            .calendar-event-sn {
              margin-left: $size4;
              color: $dark-grey-blue;
            }
          }

          .calendar-event-subtitle {
            margin-left: 5px;
            margin-right: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 100%;
            color: rgba(0, 0, 0, 0.4);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .calendar-event-status {
            display: flex;
            align-items: center;
            padding-right: 3px;
            width: 18px;

            svg {
              width: 13px;
              height: 13px;
            }

            .success {
              .a {
                fill: #6BC64C;
              }
            }

            .error {
              .a {
                fill: red;
              }
            }
          }
        }
        
        @media screen and (max-width: 768px) {
          .fc-view-harness {
            height: 500px !important;
          }
        }
      }
    }
  }
}

footer {
  clear: both;
  text-align: center;
  bottom: 0;
  position: relative;
  padding-top: 44px;
  margin: 20px auto;
  height: 40px; 
  width: 100%;
  z-index: 1;

  > div {
    padding: 10px 10px 0px 10px;
  }
}
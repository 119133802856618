@import '../../styles/shared';

.remote-access-login {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 500px;
  margin-top: $size16;
  border-radius: $element-border-radius;
  background-color: $white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);

  .input-wrapper-token {
    padding: $size16 $size24;
    display: flex;
    width: 100%;
    align-items: center;
    gap: $size8;
    box-sizing: border-box;

    .search-with-filter-wrapper {
      .filter-button-wrapper {
        display: none;
      }

      .search-wrapper {
        .search {
          background: $light-grey-blue;
        }
      }
    }
  }

  .message {
    margin-left: $size24;
    font-size: $size16;
    font-weight: 500;
  }

  .error {
    margin: 0 $size24 $size8 $size24;
    color: $red;
    font-size: $size14;
    font-style: italic;
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
}

@media screen and (max-width: 450px) {
  .remote-access {
    max-width: 320px;
    left: calc(50% - 160px);

    .content-wrapper {
      .input-wrapper {
        .error {
          top: 270px;
        }
      }
    }
  }
}

.remote-session-active {
  border: 1px solid red;
}
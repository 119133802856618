@import "../../styles/shared";

.add-report-note-modal {
  .page-modal {
    min-width: unset;
    height: 400px;

    .add-note-content {
      width: 100%;
      box-sizing: border-box;

      .input-option {
        color: $primary-black;
        font-size: $size14;
        font-weight: 500;
        margin: 0 0 $size8 0;
      }

      .note-input {
        width: 100%;
        height: 160px;
        margin-bottom: $size24;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        font-size: $size14;
        padding: 10px $size16 10px $size16;
        line-height: 20px;
        box-sizing: border-box;
        outline: none;
        font-family: "Roboto";
      }

      .note-input:focus {
        border: 1px solid $primary-blue;
      }
    }
  }

  @media all and (max-width: 768px) {
    .page-modal {
      height: 100% !important;
    }
  }
}
@import "../../../styles/shared";

.actions-menu-wrapper {
  position: absolute;
  transform: translateY(60%) scale(1);
  right: 40px;
  z-index: 100;
  padding-bottom: $size24;

  .actions-menu {
    border-radius: $element-border-radius;
    border: 1px solid $medium-grey-blue;
    background-color: $white;
    padding: $size16 0;
    cursor: pointer;
    min-width: 200px;
    box-shadow: 8px 4px 16px 0 rgba(0, 0, 0, 0.08);

    .menu-item {
      margin: 0;
      padding: 0 $size16;
      display: flex;
      align-items: center;
      height: $size36;
      font-size: $size14;
      color: $primary-black
    }

    .menu-item:hover:not(.disabled) {
      background-color: $light-grey-blue;
    }

    .disabled {
      opacity: 0.4;
      pointer-events: none;
    }
  }  
}

@import "../../../styles/shared";

.video-modal {
  .back {
    cursor: pointer;
    svg {
      width: 29px;
      height: 29px;
    }
  }

  .modal {
    top: calc((100vh + 69px) / 2);
    overflow: hidden;
    max-width: 950px;
    padding: 0 0 37px 0;

    @media screen and (max-width: 1024px) {
      margin: 10px;
    }

    .iframe-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;
      margin: 10px 35px 0 35px;

      .iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: none;
      }
    }

    .close {
      height: 28px;
      svg {
        margin: 10px 6px 0 0;
      }
    }
  }
}

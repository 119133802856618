@import "../../styles/shared";

.cycle-modal-wrapper {
  .page-modal {
    height: 50%;

    .page-content {
      padding: 0;

      .cycle-modal-content {
        width: 100%;
        box-sizing: border-box;

        .status-wrapper {
          display: flex;
          width: calc(100% - 48px);
          height: $size32;
          padding: 0 $size16;
          margin: 0 $size24;
          align-items: center;
          justify-content: center;
          box-sizing: border-box;
          border-radius: $element-border-radius;
          font-size: $size14;
          font-weight: 500;
        }

        .in_progress {
          background-color: $tracking-grey;
          color: #5f6a7b;
        }

        .completed {
          background-color: $tracking-green;
          color: #12b76a;
        }

        .cycle_interrupted {
          background-color: $tracking-yellow;
          color: #ef9a11;
        }

        .error {
          background-color: $tracking-red;
          color: #f04438;
        }

        .device-wrapper {
          display: flex;
          width: 100%;
          margin-top: $size16;
          padding: 0 $size24;
          box-sizing: border-box;
          gap: $size16;

          .image-wrapper {
            justify-content: center;
            height: 80px;
            width: 80px;
            flex-shrink: 0;
  
            img {
              height: 80px;
              width: 80px;
              object-fit: contain;
            }
          }

          .device-info-wrapper {
            display: flex;
            flex-direction: column;

            .device-model {
              font-size: $size16;
              font-weight: 700;
              color: $primary-black;
              margin-bottom: $size4;
            }

            .device-serial-number {
              font-size: $size14;
              color: $primary-blue;
              margin-bottom: $size8;
            }

            .cycle-instrument {
              font-size: $size14;
              color: $dark-grey-blue;
            }

            .cycle-info {
              display: flex;
              width: 100%;
              margin-top: $size24;
              gap: $size48;

              .cycle-data-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size4;
                width: 50%;

                .data-title {
                  font-size: $size14;
                  color: $dark-grey-blue;
                  font-weight: 700;
                }

                .data-value {
                  font-size: $size14;
                  color: $primary-black;
                }
              }
            }
          }
        }
      }
    }
  }
}

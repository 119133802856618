@import '../../../styles/shared';

.price-input-wrapper {
  display: flex;
  width: 100%;
  height: $size36;
  border: 1px solid $medium-grey-blue;
  border-radius: $element-border-radius;
  box-sizing: border-box;
  align-items: center;

  .price-input-item {
    flex-grow: 1;
    margin: 0 $size8 0 0;
    display: flex;
    outline: none;
    box-sizing: border-box;
    align-items: center;
    text-indent: $size16;
    border: none;
  }

  .price-separator {
    width: 1px;
    height: 70%;
    background-color: $medium-grey-blue;
  }

  .price-currency {
    margin: 0 $size8;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size24;
    height: $size24;
    flex-shrink: 0;
    font-size: $size14;
    color: $primary-blue;
    font-weight: 700;
  }
}

.with-price-error {
  border: 1px solid $danger-red;
}
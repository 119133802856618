@import "../../../styles/shared";

.my-account-wrapper {
  .payment-methods-wrapper {
    display: block;
    width: 100%;
    height: 100%;
    min-height: 790px;
    margin-top: $size16;
    padding-bottom: $size16;
    border-radius: $element-border-radius;
    background-color: $white;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);

    .title {
      font-size: $size14;
      font-weight: 500;
      color: $dark-grey-blue;
      padding: $size24;
      margin: 0;
    }

    .payment-methods-list {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 $size24;
      box-sizing: border-box;
      gap: $size8;

      .payment-method {
        display: flex;
        flex-direction: column;
        gap: $size8;
        width: 100%;
        padding: $size16;
        border-radius: $element-border-radius;
        background-color: $light-grey-blue;
        box-sizing: border-box;

        .card-data {
          display: flex;
          gap: $size16;

          .brand {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            border-radius: $element-border-radius;
            width: 58px;
            height: $size40;
          }

          .info {
            display: flex;
            flex-direction: column;
            
            .last-four {
              display: flex;
              gap: $size4;
              align-items: center;
              font-size: $size16;
              font-weight: 700;
              color: $primary-black;
            }

            .exp-date {
              font-size: $size14;
              color: $dark-grey-blue;
              margin: $size4 0 0 0;
            }
          }

          .actions {
            display: flex;
            justify-content: flex-end;
            position: relative;

            .actions-menu-wrapper {
              right: 0;
              transform: translateY(20%) scale(1);
            }

            .with-background {
              g {
                rect {
                  fill: $white !important;
                }
              }
            }
          }

          .with-margin-left {
            margin-left: auto;
          }
        }

        .holder-data {
          display: flex;
          margin-left: 74px;

          .holder-email {
            display: flex;
            gap: $size8;
            align-items: center;
            color: $dark-grey-blue;
            font-size: $size14;
          }
        }
      }
    }

    .action-button {
      margin: $size24;
    }

    .empty-list {
      .title {
        font-size: $size24;
        font-weight: 700;
        color: $primary-black;
      }
    }

    .create-payment-method-modal {
      .page-modal {

        .page-content {
          padding: 0 $size16;
          height: calc(100% - 69px);

          .create-payment-method-content {
            height: 100%;

            .update-form {
              display: flex;
              flex-direction: column;
              width: 100%;
              height: 100%;
              box-sizing: border-box;
              padding-bottom: $size24;

              .update-form-footer {
                margin-top: auto;
                display: flex;
                justify-content: flex-end;
                gap: $size8;
                box-sizing: border-box;
              }
            }
          }
        }

        .page-footer {
          display: none;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .payment-methods-wrapper {
      height: max-content;
      min-height: unset;
      box-sizing: border-box;
      border-radius: 0;

      .title {
        padding: $size16 $size16 $size24 $size16;
      }

      .payment-methods-list {
        padding: 0 $size16;
      }

      .action-button {
        margin: $size24 $size16 $size16 $size16;
        width: calc(100% - 32px);
      }

      .create-payment-method-modal {
        .page-modal {
          .page-content {
            .create-payment-method-content {
              .update-form {

                .update-form-footer {
                  .cancel-button,
                  .submit-button {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 413px) {
    .payment-methods-wrapper {

      .payment-methods-list {

        .payment-method {

          .holder-data {
            margin-left: 0;
          }
        }
      }
    }
  }
}

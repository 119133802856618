@import "../../styles/shared";

.main-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: $size32;
  padding: $size16;
  box-sizing: border-box;

  .section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    gap: $size4;

    .title {
      margin: 0 0 $size12 0;
      color: $dark-grey-blue;
      font-size: $size14;
      font-weight: 500; 
      
      .data-source {
        font-weight: normal;
      }
    }

    .data {
      display: flex;
      width: 100%;
      gap: $size16;
      box-sizing: border-box;

      .label {
        margin: 0;
        width: 45%;
        color: $primary-black;
        font-weight: bold;
        line-height: $size24;
      }

      .label-data {
        width: 75%;
        margin: 0;
        font-size: $size14;
        color: $dark-grey-blue;
        line-height: $size24;

        @media screen and (max-width: 768px) {
          text-align: right;
        }
      }
    }

    .with-margin-bottom {
      margin-bottom: $size24;
    }

    img {
      max-width: 100%;
    }
  }
}

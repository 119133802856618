@import "../../../styles/shared";

.responsive-table-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  .table-row {
    min-height: 50px;
    display: flex;
    width: 100%;
    box-sizing: border-box;
    gap: $size24;
    background-color: $white;
    padding: 0 $size16;
    border-bottom: 1px solid $medium-grey-blue;
    
    .table-column {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      min-width: 0;
      align-items: center;

      .status-wrapper {
        display: flex;
        gap: $size8;
        align-items: center;

        .status-icon {
          display: flex;
          width: $size24;
          height: $size24;
          align-items: center;
          justify-content: center;
        }

        .status-text {
          font-size: $size14;
          white-space: nowrap;
        }

        .activated {
          color: $success-green;
        }

        .pending {
          color: $dark-yellow;
        }

        .disabled,
        .closed {
          color: $dark-grey-blue;
        }
        
        .in-progress {
          color: $primary-blue;
        }
      }

      .product-wrapper {
        display: flex;
        gap: $size8;
        align-items: center;
        width: calc(100% - 32px);

        .product-image {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          img {
            width: $size24;
            height: $size24;
            object-fit: contain;
          }
        }
      }

      .country-wrapper {
        display: flex;
        gap: $size8;
        align-items: center;

        svg {
          width: $size14;
          height: $size14;
        }

        p {
          margin: 0;
          font-size: $size14;
          color: $primary-black;
          white-space: nowrap;
        }
      }

      .actions-wrapper {
        display: flex;
        width: $size24;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .row-effects {
    cursor: pointer;
  }

  .row-effects:hover {
    background-color: $light-grey-blue;
  }

  .header {
    height: $size36;
    background-color: $table-header-grey !important;
    font-weight: 700;
    border-bottom: 0;
    min-height: unset;

    .header-item-wrapper {
      display: flex;
      align-items: center;

      .header-sort {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 10px;
        margin-right: 10px;

        svg {
          width: 8px;
          height: 8px;
          cursor: pointer;
          fill: $dark-grey-blue;
        }

        svg:last-of-type {
          transform: rotateX(180deg);
        }

        .active-sort {
          fill: $primary-blue;
        }
      }
    }
  }
}

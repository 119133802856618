@import "../../styles/shared";

.firmware-download-wrapper {

  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .content-firmware-download {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: $size16;
    min-height: 790px;
    border-radius: $element-border-radius;
    background-color: $white;
    box-shadow: 0px 0px 25px #0000000D;
    box-sizing: border-box;

    .devices {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 33%;
      max-width: 340px;
      border-radius: $element-border-radius 0px 0px $element-border-radius;
      padding: $size16 0;
      box-sizing: border-box;
      border-right: 1px solid $medium-grey-blue;

      .devices-title {
        font-size: $size16;
        font-weight: 500;
        color: $primary-black;
        margin: 0 $size16 $size16 $size16;
      }

      .separator-line-device {
        height: 1px;
        background-color: $medium-grey-blue;
      }

      .search-wrapper {
        margin: $size16;

        .search {
          margin: 0 $size8 0 0 !important;
          box-shadow: none !important;
          width: 100%;
          background: $light-grey-blue;

          svg {
            display: none;
          }
        }
      }

      .card {
        display: flex;
        align-items: center;
        min-height: 80px;
        margin: 0 $size16;
        background-color: $light-grey-blue;
        border-radius: $element-border-radius;
        border: 1px solid transparent;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
          }
        }

        .device-name-wrapper {
          display: flex;
          width: 100%;
          gap: $size4;
          margin: $size4 $size16 $size4 0;

          .device-name {
            font-size: $size16;
            color: $primary-black;
            font-weight: 700;
            margin: 0;
          }

          .versions {
            margin: 0;
            font-size: $size14;

            .current-version {
              color: $dark-grey-blue;
            }

            .latest-version {
              color: $primary-blue;
            }
          }
        }
      }

      .selected-card {
        border: 1px solid $primary-blue;
      }
    }

    .device-firmware-wrapper {
      width: 100%;

      .device-firmware-header {
        display: flex;
        padding: $size24 $size16 $size32 $size16;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size24 0 0;
          height: 80px;
          width: 80px;
          background-color: $white;
          flex-shrink: 0;

          img {
            width: 70px;
            height: 70px;
          }
        }

        .firmware-title-header {
          display: flex;
          flex-direction: column;
          gap: $size8;

          .device-name {
            font-size: $size24;
            font-weight: 700;
            color: $primary-black;
            margin: 0;
          }

          .firmware-latest-release {
            font-size: $size14;
            color: $dark-grey-blue;
          }

          .firmware-version-available {
            font-size: $size14;
            color: $primary-blue;
          }
        }
      }

      .wrapped-tabs-wrapper {
        .tabs-container {
          padding: 0 $size16;
        }
      }
    }

    .data-placeholder {
      align-self: flex-start;
      margin-top: 240px;
    }    
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      height: 1px;
      background-color: $medium-grey-blue;
      margin: 0 $size16;
    }

    .content-firmware-download {
      display: flex;
      flex-direction: column;
      gap: $size4;
      width: 100%;
      height: 100%;
      min-height: 250px;
      margin-top: $size16;
      border: none;
      box-shadow: none;
      background-color: inherit;

      .devices-title {
        font-size: $size16;
        font-weight: 500;
        color: $primary-black;
        margin: 0 $size16 $size16 $size16;
      }

      .search-wrapper {
        margin: 0 $size16 $size16 $size16;

        .search {
          margin: 0 $size8 0 0 !important;
          box-shadow: none !important;
          width: 100%;
          background: $white;

          svg {
            display: none;
          }
        }
      }

      .card {
        display: flex;
        align-items: center;
        height: 80px;
        width: 100%;
        background-color: $white;
        cursor: pointer;
        box-sizing: border-box;

        .image-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 $size16 0 $size8;
          height: 64px;
          width: 64px;
          background-color: $white;
          border-radius: $element-border-radius;
          flex-shrink: 0;

          img {
            width: 44px;
            height: 44px;
          }
        }

        .device-name-wrapper {
          display: flex;
          width: 100%;
          gap: $size4;
          margin-right: $size16;

          .device-name {
            font-size: $size16;
            color: $primary-black;
            font-weight: 700;
            margin: 0;
          }

          .versions {
            margin: 0;
            font-size: $size14;

            .current-version {
              color: $dark-grey-blue;
            }

            .latest-version {
              color: $primary-blue;
            }
          }
        }
      }
    }
  }
}

@import "../../styles/shared";

.accounts-management-wrapper {

  .wrapped-tabs-wrapper {

    .arrow {
      background-color: $grey-background;

      &.arrow-left {
        border-right: 10px solid rgba(245, 246, 250, .7);
      }
  
      &.arrow-right {
        border-left: 10px solid rgba(245, 246, 250, .7);
      }
    }
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;

    .account-actions {
      display: flex;
      margin-left: auto;
      gap: $size8;
    }
  }
  
  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .name-wrapper {
    .text {
      color: $primary-blue;
    }
  }

  .status-wrapper {
    display: flex;
    align-items: center;

    .status-active {
      display: flex;
      align-items: center;
    }

    .status-inactive {
      display: flex;
      align-items: center;
    }

    svg {
      margin-right: $size8;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  .delete-account-confirmation {

    .confirmation-modal-message {
      margin-bottom: $size8;
    }

    .confirmation-modal-input {
      padding-left: 56px;

      .tips {
        color: $dark-grey-blue;
      }

      .title {
        margin-top: $size24;
        font-weight: 500;
      }

      input {
        width: 100%;
        height: $size36;
        padding: $size4 $size16;
        margin-top: $size8;
        box-sizing: border-box;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        outline: none;
      }
    }

    .confirmation-modal-buttons {
      margin-top: $size32;
    }
  }

  @media screen and (max-width: 768px) {
    .actions-wrapper {
      flex-direction: column;
      gap: $size16;
      height: unset;
      align-items: flex-start;
      padding: 0 $size16;

      .account-actions {
        margin-left: 0;
        flex-direction: column;
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }
          
          .card-item-body-status {
            display: flex;
            gap: $size8;

            p {
              margin: 0;
            }

            .status-active {
              display: flex;
              align-items: center;
            }

            .status-inactive {
              display: flex;
              align-items: center;
            }
          }
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .delete-account-confirmation {

      .confirmation-modal-input {
        padding-left: 0;

        .tips {
          text-align: center;
        }
      }

      .confirmation-modal-buttons {
        flex-direction: column;

        button {
          height: $size36;
        }
      }
    }
  }
}

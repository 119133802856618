@import '../../styles/shared';

.register-container {
  margin-bottom: 5.4%;
  margin-top: 22px;

  div,
  p,
  input {
    font-size: 1rem;
  }

  a {
    color: #11232e;
    font-weight: 700;
    text-decoration: none;
  }

  .type-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 64px;
    border-radius: $element-border-radius;
    box-shadow: 0px 0px 25px #0000000D;
    margin: auto;
    max-width: 758px;
    //width: 100%;

    // .divider {
    //   border: 2px solid $primary_blue;
    //   margin: 40px auto;
    //   max-width: 100px;
    // }

    .select-account-type {
      display: flex;
      flex-flow: row wrap;
      max-width: 100%;

      .account-type {
        display: flex;
        flex-direction: column;
        width: 164px;
        align-items: center;
        flex: 1;
        margin: 40px;

        @media all and (max-width: 480px) {
          width: 132px;
          margin: 20px;
        }

        .type-icon {
          //@include display-flex;
          cursor: pointer;
          background: $light-grey-blue;
          border-radius: 50%;
          //align-items: center;
          //justify-content: center;
          margin-bottom: 19px;
          height: 133px;
          width: 133px;

          span {
            @include display-flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
          }

          &:hover {
            background: $primary-blue;
            border-radius: 50%;
            height: 133px;
            width: 133px;
            z-index: 1;

            .icon {
              background: $primary-blue;
              height: initial;
              width: initial;

              path {
                fill: #fff;
              }
            }
          }

          .icon {
            border-radius: 0;
          }

          @media all and (max-width: 480px) {
            width: 93px;
            height: 93px;

            &:hover {
              height: 93px;
              width: 93px;
            }
          }
        }

        .type-name {
          font-size: 1.1rem;
          font-weight: 700;
        }
      }
    }

    @media all and (max-width: 768px) {
      padding: 40px 20px;
      max-width: 690px;
    }

    @media all and (max-width: 480px) {
      padding: 29px 11px;
      max-width: 320px;
    }
  }
}
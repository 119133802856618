@import "../../styles/shared";

.videos-wrapper {
 
  .divider {
    margin-bottom: $size16;
    height: 1px;
    background-color: $scrollbar-grey;
    display: flex;
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
    gap: $size8;

    .thumbnail-mask {
      height: 2.78rem;
      width: 5rem;
      border-radius: $element-border-radius;
      display: inline-block;
      position: relative;
      overflow: hidden;
      flex-shrink: 0;

      .thumbnail {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      
      .thumbnail-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
    }

    .title {
      margin: $size4 0;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .empty-results-margin {
    margin-top: 1rem;
  }
  
  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }
  
  @media all and (max-width: 768px) {
    .actions-wrapper {
      height: unset;
      flex-direction: column;
      padding: 0 $size16;
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .video-icon-title {
          height: $size36;
          width: 56px;
          display: flex;
          cursor: pointer;

          .thumbnail-mask {
            height: $size36;
            width: 56px;
            border-radius: $element-border-radius;
            display: inline-block;
            position: relative;
            overflow: hidden;
            flex-shrink: 0;
      
            .thumbnail {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
            
            .thumbnail-icon {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translateX(-50%) translateY(-50%);
            }
          }
        }

        .card-item-body {
          width: 70%;
          padding-right: $size40;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;
          }
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
      margin-bottom: $size16;
    }
  }

  @media all and (max-width: 480px) {
    .tabs-wrapper {
      .tabs {
        img {
          display: flex;
        }
      }
    }
  }
}

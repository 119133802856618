@import "../../styles/shared";

.remote-access-wrapper {

  .wrapped-tabs-wrapper {

    .arrow {
      background-color: $grey-background;

      &.arrow-left {
        border-right: 10px solid rgba(245, 246, 250, .7);
      }
  
      &.arrow-right {
        border-left: 10px solid rgba(245, 246, 250, .7);
      }
    }
  }

  .active-remote-session-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    margin: $size30 $size24 $size24 $size24;
    display: flex;
    gap: $size16;

    @media screen and (max-width: 768px) {
      padding: 0 $size16;
      position: inherit;
      margin: 0;
    }

    @media screen and (max-width: 436px)  {
      flex-direction: column;
      gap: $size8;

      .simple-button {
        width: 100%;
      }
    }

    .timer-wrapper {
      display: flex;
      align-items: center;
      gap: $size16;

      .timer-title {
        font-size: $size16;
        color: $primary-black;
        display: flex;

        .timer-note {
          display: flex;
          align-items: center;
          margin-left: 2px;

          .tooltip {
            width: max-content;
            left: 50%;
            transform: translateX(-50%);
            bottom: 19px;
          }

          svg {
            width: 14px;
            height: 14px;
          }
        }
      }

      .timer-elapsed {
        font-weight: bold;
        font-size: $size24;
        color: $primary-black;
      }
    }
  }
}
@import "../../../styles/shared.scss";

.my-account-wrapper {
  .my-subscriptions-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 790px;
    margin-top: $size16;
    box-sizing: border-box;
    gap: $size16;

    .section-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size16;
      width: 100%;
      padding: 0 $size24 $size16 $size24;
      border-radius: $element-border-radius;
      background-color: $white;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;

      .wrapper-header { 
        display: flex;
        padding: $size24 0 $size8 0;
        box-sizing: border-box;

        .wrapper-title {
          font-size: $size14;
          font-weight: 500;
          color: $dark-grey-blue;
          margin: 0;
          text-transform: uppercase;
        }

        .simple-button {
          margin-left: auto;
        }

        .header {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-bottom: $size16;
      
          .back-icon {
            width: $size32;
            height: $size32;
      
            &:hover {
              cursor: pointer;
            }
          }
      
          .header-title {
            margin-left: $size14;
            font-size: $size24;
            color: $primary-black;
            text-align: left;
            font-weight: 700;
          }
        }
      }

      .subscriptions-expand-header {
        display: flex;
        height: 56px;
        padding: 0 $size16;
        align-items: center;
        box-sizing: border-box;
        cursor: pointer;
        border-bottom: 1px solid $medium-grey-blue;

        .expanding-title {
          margin: 0;
          font-size: $size14;
          font-weight: 700;
        }

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-left: auto;
          border-radius: 12px;
        }
      }

      .expanded-header {
        background-color: $light-grey-blue;
        border-bottom: 0;
        border-radius: $element-border-radius;
      }

      .without-border {
        border-bottom: 0;
      }
  
      .subscription-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .details {
          width: 60%;
          min-height: $size48;
          height: max-content;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: $size8;

          .row {
            display: flex;
            align-items: flex-start;

            .details-l {
              width: 25%;
              font-size: $size14;
              color: $primary-black;
              display: flex;
              align-items: flex-start;
              margin: 0;
            }

            .details-r {
              width: 75%;
              font-weight: 700;
              font-size: $size14;
              color: $primary-black;
              margin: 0 0 0 $size8;
              display: flex;
              align-items: flex-start;
            }

            .subscription-title:hover {
              cursor: pointer;
            }

            .active {
              color: $green;
            }

            .unpaid,
            .incomplete,
            .trialing {
              color: $dark-yellow;
            }

            .expired,
            .incomplete_expired,
            .past_due,
            .canceled {
              color: $error-color;
            }
          }
        }

        .aditional-information-row {
          height: $size40;

          .aditional-information {
            padding-left: $size16;
            height: $size40;
            margin-bottom: $size16;
            display: flex;
            align-items: center;
            border-radius: $size4;
            background-color: $light-cream;
            color: $primary-black;
          }
        }

        .button-wrapper {
          width: 40%;
          gap: $size8;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        margin: $size16 0;
        background-color: $medium-grey-blue;
      }

      .empty-page-wrapper {
        min-height: 330px;
      }

      .plan-title-wrapper {
        max-width: 250px;
      }

      .plan-type-wrapper {
        text-transform: capitalize;
      }

      .buttons-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 20px;
      }
    }

    .with-min-height {
      min-height: 450px;
    }

    .spinner-wrapper {
      position: fixed;
      z-index: 9999;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      .loading-spinner {
        position: relative;
        top: 25%;
        left: 50%;
        transform: translateY(-50%);
      }
    }

    .cancel-subscription-modal {
      .modal {
        width: 500px;
        height: 500px;
        border-radius: 20px;
      }
      .close-modal-wrapper {
        height: 80%;

        .modal-image {
          margin-top: 20px;

          svg {
            g:first-child {
              stroke: $grey-background;
            }
            g:last-child {
              line {
                stroke: $red;
              }
            }
          }
        }

        .modal-title {
          margin-top: 80px;
          font-size: $size32;
          font-weight: 600;
          line-height: 40px;
        }

        .buttons-wrapper {
          width: 80%;
          margin-top: auto;
          align-self: center;

          button {
            cursor: pointer;
            width: 45%;
            margin-top: auto;
            justify-content: center;
            padding: 10px;
            border: none;
            border-radius: 10px;
          }

          button:last-child {
            background-color: $light-blue;
            color: white;
          }
        }
      }
    }
  }

  .my-subscriptions-wrapper-loading {
    z-index: -1;
  }

  @media screen and (max-width: 768px) {
    .my-subscriptions-wrapper {
      .section-wrapper {
        padding: 0 $size16 $size16 $size16;
        border-radius: 0;

        .subscription-container {
          flex-direction: column;

          .details {
            width: 100%;

            .row {
              .details-l {
                width: 50%;
                line-height: $size20;
              }

              .details-r {
                width: 50%;
                line-height: $size20;
                justify-content: flex-end;
                text-align: right;
              }
            }

            .aditional-information-row {
              .aditional-information {
                margin-left: 0px;
                width: calc(100% - 132px);
                position: absolute;
                padding-left: 0px;
                justify-content: center;
                height: $size40;
              }
            }
          }

          .button-wrapper {
            width: 100%;

            .action-button,
            .submit-button {
              height: $size30;
              width: 100%;
              margin-top: $size24;
            }
          }
        }

        .card {
          width: 100%;
          min-height: $size48;
          height: max-content;
          padding: $size16 $size16 0 $size16;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: $size8;
          cursor: pointer;
          border: 1px solid $medium-grey-blue;
  
          .card-item {
            display: flex;
            align-items: flex-start;
  
            .card-item-title {
              width: 25%;
              font-size: $size14;
              font-weight: 700;
              color: $primary-black;
              margin: 0;
            }
  
            .card-item-body {
              width: 75%;
              font-size: $size14;
              color: $primary-black;
              padding: 0 0 0 $size8;
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              text-align: right;
              box-sizing: border-box;
  
              p {
                margin: 0;
              }
  
              .card-description {
                margin: 0;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }

            .with-capitalize {
              text-transform: capitalize;
            }
          }

          .expander-wrapper {
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              width: 12px;
              height: 12px;

              path {
                fill : $primary-blue;
              }
            }
          }
        }

        .with-padding-bottom {
          padding-bottom: $size16;
        }
      }

      .payment-history-wrapper {
        gap: $size4;

        .wrapper-header {
          padding-bottom: 0;
        }
      }

      .cancel-subscription-modal {
        .modal {
          width: 90%;
          height: 500px;
        }
      }
    }
  }
}

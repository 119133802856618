@import "../../styles/shared";

.report-problem-wrapper {
  .header {
    width: 100%;
    border-bottom: 1px solid $medium-grey-blue;
    padding-bottom: 11.5px;
  }  

  .table {
    background-color: $white;
    padding: 40px 26px 26px 26px;

    .search-wrapper {
      max-width: 474px;
      width: 100%;

      .search {
        max-width: 317px;
        width: 100%;
        margin: 0;
        background-color: $grey-background;

        svg {
          display: none;
        }
      }

      .search-button {
        max-width: 144px;
        width: 100%;
        height: 40px;
        color: $white;
        border-radius: $element-border-radius;
        border: none;
        margin-left: 13px;
        font-size: 16px;
        font-weight: bold;
        background-color: $light-grey-button;

        &:hover {
          cursor: pointer;
        }
      }

      @media screen and (max-width: 768px) {
        .search-wrapper {
          max-width: unset;

          .search {
            max-width: unset;
          }
        }
      }
    }

    .table-content {
      margin-top: 31px;
      display: grid;
      grid-template-columns: minmax(90px, 0.5fr) minmax(120px, 0.8fr) minmax(
          175px,
          0.9fr
        ) minmax(120px, 0.8fr) minmax(160px, 0.9fr) minmax(115px, 0.6fr) minmax(
          210px,
          1fr
        ) minmax(160px, 0.9fr);
      line-height: 40px;
      text-align: center;
      overflow: scroll;
      overflow-y: hidden;
      border-right: 1px solid $table-column-line;

      .table-header {
        background-color: $table-header-background;
        color: $white;
        font-size: $size14;
        font-weight: bold;
        text-transform: uppercase;

        &:first-child {
          border-right: 1px solid $white;
        }

        &:nth-child(even) {
          border-right: 1px solid $white;
        }

        &:nth-child(odd) {
          border-right: 1px solid $white;
        }
      }

      .table-body {
        background-color: $white;
        border-bottom: 1px solid $scrollbar-grey;

        &:first-child {
          border-right: 1px solid $table-column-line;
        }

        &:nth-child(even) {
          border-right: 1px solid $table-column-line;
        }

        &:nth-child(odd) {
          border-right: 1px solid $table-column-line;
        }

        &.button {
          border: 1px solid $dark-grey-blue;
          cursor: pointer;
          border-radius: 50px;
          max-width: 82px;
          width: 100%;
          height: 25px;
          color: $dark-grey-blue;
        }
      }
    }

    .buttons-wrapper {
      @include display-flex;
      align-items: center;
      justify-content: center;
      margin-top: 2rem;

      .load-more-button {
        cursor: pointer;
        color: $dark-grey-blue;
        border: 1px solid $dark-grey-blue;
        background-color: transparent;
        border-radius: $element-border-radius;
        width: 240px;
        height: 40px;
        margin-bottom: 20px;

        &:focus {
          outline: none;
        }
      }
    }
  }

  .mobile-content {
    padding: 16px 20px 31px 15px;
    background-color: $white;
    font-size: $size14;
    box-shadow: 0px 1px 1px #0000002f;

    .search-wrapper {
      max-width: unset;

      .search {
        max-width: unset;
        background-color: $grey-background;
      }

      svg {
        display: none;
      }

      button {
        max-width: unset;
        background-color: $dark-grey-blue;
        height: 40px;
        border: none;
        border-radius: $element-border-radius;
        color: $white;
        font-size: $size14;
        font-weight: bold;
      }
    }

    .report-header {
      height: 62.5px;
      padding: 0 20px 0 15px;
      border-bottom: 1px solid $table-column-line;
      margin-top: 16px;
      background-color: $grey-background;

      button {
        border: 1px solid $dark-grey-blue;
        border-radius: 50px;
        max-width: 82px;
        width: 100%;
        height: 25px;
        background-color: inherit;
        color: $dark-grey-blue;
      }
    }

    .table-header {
      font-weight: bold;
    }

    .report-body {
      padding: 0 20px 32px 15px;
      background-color: $grey-background;
      display: grid;
      grid-template-columns: minmax(110px, 1fr) minmax(155px, 1fr);
      align-items: center;

      div {
        margin-top: 10px;
        padding: 0 5px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .search-wrapper {
      max-width: 343px;
      width: 100%;
      flex-direction: column;

      .search {
        max-width: 343px;
        width: 100%;
        margin: 0;
      }

      .search-button {
        max-width: 343px;
        width: 100%;
        margin: 10px 0 0 0;
      }
    }
  }
}

.report-form-wrapper {
  span {
    color: #fd6847;
  }

  .error {
    color: #fd6847;
  }

  .error-message,
  .success {
    text-align: center;
    font-weight: bold;
    max-width: 340px;
    width: 100%;
    border: none;
    margin: 5px 0 0 0;
    padding: unset;
  }

  .error-message {
    color: #fd6847;
  }

  .header {
    border-bottom: 1px solid $medium-grey-blue;
    padding-bottom: $size24;
    
    @media screen and (max-width: 768px) {
      padding-left: $size16;
    }
    
    .back-button-wrapper {
      display: flex;
      width: max-content;
      align-items: center;
      gap: $size16;
      font-size: $size24;
      font-weight: 700;
      color: $primary-black;
      margin-top: $size8;
      cursor: pointer;
  
      svg {
        g {
          rect {
            fill: $white !important;
          }
        }      
      }
    }
    
    p {
      white-space: nowrap;
    }
  }

  @media screen and (max-width: 757px) {
    .select-product-serial-number {
      padding-top: 27px;

      .click-outside-wrapper {
        box-shadow: 0px 1px 1px #00000029;
        max-width: unset;
        margin-bottom: 10px;
        font-size: $size14;
      }

      input {
        box-shadow: 0px 1px 1px #00000029;
        height: 40px;
        line-height: 40px;
        width: calc(100% - 15px);
        padding-left: 15px;
        border: none;
        font-size: $size14;
        margin-bottom: 10px;

        &:focus {
          outline: none;
        }
      }

      button {
        background-color: $dark-grey-blue;
        height: 40px;
        border-radius: $element-border-radius;
        color: $white;
        font-size: $size14;
        border: none;
      }

      .product-wrapper {
        margin-top: 21px;

        img {
          width: 63px;
          height: 58px;
          padding: 5px;
        }

        .details {
          margin-left: 14px;

          h2,
          p {
            margin: 0;
          }

          p {
            font-size: $size16;
          }
        }
      }

      .search-wrapper {
        max-width: unset;
        width: unset;
        padding: 0 10px;
        margin: 46px 0;

        p {
          font-weight: bold;
          font-size: $size16;
        }

        .click-outside-wrapper {
          max-width: 138px;
          margin: 0;
          height: 35px;

          .select-input {
            border-radius: $element-border-radius;
            background-color: $red;
            height: 35px;

            .height,
            .height div {
              font-size: $size16;
              color: $white;
              font-weight: bold;
              height: 35px;
            }

            svg {
              path {
                fill: $white;
              }
            }
          }
        }
      }
    }
  }

  .options-wrapper {
    max-width: inherit;
  }

  .options-wrapper > .option {
    border: none;
  }

  .form-body {
    .calendar-input-wrapper {
      .calendar-input {
        flex-direction: row-reverse;
      }
    }

    background-color: $white;
    padding-top: 26px;

    .search-wrapper {
      padding: 0 23px 0 23px;
      max-width: 236px;
      width: 100%;

      p {
        font-size: $size16;
        margin: 0;
        white-space: nowrap;
      }

      .click-outside-wrapper {
        margin-left: 12px;
      }
    }

    .general-information {
      box-shadow: 0px 1px 1px #00000029;
      background-color: $white;
      padding: 23px 23px 43px 23px;

      h2 {
        margin: 0;
      }

      input[type="text"] {
        max-width: 310px;
        width: 100%;
        border: none;
        height: 40px;
        background-color: $grey-background;
        padding-left: 20px;
        font-size: $size14;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-size: $size14;
        }
      }

      .select-product-serial-number {
        margin-top: 25px;

        .click-outside-wrapper {
          max-width: 330px;
          width: 100%;
          background-color: $grey-background;
          margin-right: 20px;

          .options-wrapper {
            max-width: inherit;
          }

          .options-wrapper > .option {
            border: none;
          }

          .height div {
            font-size: $size14;
          }
        }
      }

      .wrapper {
        width: 100%;
        margin-top: 32px;

        .complaint-type,
        .oobf,
        .rga-type {
          max-width: 330px;
          width: 100%;
          margin-right: 20px;

          h3 {
            margin: 0 0 10px 0;
          }

          .click-outside-wrapper {
            max-width: 330px;
            background-color: $grey-background;
          }

          input {
            border: none;
            height: 40px;
            font-size: $size16;
            background-color: $grey-background;
            padding-left: 20px;

            &:focus {
              outline: none;
            }
          }
        }
      }

      @media screen and (max-width: 757px) {
        h2 {
          font-size: 20px;
        }
        .wrapper {
          margin-top: 25px;

          .complaint-type,
          .oobf,
          .rga-type {
            max-width: unset;

            h3 {
              font-size: $size16;
              margin: 0;
            }

            .click-outside-wrapper {
              max-width: unset;
              margin: 10px 0 14px 0;
            }

            input[type="text"] {
              margin-top: 10px;
              max-width: unset;
              width: calc(100% - 20px);
            }
          }
        }
      }
    }

    .issue-reported-by {
      box-shadow: 0px 1px 1px #00000029;
      margin-top: 11px;
      background-color: $white;
      padding: 13px 23px 23px 23px;

      .reported-by-form-data {
        flex-wrap: wrap;

        .input {
          max-width: 330px;
          width: 100%;
          margin-bottom: 22px;

          .click-outside-wrapper {
            background-color: $grey-background;

            @media screen and (max-width: 757px) {
              max-width: unset;
              .options-wrapper {
                max-width: unset;
              }
            }
          }

          h3 {
            margin: 0;
            padding-bottom: 10px;
          }

          input {
            height: 40px;
            background-color: $grey-background;
            border: none;
            padding-left: 20px;
            margin-right: 20px;
            font-size: $size16;

            &:focus {
              outline: none;
            }
          }
          &.required {
            input {
              border: 1px solid #fd6847;
            }
          }
        }
      }

      @media screen and (max-width: 757px) {
        h2 {
          font-size: 20px;
        }

        .reported-by-form-data {
          .input {
            margin-bottom: 14px;
            max-width: unset;

            h3 {
              font-size: 14px;
            }

            input {
              margin-right: 0;
            }
          }
        }
      }
    }

    .complaint {
      font-size: $size18;
      margin: 14px 0 18px 0;

      @media screen and (max-width: 757px) {
        margin: 16px 0 26px 0;
      }
    }

    .event-information,
    .report-information {
      box-shadow: 0 1px 1px #00000029;
      margin-top: 11px;
      margin-bottom: 9px;
      background-color: $white;
      padding: 19px 23px 23px 23px;

      .technician,
      .doctor,
      .product {
        margin-bottom: 35px;

        h3 {
          margin: 13px 0 14px 0;
          color: $grey-text;
          padding-bottom: 12.5px;
          border-bottom: 1px solid rgba($grey-text, 0.4);
        }
      }

      .technician-inputs,
      .doctor-inputs,
      .product-inputs,
      .report-info-inputs {
        flex-wrap: wrap;

        .input {
          max-width: 330px;
          width: 100%;
          margin-right: 20px;

          p {
            margin: 0 58px 0 5px;
            font-size: $size16;
            line-height: 40px;
          }

          h3 {
            color: $black;
            border: none;
          }

          input {
            height: 40px;
            background-color: $grey-background;
            border: none;
            font-size: $size14;
            padding: 0 0 0 20px;

            &:focus {
              outline: none;
            }
          }

          input[type="radio"] {
            width: 18px;
            margin: 0;
            line-height: 40px;
          }

          .click-outside-wrapper {
            background-color: $grey-background;
          }

          &.area {
            max-width: 680px;
            width: 100%;
            margin-right: 20px;

            textarea {
              background-color: $grey-background;
              border: none;
              height: 157px;
              padding: 20px;
              resize: none; 
              
              &:focus {
                outline: none;
              }
            }
          }
          &.required {
            input,
            textarea,
            .calendar-wrapper .calendar-input,
            .radio-select {
              border: 1px solid #fd6847;
            }
            // input[type='radio'] {
            //   -webkit-appearance:none;
            //   height: 18px;
            //   padding: 0;
            //   margin: 0;
            //   line-height: 40px;
            //   border:1px solid #FD6847;
            //   border-radius:50%;
            //   outline:none;
            // }
          }
        }
      }

      @media screen and (max-width: 757px) {
        h2 {
          font-size: 20px;
        }

        .technician,
        .doctor,
        .product {
          margin: 0;

          .form-title {
            font-size: 18px;
            margin-top: 19px;
            margin-bottom: 26.5px;
          }
        }
        .technician-inputs,
        .doctor-inputs,
        .product-inputs,
        .report-info-inputs {
          .input {
            margin-right: 0;
            max-width: unset;

            h3 {
              font-size: $size14;
              margin: 0;
              padding: 0;
            }

            input,
            .click-outside-wrapper,
            .calendar-wrapper {
              margin: 10px 0 13px 0;
              max-width: unset;
              .options-wrapper {
                max-width: unset;
              }
              .calendar {
                z-index: 2;
              }
            }

            textarea {
              margin: 16px 0 26px 0;

              &:last-child {
                margin-bottom: 0;
              }
            }

            &.area {
              margin-right: 0;
            }
          }
        }
      }
    }

    @media screen and (max-width: 757px) {
      padding: 26px 11px 11px 11px;

      .search-wrapper {
        padding: 0 0 20px 0;
        max-width: unset;
        width: unset;

        p {
          font-weight: bold;
          margin-bottom: 16px;
        }

        .click-outside-wrapper {
          margin-left: 0;
          max-width: unset;
          box-shadow: none;

          .select-input {
            border-radius: $element-border-radius;
            border: 1px solid $scrollbar-grey;

            .height {
              div {
                color: black;
              }
            }
          }
          .options-wrapper {
            max-width: unset;
          }
        }
      }

      .general-information {
        .select-product-serial-number {
          margin: 16px 0 0 0;
          padding: 0;
          flex-direction: column;

          .click-outside-wrapper {
            margin-left: 0;
            margin-right: 0;
            max-width: unset;
            box-shadow: none;

            .select-input {
              background-color: $white;
              border-radius: $element-border-radius;
              border: 1px solid $scrollbar-grey;
            }
            .options-wrapper {
              max-width: unset;
            }
          }

          input {
            border-radius: $element-border-radius;
            border: 1px solid $scrollbar-grey;
            background-color: $white;
            max-width: unset;
            height: 40px;
            width: unset;
            padding: 0 0 0 20px;
            color: $black;
          }
        }
      }

      .general-information,
      .issue-reported-by,
      .event-information,
      .report-information {
        box-shadow: none;
        border-radius: $element-border-radius;
        border: 1px solid $scrollbar-grey;
        padding: 25px 17px 25px 14px;
      }
    }
  }
}

.submit {
  max-width: 340px;
  width: 100%;
  height: 40px;
  color: $white;
  font-weight: bold;
  text-align: center;
  line-height: 40px;
  border: none;
  border-radius: $element-border-radius;
  margin-top: 27px;
  font-size: 16px;
  background-color: $light-grey-button;

  &:hover {
    cursor: pointer;
  }
  &.unset {
    max-width: unset;
  }
}

.tabs-report {
  margin-left: 26px;
  margin-top: 36.5px;

  .report-type {
    max-width: 236px;
    width: 100%;
    margin-left: auto;

    p {
      white-space: nowrap;
      margin-right: 13px;
    }

    .click-outside-wrapper {
      max-width: 138px;
      width: 100%;

      .select-input {
        .height {
          div {
            color: $black;
            font-weight: bold;
          }
        }
      }
    }
  }

  .tab {
    max-width: 238px;
    width: 100%;
    height: 45px;
    font-weight: bold;
    font-size: 16px;
    border-radius: $element-border-radius;
    background-color: rgba($grey-text, 0.07);
    color: $dark-grey-blue;
    text-transform: capitalize;
    margin-right: 6px;
    cursor: pointer;

    &.active {
      background-color: $white;
      color: $black;
    }

    &:last-child {
      margin: 0;
    }
  }

  @media screen and (max-width: 757px) {
    margin-left: unset;
  }
}

.report-modal {
  .modal {
    top: calc((100vh + 69px) / 2);
    max-width: 980px;
    padding: 0;

    .close {
      padding: 15px 10px 0 0;
    }

    h1 {
      margin: 0;
      padding: 3px 23px 16px 41px;
      border-bottom: 1px solid $medium-grey-blue;
    }

    h3 {
      margin: 0;
      padding: 18px 23px 24px 41px;
      color: $grey-text;

      &.doctor,
      &.product-info {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .wrapper {
      display: grid;
      padding: 24px 23px 48px 41px;
      grid-row-gap: 27px;
      grid-template-columns: minmax(200px, 1fr) minmax(206px, 1fr) minmax(
          185px,
          0.9fr
        ) minmax(167px, 0.85fr) minmax(163px, 0.85fr);

      div {
        max-width: 163px;
      }

      h5,
      h4 {
        margin: 0;
      }

      h5 {
        font-size: $size14;
        font-weight: normal;
        max-width: 139px;
        color: $grey-text;
        margin-bottom: 6px;
      }

      &.technician,
      &.doctor,
      &.product-info {
        padding-top: 0;
        padding-bottom: 26px;

        h5 {
          height: 40px;
          align-items: flex-end;
          display: flex;
          overflow-wrap: anywhere;
        }
      }
    }

    @media screen and (max-width: 757px) {
      h1 {
        font-size: $size22;
      }
      h3 {
        font-size: $size16;
      }

      .wrapper {
        grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);

        h4 {
          font-size: $size16;
        }

        &.area {
          grid-template-columns: minmax(100%, 1fr);
          padding-bottom: 0;
          padding-top: 0;
        }

        &.area:first-of-type {
          margin-top: 13px;
          margin-bottom: 30px;
        }

        &.area:last-of-type {
          margin-top: 30px;
          margin-bottom: 48px;
        }
      }
    }
  }
}

@import "../../styles/shared";

.products-modern-videos-wrapper {
  padding: $size24 $size16;

  .videos-header {
    justify-content: space-between;

    .category-wrapper {
      align-items: center;
      width: 50%;

      .category-title {
        font-size: $size14;
        font-weight: 500;
        color: $primary-black;
        margin-right: $size24;
      }

      .click-outside-wrapper {
        border: 1px solid $medium-grey-blue; 
        box-shadow: none; 
        max-width: 290px;
        box-sizing: border-box;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }

          svg {
            width: 12px;
            height: 12px;

            path {
              fill: $primary-black;
            }
          }

          .text-wrapper {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .reset-filter-button {
            background: none;
            border: none;
            width: 28px !important;
            height: 28px !important;
            cursor: pointer;
            margin-right: $size16;

            svg {
              width: 28px !important;
              height: 28px !important;

              circle {
                fill: $grey-background;
                stroke: none;
              }

              g {
                line {
                  stroke: $primary-blue;
                }
              }
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: overlay;

          .option {
            box-sizing: border-box;
            padding: $size4 $size16 $size4 $size16;
            text-indent: 0;
            min-height: $size36;
            height: max-content;
            line-height: unset;
            display: flex;
            align-items: center;
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }
    }
  }

  .videos-container {
    flex-direction: column;
    align-items: center;

    .videos-content {
      margin-top: $size24;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: $size24;

      .video-item-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        cursor: pointer;

        .thumbnail-wrapper {
          position: relative;
          display: flex;
          width: 100%;
          height: 160px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          border-radius: $element-border-radius;
          overflow: hidden;

          .thumbnail-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);

            svg {
              width: $size32;
              height: $size32;
            }
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .title {
          margin: $size16 0 0 0;
          font-weight: 700;
          text-align: left;
          font-size: $size16;
          word-wrap: break-word;
        }
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
    }
  }

  .empty-list {
    min-height: 250px;
    
    .title {
      font-size: $size18;
    }
  }

  @media screen and (max-width: 425px) {
    .videos-container {
      .videos-content {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }

  @media screen and (min-width: 426px) and (max-width: 768px) {
    .videos-container {
      .videos-content {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media screen and (max-width: 768px) {
    .videos-header {
      flex-direction: column;
      gap: $size16;

      .category-wrapper {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: $size4;

        .click-outside-wrapper {
          max-width: 100%;
        }
      }

      .simple-button {
        width: 100%;
      }
    }
  }
}

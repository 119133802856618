@import '../../../styles/shared';

.select-menu {
  height: 36px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #E5E7F0;
  border-radius: 4px;
  position: relative;
  opacity: 1;
  width: 100%;
  box-sizing: border-box;

  .selected-option {
    display: flex;
    justify-content: space-between;
    padding-left: 16px;
    border-radius: 4px;
  }

  button {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 34px;
    outline: none;
    box-sizing: border-box;
    background-color: $white;
    width: 100%;
    padding: 0 16px;
    cursor: pointer;
  }

  .caret-dropdown-icon {
    path {
      fill: black;
    }
  }

  .options {
    border: 1px solid #e5e7f0;
    box-shadow: 0px 1px 6px #00000024;
    margin-top: 4px;
    width: 100%;
    z-index: 9999 !important;
    max-height: 160px;
    position: relative;
    overflow: overlay;
  }
  
  .option:hover {
    background-color: #f5f6fa;
  }
}
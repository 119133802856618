@import "../../styles/shared";

.policies-modal {
  .page-modal {
    min-width: unset;

    .page-header {
      .status-wrapper {
        display: flex;
        align-items: center;

        .input-option {
          margin: 0 $size16 0 0;
          font-size: $size14;
          font-weight: 500;
          color: $primary-black;
        }

        .status-option {
          display: flex;
          align-items: center;

          .text-status-option {
            margin: 0 0 0 $size4;
            font-size: $size12;
            color: $primary-black;
          }
        }
      }
    }

    .modal-buttons-footer {
      gap: 8px;
    }

    .policy-modal-content {
      width: 100%;
      box-sizing: border-box;

      .input-option {
        color: $primary-black;
        font-size: $size14;
        font-weight: 500;
      }

      .text-item-view {
        margin-bottom: 15px;

        &.description-text {
          margin-bottom: 40px;
        }
      }

      .name-input {
        width: 100%;
        height: $size36;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        font-size: $size14;
        text-indent: 15px;
        outline: none;
        box-sizing: border-box;
      }

      .name-input:disabled {
        color: $disabled-grey;
        background-color: $white;
      }

      .description-input {
        width: 100%;
        height: 100px;
        margin-bottom: 20px;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        font-size: $size14;
        padding-left: 15px;
        padding-top: 10px;
        padding-bottom: 10px;
        line-height: 20px;
        box-sizing: border-box;
        outline: none;
        font-family: "Roboto";
      }

      .permissions-wrapper {
        display: flex;
        margin-top: 10px;
        max-width: 592px;
        
        .columns-wrapper {
          column-count: 3;
          max-width: 592px;

          .permission-wrapper {
            align-items: center;
            padding-bottom: 7px;
            padding-top: 3px;
            color: #08232f;
  
            &.view-mode {
              color: #758091;
  
              &.selected-permission {
                color: #08232f;
              }
            }
  
            .checkbox-wrapper {
              display: flex;
            }
  
            label {
              padding-left: 12px;
            }
          }
        }
      }

      .edit-option-tooltip {
        font-size: $size12;
        color: $grey-text;
        margin: 5px 0 15px 0;
      }

      .dropdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .type-wrapper,
        .policy-wrapper {
          width: 50%;

          &.policy-type {
            margin-left: $size24;
          }

          .input-option {
            color: $primary-black;
            font-size: $size14;
            font-weight: 500;
          }

          .click-outside-wrapper {
            margin: 0 0 0 0;
            border: 1px solid $medium-grey-blue;
            border-radius: $element-border-radius;
            box-shadow: none;
            height: $size36;
            cursor: pointer;

            .height {
              div {
                color: $primary-black;
                font-size: $size14;
              }

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill: $primary-black;
                }
              }

              .icon-dropdown-open {
                transform: rotate(180deg);
              }
            }

            .options-wrapper {
              margin-top: 4px;
              border-radius: $element-border-radius;
              border: 1px solid $medium-grey-blue;
              max-height: 160px;
              position: relative;
              overflow: overlay;

              .selected-option {
                color: $primary-black;
                font-weight: 600;
              }
            }
          }

          .has-error {
            border: 1px solid $required-red;
          }
        }

        .email-template-preview-button {
          width: 50%;
          display: flex;
          cursor: pointer;

          .template-preview-tooltip {
            color: $primary-blue;
            font-size: $size14;
            margin-top: 50px;
          }

          .disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .template-preview-modal-content {
      margin: 30px 15px 15px 15px;
      height: 600px;
      display: flex;
      flex-direction: column;

      .template-preview-content {
        width: max-content;
        height: 100%;
        overflow: auto;
        margin-bottom: 25px;
        box-sizing: border-box;
        align-self: center;

        .content {
          min-height: unset !important;
        }
      }

      .template-preview-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: auto;

        .cancel-button {
          padding: 15px;
          display: flex;
          justify-content: center;
          height: 40px;
          border-radius: $element-border-radius;
          background-color: $scrollbar-grey;
          color: $primary-black;
          font-weight: 500;
          border: none;
          cursor: pointer;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .policies-modal {
    position: fixed;

    .page-modal {
      .page-header {
        .status-wrapper {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .policy-modal-content {
        .input-option {
          margin-bottom: 0.7rem;
        }

        .description-input {
          width: 100%;
        }

        .dropdown-wrapper {
          display: block;
          width: 100%;

          .type-wrapper,
          .policy-wrapper {
            width: 100%;

            .click-outside-wrapper {
              max-width: 100%;
            }
          }

          .policy-wrapper {
            &.policy-type {
              margin-left: 0;
              margin-top: $size24;
              margin-bottom: $size24;
            }
          }
        }

        .permissions-wrapper {
          .columns-wrapper {
            column-count: 2;
          }
        }
      }
    }
  }
}

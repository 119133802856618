@import "../../styles/shared";

.register-product-modern-wrapper {
  .page-modal-wrapper {
    
    .page-modal {

      .page-content {
        padding: 0;

        .register-product-modal-content {
          width: 100%;
          box-sizing: border-box;

          .section-title {
            font-size: $size14;
            font-weight: 500;
            padding: $size16 $size24 $size24 $size24;
            color: $dark-grey-blue;
          }

          .section-disclaimer {
            display: flex;
            width: calc(100% - 48px);
            margin: 0 $size24;
            background-color: $transparent-blue;
            border-radius: $element-border-radius;

            p {
              font-size: $size14;
              margin: $size16;
              color: $primary-black;
              max-width: 640px;
            }
          }

          .section-wrapper {
            display: flex;
            gap: $size16;
            width: 100%;
            padding: 0 $size24;
            box-sizing: border-box;

            .input-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size8;
              width: 50%;
              box-sizing: border-box;

              .input-label {
                font-size: $size14;
                font-weight: 500;
                color: $primary-black;
              }

              .input-item {
                width: 100%;
                height: $size36;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                text-indent: $size16;
                outline: none;
                box-sizing: border-box;
              }

              .click-outside-wrapper {
                margin: 0;
                border: 1px solid $medium-grey-blue; 
                box-shadow: none;
                max-width: 100%;
                box-sizing: border-box;

                .height {
                  div {
                    color: $primary-black;
                    font-size: $size14;
                  }         

                  svg {
                    width: 12px;
                    height: 12px;
                    path {
                      fill : $primary-black;
                    }
                  }

                  .icon-dropdown-open {
                    transform: rotate(180deg);
                  }
                }

                .options-wrapper {
                  margin-top: 4px;
                  border: 1px solid $medium-grey-blue;
                  max-height: 160px;
                  position: relative;
                  overflow: overlay;

                  .selected-option {
                    color: $primary-black;
                    font-weight: 500;
                  }
                }
              }

              .has-error {
                border: 1px solid $required-red;
              }

              .loading-start {
                opacity: 0.5;
                pointer-events: none;
              }
            }
          }

          .with-margin-top {
            margin-top: $size16;
          }
          
          .with-margin-bottom {
            margin-bottom: $size16;
          }

          .separator {
            width: 100%;
            height: 1px;
            background-color: $medium-grey-blue;
            margin: $size24 0 $size8 0;
          }
        }
      }      
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .page-modal-wrapper {

      .page-modal {

        .page-content {

          .register-product-modal-content {
            font-size: $size14;

            .section-wrapper {
              flex-direction: column;
              
              .input-wrapper {
                width: 100%;
              }
            }
          }
        }        
      }
    }
  }
}

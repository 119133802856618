@import "../../styles/shared.scss";

.missing-item-modal {

  .page-modal {

    .page-content {

      .missing-item-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size16;

        .row-wrapper {
          width: 100%;
          display: flex;
          gap: $size16;

          .section-title {
            font-size: $size14;
            font-weight: 500;
            color: $dark-grey-blue;
            text-transform: uppercase;
          }

          .input-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: $size8;
            position: relative;
    
            .modal-section-disclaimer {
              padding: $size8 $size16;
              border-radius: $element-border-radius;
              background-color: $transparent-blue;
            }

            .input-option {
              margin: 0;
              font-weight: 500;
            }
            
            .input-item {
              width: 100%;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }

            .textarea-item {
              width: 100%;
              height: 100px;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              padding: $size16;
              line-height: $size24;
              box-sizing: border-box;
              outline: none;
              font-family: 'Roboto';
              font-size: $size14;
            }
          }
        }

        .separator {
          width: 100%;
          height: 1px;
          background-color: $medium-grey-blue;
        }

        .add-btn {
          border: 0px;
          text-align: left;
          color: $primary-blue;
          background: transparent;
          cursor: pointer;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {

      .missing-item-modal-content {
        font-size: $size14;
        gap: $size16;

        .row-wrapper {

          .input-wrapper {
            flex-direction: column;
            gap: $size16;
            width: 100%;
            box-sizing: border-box;
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 485px) {
    .page-modal {

      .page-content {

        .missing-item-modal-content {
          gap: $size16;

          .row-wrapper {
            flex-direction: column;
            gap: unset;
          }
        }
      }
    }
  }
}

.hide-footer {

  .page-footer {
    display: none !important;
  }
}

@import '../../../styles/shared';

.modal-wrapper {
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .modal {
    max-width: 935px;
    width: 100%;
    background-color: $white;
    z-index: 1;
    position: relative;
    top: calc((100vh + 69px) / 2);
    transform: translateY(-50%);
    padding: 15px 10px;
    border-radius: $element-border-radius;
    max-height: 80%;
    overflow: auto;
    margin: auto;

    @media screen and (max-width: 1050px) {
      margin: 10px 10px 10px 70px;
      width: auto;
      max-width: unset;
    }

    @media screen and (max-width: 1015px) {
      margin: 10px auto;
      width: auto;
      max-width: unset;
    }

    @media screen and (max-width: 425px) {
      max-width: unset;
      width:auto;
    }

    .close {
      text-align: right;

      svg {
        cursor: pointer;
      }
    }
  }

  .overflow {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-black;
    opacity: 0.32;
  }

  .btn {
    margin: 0;
    height: 40px;
    width: 170px;
    font-size: 16px;
    padding: 12px;
  }

  .separator {
    border-top: 1px solid #00000029;
    margin: 10px -66px 16px;
  }
}

@media all and (max-width: 480px) {
  .modal-wrapper {
    .modal {
      width: auto;
      top: calc((100vh + 69px) / 2);

      .separator {
        margin: 10px -10px 16px;
      }
    }

  }
}

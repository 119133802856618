@import "../../styles/shared.scss";

.add-edit-company-modal {

  .page-modal {
    height: max-content !important;

    .page-header {

      .status-wrapper {
        display: flex;
        align-items: center;

        .label {
          margin: 0 $size16 0 0;
          font-size: $size14;
          font-weight: 500;
          color: $primary-black;
        }

        .status {
          margin: 0 0 0 $size4;
        }         
      }        
    }

    .page-content {
      overflow: unset;
    
      .add-edit-company-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size16;

        .row-wrapper {
          width: 100%;
          display: flex;
          gap: $size16;

          .input-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: $size8;
    
            .input-option {
              margin: 0;
              font-weight: 500;
            }
            
            .name-input {
              width: 100%;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }

            .click-outside-wrapper {
              margin: 4px 0 0 0;
              border: 1px solid $medium-grey-blue; 
              box-shadow: none;
              max-width: 100%;
              box-sizing: border-box;

              .height {
                div {
                  color: $primary-black;
                  font-size: $size14;
                }         

                svg {
                  width: 12px;
                  height: 12px;
                  path {
                    fill : $primary-black;
                  }
                }

                .icon-dropdown-open {
                  transform: rotate(180deg);
                }
              }

              .options-wrapper {
                margin-top: 4px;
                border: 1px solid $medium-grey-blue;
                max-height: 160px;
                position: relative;
                overflow: overlay;

                .selected-option {
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }

            .has-error {
              border: 1px solid $required-red;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;

      .page-header {

        .page-header-content {
          margin-right: $size16;

          .status-wrapper {
            width: unset;

            .label {
              display: none;
            }
          }
        }
      }

      .page-content {
        overflow: scroll;
      
        .add-edit-company-modal-content {
          font-size: $size14 !important;

          .row-wrapper {
            flex-direction: column;

            .input-wrapper {
              flex-direction: column;
              width: 100%;
              box-sizing: border-box;
            }

            .google-address-input-wrapper {
              gap: $size16 !important;

              .input-wrapper-google-address {
                margin-bottom: 0px;
              }
            }

            .empty {
              display: none;
            }
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}

.invalidate-confirmation-modal {

  .confirmation-modal-message {
    margin-bottom: $size8;
  }

  .invalidate-confirmation-wrapper {
    padding-left: 56px;

    .disclaimer {
      padding: $size8 $size16;
      border-radius: $element-border-radius;
      background-color: $transparent-blue;
    }
  }

  .confirmation-modal-buttons {
    margin-top: $size32;
  }
}

@media screen and (max-width: 768px) {
  .invalidate-confirmation-modal {
    font-size: $size14;

    .invalidate-confirmation-wrapper {
      padding-left: 0;

      .disclaimer {
        p {
          margin: 0;
        }
      }
    }

    .confirmation-modal-buttons {
      flex-direction: column;

      button {
        height: $size36;
      }
    }
  }
}

@import "../../styles/shared";

.troubleshooting-wrapper {
  padding: $size16;

  .cycle-select-title {
    font-size: $size14;
    margin:0 0 $size8 0;
    font-weight: 500;
    color: $primary-black;
  }

  .click-outside-wrapper {
    margin: 0 0 $size40 0;
    border: 1px solid $medium-grey-blue;
    box-shadow: none;
    max-width: 288px;
    cursor: pointer;

    .height {
      div {
        color: $primary-black;
        font-size: $size14;
      }         

      svg {
        width: 12px;
        height: 12px;
        path {
          fill : $primary-black;
        }
      }

      .icon-dropdown-open {
        transform: rotate(180deg);
      }
    }

    .options-wrapper {
      margin-top: 4px;
      border: 1px solid $medium-grey-blue;
      max-height: 390px;
      position: relative;
      overflow: overlay;

      .options-filter {
        width: 100%;
        height: 100%;
        background-color: $white;
        z-index: 1;

        .search {
          background-color: $light-grey-blue;
          border-radius: $element-border-radius;
          margin: $size16 $size16 $size4 $size16 !important;
          box-shadow: none !important;
          z-index: 1;

          .search-icon {
            display: none;
          }
        }
      }

      .selected-option {
        color: $primary-black;
        font-weight: 500;
      }
    }
  }

  .cycle-details-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 0 $size24 0;
    gap: $size32;
    box-sizing: border-box;

    .cycle-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size8;

      .cycle-title {
        display: flex;
        font-size: $size24;
        font-weight: 700;
        gap: $size4;

        .cycle-title-number {
          color: $primary-blue
        }
      }

      .cycle-description {
        font-size: $size14;
        color: $primary-black;
      }
    }      

    .cycle-category {
      .cycle-category-title {
        font-size: $size14;
        font-weight: 700;
        margin: 0 0 $size8 0;
      }
      
      .cycle-categories {
        margin: 0px;
      }
      
      .list-none {
        list-style: none;
        margin: 0px;
        padding: 0px;
      }
      
      .cycle-category-issue-list {
        margin: 0 0 0 $size16;
        padding: 0;
        list-style: none;

        .cycle-category-issue {
          font-size: $size14; 
          margin-bottom: $size4;
          color: $primary-black;
        }

        li::before {
          content: "\2022";
          color: $primary-blue;
          font-weight: bold;
          font-size: $size16;
          display: inline-block;
          width: 12px;
          margin-left: -16px;
        }
      }          
    }
  }

  @media screen and (max-width: 768px) {
    .click-outside-wrapper {
      max-width: 100%;
    }
  }
}

@import "../../../styles/shared";

.my-account-wrapper {
  .notifications-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 790px;
    margin-top: $size16;
    box-sizing: border-box;
    gap: $size16;

    .section-wrapper {
      display: flex;
      flex-direction: column;
      gap: $size16;
      width: 100%;
      padding: 0 $size24 $size16 $size24;
      border-radius: $element-border-radius;
      background-color: $white;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
      box-sizing: border-box;

      .wrapper-title {
        font-size: $size14;
        font-weight: 500;
        color: $dark-grey-blue;
        padding: $size24 0 $size8 0;
        margin: 0;
        text-transform: uppercase;
      }
      
      .pt-0 {
        padding-top: 0px;
      }

      .section {
        display: flex;
        width: 100%;
        box-sizing: border-box;

        .section-title {
          display: flex;
          flex-direction: column;
          gap: $size8;
          margin: 0;
          font-weight: 700;
          width: 25%;
          box-sizing: border-box;

          .disclaimer {
            font-weight: 400;
            width: 70%;
            color: $dark-grey-blue;
          }
        }

        .section-content {
          width: 75%;
          margin-right: $size24;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;
  
          .inline-section {
            display: flex;
            gap: 65px;
  
            .toggle-option {
              display: flex;
              gap: $size8;
            }
  
            .details {
              margin: 0;
              min-width: 54px;
            }
  
            .selected {
              .checkbox-text {
                color: $primary-black !important;
              }              
            }
  
            input[type="checkbox"] {
              cursor: pointer;
            }

            .checkbox-wrapper {
              .checkbox-text {
                color: $dark-grey-blue;
              }
            }
          }
  
          .tooltip {
            margin: $size8 0 0 0;
            color: $grey-text;
          }
  
          .grid-section {
            display: inline-grid;
            grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
            grid-gap: $size16;
            margin-bottom: $size24;
  
            .card {
              flex-grow: 1;
              height: 110px;
              width: 290px;
              background-color: $grey-background;
              border-radius: $element-border-radius;
  
              .image-wrapper {
                margin: $size16 $size24 0 $size24;
                height: $size36;
                width: $size36;
  
                img {
                  width: $size36;
                }
              }
  
              .product-wrapper {
                width: 100%;
                margin: $size16 0 $size16 0;
  
                .product-title {
                  margin: 0 0 $size8 0;
                  font-size: $size16;
                  font-weight: 600;
                }
  
                .product-number {
                  margin: 0 0 $size8 0;
                  color: $dark-grey-blue;
                }
  
                button {
                  width: 63px;
                  height: 30px;
                  min-width: fit-content;
                  margin-top: auto;
                  background: $white;
                  border: 1px solid $medium-grey-blue;
                  border-radius: $element-border-radius;
                  color: $primary-black;
                  cursor: pointer;
                }
  
                button:hover {
                  background: $primary-blue;
                  color: $white;
                }
              }
            }
          }
        }
      }
      
      .separator {
        background-color: $medium-grey-blue;
        width: 100%;
        height: 1px;
        margin: $size16 0;
      }

      .buttons {
        display: flex;
        gap: $size8;
        margin-top: $size24;
        width: 100%;
        box-sizing: border-box;
        justify-content: flex-end;
      }
    }

    .align-center {
      align-items: center;
    }

    .spinner-wrapper-parent {
      position: fixed;
      z-index: 999 !important;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;

      .loading-spinner {
        position: relative;
        top: 25%;
        left: 50%;
        transform: translateY(-50%);
      }
    }
  }

  @media all and (max-width: 768px) {
    .notifications-wrapper {

      .section-wrapper {
        flex-direction: column;
        box-sizing: border-box;
        padding: 0 $size16 $size16 $size16;
        border-radius: 0;

        .section {
          flex-direction: column;

          .section-title {
            width: 100%;
            font-size: $size14;
          }

          .section-content {
            width: 100%;
            justify-content: center;
            margin: $size16 0;            
            box-sizing: border-box;
  
            .inline-section {
              align-items: center;
  
              .left-big-margin {
                margin-left: $size8;
              }
  
              .right-big-margin {
                margin-right: $size8;
              }
  
              .disabled {
                opacity: 0.8;
              }
  
              input[type="checkbox"]:not(:disabled) {
                cursor: pointer;
              }
            }

            .grid-section {
              gap: $size8;
            }
  
            .tooltip {
              margin: $size8 0 0 0;
            }
          }
        }

        .buttons {
          .simple-button {
            width: 100%;
          }
        }

        .separator {
          width: 100%;
          margin: $size8 0;
        }        
      }
    }
  }
  
  .capitalize {
    text-transform: capitalize;
  }
}
@import "../../styles/shared";

.configuration-wrapper {
  padding: $size24 $size16;

  .configuration-expand-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;
    background-color: $light-grey-blue;

    .configuration-expand-header {
      display: flex;
      margin: $size16;
      align-items: center;
      cursor: pointer;

      .title {
        margin: 0;
        font-size: $size14;
        font-weight: bold;
        text-align: left;
      }

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        margin-left: auto;
        border-radius: 12px;
      }
    }
  }

  .wrapper-unactive {
    background-color: $white;
    border-bottom: 1px solid $scrollbar-grey;
  }

  .list-wrapper {
    padding: 0 $size16 $size14 $size16;

    .list-info {
      width: 50%;

      .section-title {
        margin: $size32 0 $size16 0;
        color: $dark-grey-blue;
        font-size: $size14;
        text-align: left;
        font-weight: 500;
      }

      .empty-section {
        padding-bottom: $size16;
      }

      p {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
      }

      span {
        text-align: left;
        font-size: $size14;
        color: $dark-grey-blue;
      }

      .label {
        margin: 0;
        width: 35%;
        color: $primary-black;
        font-weight: bold;
        line-height: $size18;
      }

      .label-data {
        width: 65%;
        margin: 0 0 0 $size8;
        word-wrap: break-word;
        line-height: $size18;
      }

      .break-email-address {
        word-break: break-all;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: $size16 0;

    .configuration-expand-wrapper {
      .list-wrapper {
        flex-direction: column;
        gap: $size32;

        .list-info {
          width: 100%;

          .section-title {
            margin: $size8 0 $size16 0;
          }

          p {
            .label-data {
              text-align: right;
            }
          }
        }
      }
    }
  }
}

@import "../../../styles/shared";

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: $size8;

  .checkbox-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $size16;
    height: $size16;
    border: 1px solid $dark-grey-blue;
    border-radius: $size4;
    cursor: pointer;
    flex-shrink: 0;

    svg {
      position: absolute;
      visibility: hidden;
    }
  }

  .active-checkbox-switch {
    background-color: $primary-blue;
    border: 1px solid $primary-blue;

    svg {
      fill: $white;
      visibility: visible;
    }
  }

  .disabled-checkbox-switch {
    opacity: 0.7;
    pointer-events: none;
  }

  .checkbox-text {
    font-size: $size14;
    color: $primary-black;
    margin: 0;
  }

  .disabled-checkbox-text {
    opacity: 0.7;
  }
}
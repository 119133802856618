@import "../../styles/shared";

.products-modern-wrapper {
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }
  
  .legend-wrapper {
    margin: $size16 0 0 0;

    .svg-wrapper {
      > div {
        display: flex;
        gap: $size32;

        .legend {
          display: flex;
          align-items: center;
          gap: $size4;

          .dashboard-span {
            height: $size16;
          }
        }
      }
    }
  }

  .with-margin-top {
    margin-top: $size16;
  }

  .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-top: $size16;
    min-height: 790px;
    border-radius: $element-border-radius;
    background-color: $white;
    box-shadow: 0px 0px 25px #0000000D;
    box-sizing: border-box;

    .product-header-wrapper {
      display: flex;
      width: 100%;
      padding: $size16 $size16 $size32 $size16;
      box-sizing: border-box;

      .product-wrapper {
        display: flex;
        width: 100%;
        box-sizing: border-box;

        .image-wrapper {
          justify-content: center;
          height: 160px;
          width: 160px;
          background-color: $light-grey-blue;
          flex-shrink: 0;

          img {
            height: 115px;
            width: 115px;
            object-fit: contain;
          }
        }

        .product-data-wrapper {
          display: flex;
          flex-direction: column;
          margin-left: $size24;
          width: 100%;

          .product-identification-wrapper {
            display: flex;
            flex-direction: column;

            .product-model {
              font-size: $size24;
              font-weight: 700;
              color: $primary-black;
              margin: 0;
            }

            .product-serial {
              font-size: $size14;
              color: $primary-blue;
              margin: $size4 0 0 0;
            }

            .product-nickname {
              font-size: $size14;
              color: $primary-black;
              opacity: 0.5;
              margin: $size16 0 0 0;
            }

            .product-super-password-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size4;
              margin-top: $size16;

              .section-title {
                font-size: $size14;
                color: $primary-black;
                opacity: 0.5;
                margin: 0;
              }

              .super-password {
                font-size: $size16;
                color: $primary-blue;
                margin: 0;
                text-transform: lowercase;
                gap: $size4;

                .tooltip {
                  font-size: $size12;
                  color: $dark-grey-blue;
                  margin: 0;
                }
              }
            } 
            
            .last-ping-date {
              margin-top: $size16;
              .section-title {
                color: #08232f;
                opacity: .5;
              }
              span {
                color: $primary-blue;
              }
            }
          }

          .product-status {
            display: flex;
            gap: $size8;
            margin: $size16 0 0 0 !important;

            .tooltip {
              bottom: 130%;
            }

            .unavailable {
              opacity: 0.2;

              path {
                fill: $primary-black;
              }
            }
          }
        }
      }

      .navigation-buttons-wrapper {
        display: flex;
        gap: $size8;
        margin-left: auto;
      }
    }

    .wrapped-tabs-wrapper {
      .tabs-container {
        padding: 0 $size16;

        .tab {
          max-width: 160px;
        }
      }
    }

    .calendar-separator {
      display: flex;
      height: 1px;
      width: calc(100% - 32px);
      background-color: $medium-grey-blue;
      margin: 0 $size16;
      box-sizing: border-box;
    }
  }

  .hidden-content {
    display: none;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .legend-wrapper {
      padding: 0 $size16;

      .svg-wrapper {
        > div {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: $size24;
        } 
      }
    }
    
    .content {
      border-radius: 0;

      .product-header-wrapper {
        display: flex;
        flex-direction: column;
        gap: $size24;
  
        .product-wrapper {
  
          .image-wrapper {
            display: flex;
            align-items: center;
            height: 80px !important;
            width: 80px !important;
  
            img {
              height: 61px !important;
              width: 61px !important;
            }
          }
  
          .product-data-wrapper {
            flex-direction: column;
            margin-left: $size16 !important;
  
            .product-identification-wrapper {
              width: 100%;
  
              .product-super-password-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size4;
                margin: $size16 0 0 0 !important;
  
                .section-title {
                  font-size: $size14;
                  color: $primary-black;
                  opacity: 0.5;
                  margin: 0;
                }
  
                .super-password {
                  font-size: $size16;
                  color: $primary-blue;
                  margin: 0;
                  text-transform: lowercase;
  
                  .tooltip {
                    font-size: $size12;
                    color: $dark-grey-blue;
                    margin: 0;
                  }
                }
              }    
              
              .last-ping-date {
                margin-top: $size16;
                .section-title {
                  color: #08232f;
                  opacity: .5;
                }
                span {
                  color: $primary-blue;
                }
              }
            }
  
            .product-status {
              margin: $size16 0 0 0 !important;
            }
          }
        }
  
        .navigation-buttons-wrapper {
          display: flex;
          width: 100%;
          gap: $size8;
          box-sizing: border-box;
  
          .action-button {
            width: 100%;
          }
        }
      }
    }
  }
}

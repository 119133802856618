@import "../../../styles/shared";

$legend-text-size: 11px;

.digital-twin-container {
  display: block;
  height: 100%;
  min-height: 790px;
  margin-top: $size16;
  border-radius: $element-border-radius;
  background-color: $white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
  padding: $size16 $size24;
  overflow-x: scroll;

  .search-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: $size8;
    box-sizing: border-box;

    .search-with-filter-wrapper {
      .filter-button-wrapper {
        display: none;
      }

      .search-wrapper {
        .search {
          background: $light-grey-blue;
        }
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: $size16;

    .back-icon {
      width: $size32;
      height: $size32;

      &:hover {
        cursor: pointer;
      }
    }

    .back-title {
      margin-left: $size14;
      font-size: $size24;
      color: $primary-black;
      text-align: left;
      font-weight: bold;
    }
  }
  
  .api-loading-spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 6em;
    height: 6em;
    border: 0.5rem solid rgba(117, 128, 145, 0.3);
    border-left: 0.5rem solid $primary-black;
    border-radius: 50%;
    animation: spinning 1.1s infinite linear;
    z-index: 9;

    @keyframes spinning {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .chart-wrapper {
    background-color: $light-grey;
    width: 98%;
    min-width: 1770px;
    padding: 10px;
    margin-bottom: 5px;
    position: relative;

    .chart-title {
      font-weight: bold;
      font-size: 16px;
      margin: 0 0 20px 650px;
    }
    .chart-title_temperature_pressure {
        font-weight: bold;
        font-size: 16px;
        margin: 0 0 20px 550px;
    }

    .expand {
      @include display-flex;
      justify-content: flex-end;
    }
  }

  .digital-twin-buttons {
    margin: 10px 0 10px 440px;
    min-width: 600px;

    .button {
      font-size: 14px;
      font-weight: bold;
      width: 120px;
      margin: 0 30px;
    }
    .message {
      margin-left: 15%;
      margin-top: $size8;
    }
  }

  .diagram-container {
    @include display-flex;

    .diagram-container-left {
      min-width: 200px;

      .sterilizer-diagram-legend {
        width: 200px;
        border: 1px solid #D2D7E0;
        margin: 10px 0;
        background-color: $light-grey;
        text-align: center;
    
        .sterilizer-diagram-legend-header {
          font-size: 14px;
          font-weight: bold;
          padding: 10px 0 0 0;
        }
    
        .sterilizer-diagram-legend-title {
          font-size: $legend-text-size;
          font-weight: bold;
        }
    
        .sterilizer-diagram-legend-divider {
          height: 5px;
          margin: 5px 0;
          background-color: $light-grey;
        }
    
        .sterilizer-diagram-legend-divider-pink {
          height: 5px;
          margin: 7px 0;
          background-color: $pink;
        }
    
        .sterilizer-diagram-legend-divider-blue {
          height: 5px;
          margin: 7px 0;
          background-color: $light-grey-button;
        }
    
        .sterilizer-diagram-legend-divider-green {
          height: 5px;
          margin: 7px 0;
          background-color: $green;
        }
    
        .sterilizer-diagram-legend-divider-yellow {
          height: 5px;
          margin: 7px 0;
          background-color: $yellow;
        }
    
        .sterilizer-diagram-legend-text {
          font-size: $legend-text-size;
          line-height: 1.5em;
        }
      }
    }

    .diagram-container-right {
      padding: 15px 0;
      min-width: 1600px;
    }
  }

  .tabular-view {
    @include display-flex;

    .tabular-view-section-header {
      text-align: center;
      font-weight: bold;
      line-height: 30px;
      font-size: 12px;
    }

    .section-container-line-top-center {
      font-weight: bold;
      margin-top: 4px;
      font-size: 10px;
    }

    .section-container-line-top-left {
      font-weight: bold;
      margin: 4px 0 0 10px;
      font-size: 10px;
      text-align: left;
    }

    .section-container-line-top-left-normal {
      margin: 0 0 0 10px;
      font-size: 10px;
      text-align: left;
    }

    .section-container-line-top-left-normal-small {
      margin: 0 0 0 5px;
      font-size: 9px;
      text-align: left;
    }

    .tabular-view-section-long-text {
      width: 250px;
      background-color: white;
      line-height: 25px;
      margin: 3px 10px 10px 10px;
      padding-left: 10px;
      font-size: 10px;
    }

    .tabular-view-section-medium-text {
      width: 110px;
      background-color: white;
      line-height: 25px;
      margin: 3px 10px 6px 5px;
      padding-left: 10px;
      font-size: 10px;
      text-align: left;
    }

    .tabular-view-section-short-text {
      width: 110px;
      background-color: white;
      line-height: 25px;
      margin: 3px 10px 5px 10px;
      padding-left: 10px;
      font-size: 10px;
      text-align: left;
    }

    .tabular-view-section-short-text-narrow {
      width: 60px;
      background-color: white;
      line-height: 22px;
      margin: 3px 10px 0 10px;
      padding-left: 10px;
      font-size: 10px;
    }

    .tabular-view-section-half {
      height: 288px;
      min-width: 144px;
      background-color: $light-grey;
      margin: 10px 5px 10px 0;
      font-size: 10px;
      text-align: center;
      line-height: 15px;
    }

    .tabular-view-section-one-third {
      height: 288px;
      min-width: 130px;
      background-color: $light-grey;
      margin: 10px 5px 10px 0;
      font-size: 10px;
      text-align: center;
      line-height: 15px;
    }

    .tabular-view-section-two-thirds {
      height: 288px;
      min-width: 180px;
      margin: 10px 5px 10px 0;
      font-size: 10px;

      .tabular-view-section-two-thirds-top {
        background-color: $light-grey;
        height: 208px;
        margin-bottom: 6px;
      }

      .tabular-view-section-two-thirds-bottom {
        background-color: $light-grey;
        height: 74px;
        margin-bottom: 5px;
      }

      .section-container-two-col-narrow {
        @include display-flex;
        margin-bottom: 9px;

        .section-container-two-col-narrow-1 {
          min-width: 70px;
        }
      }
    }

    .tabular-view-section {
      height: 288px;
      min-width: 288px;
      background-color: $light-grey;
      margin: 10px 5px 10px 0;

      .section-container-two-col {
        @include display-flex;

        .section-container-two-col-1 {
          min-width: 140px;
        }
      }

      .tabular-view-section-container {
        @include display-flex;

        .section-container-left {
          width: 190px;
          text-align: right;
          margin-right: 20px;
        }

        .section-container-right {
          width: 90px;
        }

        .section-container-left-2 {
          width: 120px;
          margin-left: 5px;
        }

        .section-container-right-2 {
          width: 160px;
        }

        .section-container-right-line {
          line-height: 30px;
          font-size: 12px;
        }

        .section-container-left-line {
          line-height: 30px;
          font-weight: bold;
          font-size: 12px;
        }

        .section-container-left-line-1 {
          line-height: 20px;
          font-weight: bold;
          font-size: 12px;
        }

        .section-container-left-line-2 {
          font-size: 12px;
          margin-bottom: 5px;
        }

        .section-container-right-line-1 {
          line-height: 36px;
          margin-bottom: 5px;
          width: 140px;
          background-color: white;
          padding-left: 10px;
        }
      }
    }

    .tabular-view-section-3 {
      @include display-flex;
      height: 288px;
      width: 288px;
      margin: 10px 10px 10px 0;

      .tabular-view-section-3-left {

        .tabular-view-section-3-left-top {
          width: 140px;
          height: 139px;
          background-color: $light-grey;
          margin-bottom: 10px;

          .tabular-view-section-3-left-top-text {
            line-height: 80px;
            text-align: center;
          }
        }

        .tabular-view-section-3-left-bottom {
          width: 140px;
          height: 139px;
          background-color: $light-grey;
          font-size: 12px;

          .tabular-view-section-3-left-bottom-content {
            @include display-flex;
            line-height: 40px;

            .tabular-view-section-3-left-bottom-left {
              width: 190px;
              font-weight: bold;
              margin-left: 10px;
            }

            .tabular-view-section-3-left-bottom-right {
              width: 70px;
            }
          }
        }
      }

      .tabular-view-section-3-right {
        width: 140px;
        height: 288px;
        background-color: $light-grey;
        margin-left: 10px;
        text-align: center;

        .tabular-view-section-3-right-line-1 {
          font-weight: bold;
          font-size: 12px;
          line-height: 80px;
        }

        .tabular-view-section-3-right-line-2 {
          font-size: 12px;
        }
      }
    }

    .tabular-view-section-5 {

      .tabular-view-section-5-top {
        background-color: $light-grey;
        height: 139px;
        width: 288px;
        margin: 10px 10px 10px 0;
      }

      .tabular-view-section-5-bottom {
        background-color: $light-grey;
        height: 139px;
        width: 288px;
        margin: 10px 10px 10px 0;
      }

      .tabular-view-section-5-text {
        width: 248px;
        background-color: white;
        line-height: 40px;
        margin: 0 10px 0 10px;
        padding-left: 20px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
}
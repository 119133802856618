@import "../../../styles/shared";

.toggle-wrapper {
  height: 18px;
  width: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: $scrollbar-grey;
  border-radius: 10px;

  .toggle-switch {
    margin: 0 3px 0 3px;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: $white;
  }
}

.active-toggle {
  background-color: $primary-blue;
  justify-content: flex-end;
}

.disabled-toggle {
  pointer-events: none;
  opacity: 0.4;
}

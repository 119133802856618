@import "../../styles/shared";

.view-create-report-modal-wrapper {
  .page-modal-wrapper {
    
    .page-modal {

      .page-content {
        padding: 0;

        .report-modal-content {
          width: 100%;
          box-sizing: border-box;

          .section-title {
            font-size: $size14;
            font-weight: 500;
            padding: $size16 $size24 $size24 $size24;
            color: $dark-grey-blue;
          }

          .section-subtitle {
            font-size: $size14;
            font-weight: 700;
            padding: 0 $size24 $size16 $size24;
            color: $primary-black;
          }

          .section-wrapper {
            display: flex;
            gap: $size16;
            width: 100%;
            padding: 0 $size24;
            box-sizing: border-box;

            .input-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size8;
              width: 50%;
              box-sizing: border-box;

              .input-label {
                font-size: $size14;
                font-weight: 500;
                color: $primary-black;

                .required {
                  color: $danger-red;
                }
              }

              .input-item {
                display: flex;
                width: 100%;
                height: $size36;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                text-indent: $size16;
                outline: none;
                box-sizing: border-box;
                align-items: center;
              }

              .text-area-item {
                width: 100%;
                height: 72px;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                font-size: $size14;
                padding: 10px $size16 10px $size16;
                line-height: 20px;
                box-sizing: border-box;
                outline: none;
                font-family: "Roboto";
              }

              .click-outside-wrapper {
                margin: 0;
                border: 1px solid $medium-grey-blue; 
                box-shadow: none;
                max-width: 100%;
                box-sizing: border-box;

                .height {
                  div {
                    color: $primary-black;
                    font-size: $size14;
                  }         

                  svg {
                    width: 12px;
                    height: 12px;
                    path {
                      fill : $primary-black;
                    }
                  }

                  .icon-dropdown-open {
                    transform: rotate(180deg);
                  }
                }

                .options-wrapper {
                  margin-top: 4px;
                  border: 1px solid $medium-grey-blue;
                  max-height: 160px;
                  position: relative;
                  overflow: overlay;

                  .selected-option {
                    color: $primary-black;
                    font-weight: 500;
                  }
                }
              }
              
              .search-with-options-wrapper {
                
                .search-wrapper {
                  width: 100%;
                }

                .search {
                  position: relative;
                  margin: 0;
                  box-shadow: none;
                  height: unset;
                  width: 100%;
                  
                  .reset-search-button {
                    margin-left: auto;
                    flex-shrink: 0;
                  }

                  .search-icon {
                    display: none;
                  }

                  ::placeholder {
                    font-style: italic;
                  }
                }

                .disabled {
                  opacity: 0.7;
                  pointer-events: none;
                  background-color: $light-grey-blue;

                  .search {
                    background-color: $light-grey-blue;
                  }
                }
      
                .options-wrapper {
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  margin-top: 4px;
                  border: 1px solid $medium-grey-blue;
                  max-height: 160px;
                  min-height: 50px;
                  position: relative;
                  overflow: overlay;
                  width: 100%;
                  height: 100%;
                  z-index: 1;
                  box-sizing: border-box;
                  box-shadow: 0px 1px 6px #00000024;
                  border-radius: $element-border-radius;
      
                  .result-wrapper {
                    display: flex;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 $size16;
                    align-items: center;
                    gap: $size16;
                    font-size: $size14;
                    box-sizing: border-box;

                    .spare-part-identifier {
                      flex-shrink: 0;
                      color: $primary-black;
                    }

                    .spare-part-description {
                      color: $grey;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow: hidden;
                    }
      
                    &:hover {
                      background-color: $light-grey-blue;
                      cursor: pointer;
                    }
                  }

                  .buttons-wrapper {
                    margin-top: $size8;
                    margin-bottom: $size8;
                  }
                }
              }

              .calendar-input-wrapper {
                .calendar-input {
                  background-color: $white;
                  border: 1px solid $medium-grey-blue;
                  border-radius: $element-border-radius;
                  box-sizing: border-box;
                  padding-left: $size16;

                  .arrow-icon {
                    display: none;
                  }

                  .calendar-icon {
                    width: 16px;
                    height: 16px;
                    path {
                      fill: $primary-black;
                    }
                  }

                  .custom-selected {
                    height: 34px;
                  }
                }
              }

              .calendar {
                z-index: 2 !important;
              }

              .upload-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size8;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 56px;
                border-radius: $element-border-radius;
                border: 1px solid $medium-grey-blue;
                box-sizing: border-box;
                padding: $size8;
                cursor: pointer;

                .file-input-wrapper {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  gap: $size16;
                  width: 100%;
                  height: 100%;
                  color: $dark-grey-blue;
                  cursor: pointer;
    
                  .call-to-action {
                    color: $primary-blue;
                    font-size: $size14;
                    font-weight: 700;
                  }

                  svg {
                    .fillable-area {
                      fill: $dark-grey-blue !important;
                    }
                  }
                }            
              }
    
              .file-input {
                display: none;
              }

              .receipt-file-name {
                margin-top: $size24;
                color: $dark-grey-blue;
                font-style: italic;
                font-size: $size14;

                .error-message {
                  color: $danger-red;
                }
              }

              .radio-input-wrapper {
                display: flex;
                gap: $size48;
                align-items: center;
              }

              .has-error {
                border: 1px solid $required-red;
              }

              .value {
                color: $dark-grey-blue;
                font-size: $size14;

                .link-action {
                  color: $primary-blue;
                  cursor: pointer;
                  margin-left: $size4;
                }
              }
            }

            .full-width {
              width: 100%;
            }

            .instruction-wrapper {
              display: flex;
              flex-direction: column;
              gap: $size8;
              font-size: $size14;
              padding: $size16;
              border-radius: $element-border-radius;
              background-color: $transparent-blue;
              color: $primary-black;

              .title {
                margin-bottom: $size24;
              }
            }
          }

          .with-margin-top {
            margin-top: $size16;
          }
          
          .with-margin-bottom {
            margin-bottom: $size24;
          }

          .separator {
            width: 100%;
            height: 1px;
            background-color: $medium-grey-blue;
            margin: $size24 0 $size8 0;
          }

          .inner-separator {
            width: calc(100% - 48px);
            height: 1px;
            background-color: $medium-grey-blue;
            margin: $size24;
          }
        }
      }      
    }
  }

  .view-mode {
    .page-modal {
      .page-content {
        height: calc(100% - 69px);
      }

      .page-footer {
        display: none;
      }
    }
  }

  @media all and (max-width: 768px) {
    .page-modal-wrapper {

      .page-modal {

        .page-content {

          .report-modal-content {
            font-size: $size14;

            .section-wrapper {
              flex-direction: column;
              
              .input-wrapper {
                width: 100%;
              }

            .empty {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

@import "../../styles/shared";

.service-modal {
  .page-modal {
    overflow: initial;

    .page-header {

      .status-wrapper {
        display: flex;
        align-items: center;

        .title {
          margin: 0 $size16 0 0;
          font-size: $size14;
          font-weight: 500;
          color: $primary-black;
        }

        .status {
          margin: 0 0 0 $size4;
        }         
      }        
    }

    .input-error {
      border: 1px solid $error-color;
      border-radius: 4px;
    }

    .input-option {
      font-size: $size14;
      font-weight: 500;
      gap: $size4;
    }

    input,
    textarea {
      width: 100%;
      box-sizing: border-box;
      border-radius: $element-border-radius;
      border: 1px solid $medium-grey-blue;
      font-family: Roboto, sans-serif;
      font-size: $size14;
      outline: none;
    }
    input {
      height: $size36;
      padding: $size4 $size16;
    }
    textarea {
      min-height: 80px;
      padding: $size4 $size16;
    }
    input[disabled] {
      color: $disabled-grey;
      background-color: $white;
    }

    .input-wrapper {
      input,
      textarea {
        margin-top: $size8;
      }
      &:nth-of-type(2) {
        margin-top: $size16;
      }
      textarea {
        padding: $size8 $size16;
      }
    }

    .policy-wrapper {
      margin-top: $size16;

      .dropdown-with-search {
        margin-top: $size8;
        .click-outside-wrapper {
          width: 50%;

          .select-input {
            padding: 0;
            box-shadow: none;
            height: $size36;
          }

          .select-options {
            width: 50%;

            .search-input {
              margin-top: $size8;
              width: 90%;
              align-self: center;
            }

            .scrollable-wrapper {
              max-height: 150px;
              input {
                width: fit-content;
              }
            }
          }
        }
      }
    }

    .click-outside-wrapper {
      max-width: 100%;
      border: 1px solid $medium-grey-blue;
      border-radius: $element-border-radius;
      box-shadow: none;

      .height {
        div:first-of-type {
          font-size: $size14;
          color: black;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
    }

    .loading-spinner {
      margin-top: 0 !important  ;
    }

    @media all and (max-width: 768px) {
      
      .page-header {

        .page-title {
          margin: 0 $size16;
        }
                  
        .page-header-content{
          margin-right: $size16;

          .status-wrapper {
            width: unset;
        
            .title {
              display: none;
            }
          }
        } 
      }   
      
      .page-content {
        padding: $size16;

        .policy-wrapper {

          .dropdown-with-search {

            .click-outside-wrapper {
              width: unset;
            }
          }
        }
      }
    }
  }
}

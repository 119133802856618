@import "../../styles/shared.scss";

.services-wrapper {
  
  .separator {
    width: 100%;
    height: 1px;
    background-color: $medium-grey-blue;
  }

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;
  }

  .services-table {
    margin-top: $size16;
  }

  .status-wrapper {
    display: flex;
    align-items: center;
    width: 150px;

    .status-active {
      color: $success-green;
      display: flex;
      align-items: center;
    }

    .status-inactive {
      display: flex;
      align-items: center;
      color: $dark-grey-blue;
    }

    svg {
      margin-right: $size8;
    }
  }

  .table-actions-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: end;
  }

  .buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .separator {
      width: calc(100% - 32px);
      margin: 0 $size16;
    }

    .actions-wrapper {
      height: unset;
      flex-direction: column;
      padding: 0 $size16;

      .order-by-mobile-wrapper {
        margin-top: $size16;
        height: $size36;
        width: 100%;

        .click-outside-wrapper {
          margin: 0 $size16 0 0;
          border: 1px solid $medium-grey-blue;
          box-shadow: none;
          max-width: 100%;
          cursor: pointer;

          .height {
            div {
              color: $primary-black;
              font-size: $size14;
            }

            svg {
              width: 12px;
              height: 12px;
              path {
                fill: $primary-black;
              }
            }

            .icon-dropdown-open {
              transform: rotate(180deg);
            }
          }

          .options-wrapper {
            margin-top: 4px;
            border: 1px solid $medium-grey-blue;
            max-height: 390px;
            position: relative;
            overflow: overlay;

            .selected-option {
              color: $primary-black;
              font-weight: 500;
            }
          }
        }
      }
    }

    .card {
      width: 100%;
      min-height: $size48;
      height: max-content;
      margin-top: $size4;
      background-color: $white;
      padding: $size16 $size8 $size16 $size16;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: $size8;
      cursor: pointer;

      .card-item {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: $size8;

        .card-item-title {
          width: 30%;
          font-size: $size14;
          font-weight: 700;
          color: $primary-black;
          margin: 0;
        }

        .card-item-body {
          width: 70%;
          padding-right: $size32;
          font-size: $size14;
          color: $primary-black;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          justify-content: flex-end;
          text-align: end;

          .card-actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: absolute;
            right: $size16;

            .actions-menu-wrapper {
              right: 0;
            }
          }
          
          .card-item-body-status {
            display: flex;
            gap: $size8;

            p {
              margin: 0;
            }

            .status-active {
              color: $success-green;
              display: flex;
              align-items: center;
            }

            .status-inactive {
              display: flex;
              align-items: center;
              color: $dark-grey-blue;
            }
          }
        }

        .align-center {
          align-items: center;
        }
      }

      .align-center {
        align-items: center;
      }
    }

    .with-margin-top {
      margin-top: $size16;
    }

    .buttons-wrapper {
      margin-top: $size16;
      margin-bottom: $size16;
    }
  }
}

@import "../../styles/shared";

.cycle-fault-details-modal-wrapper {
  .page-modal-wrapper {
    
    .page-modal {

      .page-content {
        height: calc(100% - 69px);
        padding: 0 0 $size16 0;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;

        .cycle-fault-modal-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          box-sizing: border-box;

          .fault-worked-on {
            display: flex;
            border-radius: $element-border-radius;
            align-items: center;
            margin: 0 $size24 $size8 $size24;
            padding: $size16;
            gap: $size16;
            background-color: $transparent-green;

            p {
              margin: 0;
              font-size: $size14;
              color: $success-green;
            }
          }

          .fault-status {
            display: flex;
            border-radius: $element-border-radius;
            align-items: center;
            justify-content: space-between;
            margin: 0 $size24 $size40 $size24;
            padding: $size16;
            background-color: $transparent-blue;
            border: 1px solid $primary-blue;

            .status-options {
              display: flex;
              gap: $size24;
            }
          }

          .product-fault-details-wrapper {
            display: flex;
            gap: $size16;
            width: calc(100% - 48px);
            box-sizing: border-box;
            margin: 0 $size24 $size16 $size24;
            padding-bottom: $size24;
            border-bottom: 1px solid $medium-grey-blue;

            .image-wrapper {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 80px;
              width: 80px;
              flex-shrink: 0;

              img {
                height: 64px;
                width: 64px;
                object-fit: contain;
              }
            }

            .data-wrapper {
              display: flex;
              width: 100%;
              gap: $size24;

              .identification-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size16;
                
                .identification {
                  display: flex;
                  flex-direction: column;
                  gap: $size4;

                  .product-model {
                    font-size: $size16;
                    font-weight: 700;
                    color: $primary-black;
                    margin: 0;
                  }

                  .product-serial {
                    font-size: $size14;
                    color: $primary-blue;
                    margin: 0;
                  }
                }

                .cycle-data {
                  display: flex;
                  gap: $size32;

                  .cycle-data-item {
                    display: flex;
                    flex-direction: column;
                    gap: $size4;

                    .cycle-data-item-title {
                      font-size: $size14;
                      color: $dark-grey-blue;
                      font-weight: 500;
                      margin: 0;
                    }

                    .cycle-data-item-value {
                      .country-wrapper {
                        display: flex;
                        gap: $size4;
                        align-items: center;

                        svg {
                          height: $size14;
                          width: $size14;
                        }

                        p {
                          margin: 0;
                        }
                      }
                    }
                  }
                }
              }

              .product-state {
                display: flex;
                gap: $size16;
                align-items: center;
                margin-left: auto;
                height: $size36;

                .status-wrapper {
                  display: flex;
                  gap: $size16;

                  .tooltiped-icon-wrapper {
                    .tooltip {
                      bottom: 145%;
                    }
                    
                    .unavailable {
                      opacity: 0.2;

                      path {
                        fill: $primary-black;
                      }
                    }
                  }                  
                }
              }              
            }
          }

          .cycle-faults-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size16;
            padding: 0 $size24 $size32 $size24;

            .faults-title {
              font-size: $size14;
              font-weight: 500;
              color: $primary-black;
              margin: 0;
            }

            .faults-wrapper {
              display: inline-grid;
              grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
              grid-gap: $size32;

              .fault-item-wrapper {
                display: flex;
                flex-direction: column;
                gap: $size4;
                box-sizing: border-box;
                flex-grow: 1;

                .title {
                  margin: 0;
                  font-size: $size14;
                  font-weight: 700;
                  color: $danger-red;
                }
                
                .time {
                  margin: 0;
                  font-size: $size14;
                  color: $dark-grey-blue;
                }
              }

              .no-cycles-wrapper {
                display: flex;
                align-items: center;
                background-color: $light-grey-blue;
                border-radius: $element-border-radius;
                height: 52px;

                .disclaimer {
                  margin: 0 $size16;
                  font-size: $size14;
                  font-style: italic;
                  color: $dark-grey-blue;
                }
              }
            }
          }

          .new-note-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size8;
            padding: 0 $size24 $size32 $size24;

            .title {
              margin: 0;
              font-size: $size14;
              font-weight: 500;
              color: $primary-black;
            }

            .note-wrapper {
              display: flex;
              width: 100%;
              gap: $size32;

              .note-input {
                width: 100%;
                height: 80px;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                padding: $size8 $size16 $size16 $size16;
                line-height: $size16;
                box-sizing: border-box;
                outline: none;
                font-family: 'Roboto';
              }
            }
          }

          .wrapped-tabs-wrapper {
            .tabs-container {
              padding: 0 $size16;

              .tab {
                max-width: 160px;
              }
            }

            .more-tabs {
              padding-right: $size16;
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media all and (max-width: 768px) {
    .page-modal-wrapper {

      .page-modal {

        .page-content {

          .cycle-fault-modal-content {
            font-size: $size14;

            .fault-worked-on {
              margin: 0 $size16 $size8 $size16;
            }

            .fault-status {
              flex-direction: column;
              align-items: flex-start;
              gap: $size16;
              margin: 0 $size16 $size24 $size16;

              .status-options {
                justify-content: space-between;
                width: 100%;
              }

              .submit-button {
                align-self: center;              
              }
            }

            .product-fault-details-wrapper {
              width: calc(100% - 32px);
              margin: 0 $size16 $size24 $size16;

              .data-wrapper {
                flex-direction: column;

                .identification-wrapper {
                  display: flex;
                  flex-direction: column;
                  gap: $size16;

                  .cycle-data {
                    flex-direction: column;
                    gap: $size16;
                  }
                }

                .product-state {
                  height: unset;
                  flex-direction: column;
                  align-items: flex-start;
                  margin-left: 0;

                  .action-button {
                    width: 100%;
                    max-width: 250px;
                  }
                }              
              }
            }

            .cycle-faults-wrapper {
              padding: 0 $size16 $size24 $size16;

              .faults-wrapper {
                grid-gap: $size16;
              }
            }

            .new-note-wrapper {
              padding: 0 $size16 $size24 $size16;

              .note-wrapper {
                flex-direction: column;
                gap: $size16;

                .submit-button {
                  align-self: center;
                }
              }
            }
          }
        }        
      }
    }
  }

  @media all and (max-width: 540px) {
    .page-modal-wrapper {

      .page-modal {

        .page-content {

          .cycle-fault-modal-content {

            .fault-status {

              .submit-button {
                width: 100%;
              }
            }

            .new-note-wrapper {

              .note-wrapper {

                .submit-button {
                  width: 100%;
                }
              }
            }
          }
        }        
      }
    }
  }
}

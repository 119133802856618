@import "../../styles/shared";

.products-documents-wrapper {
  padding: $size24 $size16;

  .documents-header {
    justify-content: space-between;

    .type-wrapper {
      align-items: center;
      width: 50%;

      .type-title {
        font-size: $size14;
        font-weight: 500;
        color: $primary-black;
        margin-right: $size24;
      }

      .click-outside-wrapper {
        border: 1px solid $medium-grey-blue; 
        box-shadow: none; 
        max-width: 290px;
        box-sizing: border-box;
        cursor: pointer;

        .height {
          div {
            color: $primary-black;
            font-size: $size14;
          }

          svg {
            width: 12px;
            height: 12px;

            path {
              fill: $primary-black;
            }
          }

          .text-wrapper {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .reset-filter-button {
            background: none;
            border: none;
            width: 28px !important;
            height: 28px !important;
            cursor: pointer;
            margin-right: $size16;

            svg {
              width: 28px !important;
              height: 28px !important;

              circle {
                fill: $grey-background;
                stroke: none;
              }

              g {
                line {
                  stroke: $primary-blue;
                }
              }
            }
          }

          .icon-dropdown-open {
            transform: rotate(180deg);
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 390px;
          position: relative;
          overflow: overlay;

          .option {
            box-sizing: border-box;
            padding: $size4 $size16 $size4 $size16;
            text-indent: 0;
            min-height: $size36;
            height: max-content;
            line-height: unset;
            display: flex;
            align-items: center;
          }

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }
    }
  }

  .documents-container {
    margin-top: $size32;
    flex-direction: column;
    align-items: center;

    .documents-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      border-radius: $element-border-radius;
      background-color: $white;

      .item-wrapper {
        display: flex;
        height: 50px;
        width: 100%;
        padding: 0 $size16;
        align-items: center;
        border-bottom: 1px solid $scrollbar-grey;
        box-sizing: border-box;
        gap: $size8;
        cursor: pointer;

        .image-wrapper {
          display: flex;
          width: 32px;
          height: 32px;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
        }

        .rounded-image {
          background-color: $light-grey-blue;
          border-radius: 50%;
        }

        .name-wrapper {
          display: block;
          margin-right: $size16;

          .title {
            margin: 0;
            font-size: $size14;
            font-weight: 500;
          }

          .section {
            margin: 0;
            font-size: $size14;
            font-weight: 400;
            opacity: 0.8;
          }
        }
      }
    }

    .buttons-wrapper {
      margin-top: $size16;
    }
  }

  .empty-list {
    min-height: 250px;
    
    .title {
      font-size: $size18;
    }
  }

  @media screen and (max-width: 768px) {
    .documents-header {
      flex-direction: column;
      gap: $size16;

      .type-wrapper {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
        gap: $size4;

        .click-outside-wrapper {
          max-width: 100%;
        }
      }

      .simple-button {
        width: 100%;
      }
    }

    .documents-container {
      .documents-content {
        gap: 1px;
        background-color: $medium-grey-blue;

        .item-wrapper {
          min-height: 48px;
          height: max-content;
          gap: $size8;
          border-bottom: none;
          background-color: $white;
          padding: 0 $size8;

          .movable-image {
            position: absolute;
            right: $size24;
          }

          .name-wrapper {
            margin: $size4 $size8 $size4 0;
            width: calc(100% - 72px);
          }
        }
      }
    }
  }
}

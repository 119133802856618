@import "../../../styles/shared.scss";

.convert-to-organization-modal {

  .page-modal {
    height: max-content !important;
    overflow: unset;

    .page-content {
      overflow: unset;

      .convert-to-organization-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size16;

        .row-wrapper {
          width: 100%;
          display: flex;
          gap: $size16;

          .input-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: $size8;
            position: relative;
    
            .modal-section-disclaimer {
              padding: $size8 $size16;
              border-radius: $element-border-radius;
              background-color: $transparent-blue;
            }

            .input-option {
              margin: 0;
              font-weight: 500;
            }
            
            .input-item {
              width: 100%;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }

            .has-error {
              border: 1px solid $required-red;
            }
          }
        }
      }
    }

    .convert-company-confirmation {

      .confirmation-modal-message {
        margin-bottom: $size8;
      }
  
      .convert-confirmation-wrapper {
        padding-left: 56px;
  
        .disclaimer {
          padding: $size8 $size16;
          border-radius: $element-border-radius;
          background-color: $transparent-blue;
          margin-bottom: $size16;
        }
      }
  
      .confirmation-modal-buttons {
        margin-top: $size32;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;

      .convert-to-organization-modal-content {
        font-size: $size14;
        gap: unset;

        .row-wrapper {

          .input-wrapper {
            flex-direction: column;
            gap: $size16;
            width: 100%;
            box-sizing: border-box;
          }

          .google-address-input-wrapper {
            gap: $size16;

            .row-wrapper {
              gap: $size16;

              .input-wrapper-google-address {
                margin-bottom: 0px;
              }
            }
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }

      .convert-company-confirmation {
        font-size: $size14;
  
        .convert-confirmation-wrapper {
          padding-left: 0;
  
          .disclaimer {
            p {
              margin: 0;
            }
          }
        }
  
        .confirmation-modal-buttons {
          flex-direction: column;
  
          button {
            height: $size36;
          }
        }
      }
    }
  }

  @media screen and (max-width: 485px) {
    .page-modal {

      .page-content {
        overflow: scroll;

        .convert-to-organization-modal-content {

          .row-wrapper {
            flex-direction: column;
            gap: unset;
          }
        }
      }
    }
  }
}

@import '../../../styles/shared';

.show-more-wrapper {

  .show-less,
  .show-more {
    background-color: transparent;
    border: none;
    color: $primary-blue;
    cursor: pointer;
  }
}

@import "../../styles/shared.scss";

.request-help-modal {
  .form-container {
    display: flex;
    flex-direction: column;
    gap: $size16;
    
    .error {
      color:$red;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: $size8;
      
      ul {
        padding: 0px;
        list-style: none;
      }
      
      .uploaded-files {
        margin: 0px;
        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: $size16;
          
          svg {
            cursor: pointer;
          }
        }
      }
      
      label {
        font-weight: 500;
      }

      input {
        height: $size36;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E5E7F0;
        border-radius: $size4;
        opacity: 1;
        outline: none;
        text-indent: $size16;
        padding: $size4;
      }
      
      textarea {
        border: 1px solid #E5E7F0;
        border-radius: $size4;
        padding: $size4;
        padding: $size10 $size16;
        outline: none;
      }
    }
  }
  
  .half {
    width: 50%;
  }
  
  .upload-wrapper {
    display: flex;
    flex-direction: column;
    gap: $size8;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 56px;
    border-radius: $element-border-radius;
    border: 1px solid $medium-grey-blue;
    box-sizing: border-box;
    padding: $size8;
    cursor: pointer;

    .file-input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: $size16;
      width: 100%;
      height: 100%;
      color: $dark-grey-blue;
      cursor: pointer;

      .call-to-action {
        color: $primary-blue;
        font-size: $size14;
        font-weight: 700;
      }

      svg {
        .fillable-area {
          fill: $dark-grey-blue !important;
        }
      }
    }            
  }

  .file-input {
    display: none;
  }

  .receipt-file-name {
    margin-top: $size24;
    color: $dark-grey-blue;
    font-style: italic;
    font-size: $size14;

    .error-message {
      color: $danger-red;
    }
  }
  
  .header-info {
    background-color: #F0FAFF;
    border-radius: $size4;
    padding: 0px $size16;
    letter-spacing: 0px;
  }
}
@import "../../styles/shared.scss";

.create-account-modal,
.edit-account-modal {
  .page-modal {
    .page-header {
      .status-wrapper {
        .title {
          font-weight: 500;
          font-size: $size14;
        }
        .toggle-wrapper {
          margin: 0 $size4 0 $size16;
        }
      }
    }

    .page-content {
      .account-details-wrapper {
        .detail-wrapper {
          .title {
            font-size: $size14;
            color: $black;
            font-weight: 500;

            &:last-of-type {
              margin-top: $size8;
            }

            .character-limit {
              font-weight: normal;
            }
          }

          & > textarea {
            font-size: $size14;
            font-family: Roboto, sans-serif;
            margin-top: $size8;
            resize: none;
            text-align: start;
            border-radius: $element-border-radius;
            border: 1px solid $medium-grey-blue;
            padding: 10px;
            box-sizing: border-box;
            outline: none
          }

          textarea:disabled {
            color: $disabled-grey;
            background-color: $white;
          }

          &:first-of-type {
            font-weight: normal;
            width: 50%;

            .character-limit {
              color: $dark-grey-blue;
            }
            textarea {
              height: 40px;
              width: 100%;
            }

            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }

          &:nth-of-type(2) {
            margin-top: $size16;
            textarea {
              height: 200px;
              width: 100%;
              box-sizing: border-box;
            }
          }

          .input-error {
            border: 1px solid $error-color;
            border-radius: $element-border-radius;
          }
        }

        .status-wrapper {
          margin-top: $size16;
          .title {
            font-weight: 500;
            font-size: $size14;
          }
          .toggle-wrapper {
            margin: 0 $size4 0 $size16;
          }
        }
        .uneditable-toggle {
          .toggle-wrapper {
            opacity: 0.3;
            cursor: default;
            pointer-events: none;
          }
        }
      }

      .loading-spinner {
        margin-top: 0 !important;
        left: calc(50% - 1.5em);
        top: calc(40% - 1.5em);
      }
    }
    .page-footer {
      .action-delete-button {
        margin-right: auto;
      }
    }
  }
}

@import '../../styles/shared';

.cycle-analytics-content-wrapper {

  .actions-wrapper {
    display: flex;
    align-items: center;
    height: $size36;
    width: 100%;
    margin-top: $size16;
    margin-bottom: $size16;
    box-sizing: border-box;

    .disable-search-bar {

      .search {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    .disabled-filters-button {

      .filter-button-wrapper {

        &:hover {
          box-shadow: none !important;
        }

        .filter-button {
          opacity: 0.5; 
          pointer-events: none; 
        }
      }
    }

    .no-reset-button {

      .reset-filters-button {
        display: none;
      }
    }

    .clear-filters {
      margin-left: $size24;
      cursor: pointer;
      text-decoration: underline;
    }

    .current-date {
      margin-left: auto;
      font-size: $size18;
      color: $primary-black;

      .medium {
        font-weight: 500;
      }
    }
  }
  
  .overview-expand-wrapper {
    margin-bottom: $size16;
    background-color: $white;

    .overview-expand-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: $size16;
      height: 52px;
      border-radius: $element-border-radius;
      box-sizing: border-box;
      user-select: none;
      cursor: pointer;

      .title {
        font-size: $size16;
        font-weight: 700;
        color: $primary-black;
      }
    }

    .overview-expand-body {
      display: flex;
      flex-direction: column;

      .separator {
        width: 100%;
        height: 1px;
        margin: 0 0 $size16 0;
        background-color: $medium-grey-blue;
      }
  
      .overview-table {
        padding: 0 $size16;
        font-size: $size14;

        .table {
          .header-item {
            width: unset;
          }
        }
      }

      .bold-font {
        font-weight: 700;
      }

      .total-units-wrapper {
        display: flex;
        align-items: center;
        height: $size48;
        width: calc(100% - 32px);
        margin: 0 $size16 $size16 $size16;
        box-sizing: border-box;
        border-bottom: 1px solid $medium-grey-blue;
        font-weight: 500;
        font-size: $size14;
        

        .label {
          margin-left: $size16;
        }

        .value {
          margin-left: auto;
          display: flex;
          padding: 0 $size16;
          box-sizing: border-box;
        }
      }
    }
  }

  .product-details-wrapper {
    display: flex;
    margin-top: $size16;
    background-color: $white;
    border-radius: $element-border-radius;
    box-sizing: border-box;
    min-height: 285px;

    .details-header {
      display: flex;
      width: 100%;
      padding: $size16;
      height: 68px;
      align-items: center;
      box-sizing: border-box;

      .product-name {
        font-size: $size24;
        color: $primary-black;
        font-weight: 700;
      }

      .filter-options-wrapper {
        margin-left: auto;
        display: flex;
        gap: $size24;
        align-items: center;
        flex-shrink: 0;
        box-sizing: border-box;

        .filter-option {
          display: flex;
          gap: $size16;
          align-items: center;
          box-sizing: border-box;

          .input-title {
            font-size: $size14;
            color: $primary-black;
            font-weight: 500;
            flex-shrink: 0;
          }

          .click-outside-wrapper {
            margin: 0;
            border: 1px solid $medium-grey-blue;
            box-shadow: none;
            width: 240px;
            box-sizing: border-box;
            cursor: pointer;
  
            .date {
              color: $dark-grey-blue;
            }
  
            .duration {
              margin-right: $size8;
            }
  
            .height {
              gap: $size8;
  
              div {
                color: $primary-black;
                font-size: $size14;
              }         
  
              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }
    
              .icon-dropdown-open {
                transform: rotate(180deg);
              }
  
              .text-wrapper {
                text-indent: $size16;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
    
            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 160px;
              position: relative;
              overflow: overlay;
  
              .option {
                font-size: $size14;
                line-height: 0px;
                padding: $size16 $size16 $size16 $size16;
                text-indent: 0;
                box-sizing: border-box;
                height: unset;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
  
              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }
          }
  
          .dropdown-disabled {
            pointer-events: none;
            background-color: $light-grey-blue;
          }
        }
      }
    }

    .models-container {
      padding: $size16;
      display: flex;
      flex-direction: column;
      border-bottom: 2px solid $medium-grey-blue;
      gap: $size8;

      .title {
        font-weight: 500;
        font-size: $size14;
        color: $primary-black;
      }

      .models-checkboxes {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        font-size: $size14;

        .models-checkbox {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: $size8 0 $size8 0;
        }
      }
    }

    .no-product-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
  
      .empty-page-wrapper {
        min-height: 150px;
        
        .title {
          font-size: $size16;
        }      
      }
    }

    .wrapped-tabs-wrapper {
      padding: $size16 0 0 0;
      margin: 0 $size16;
      width: calc(100% - 32px);
    }
  }

  .list-download-wrapper {
    margin: $size16;
    display: flex;
    flex-direction: column;

    .simple-button {
      margin-left: auto;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: $size16 $size16 $size24 $size16;
    gap: $size48;

    .summary-header {
      font-size: $size16;
      font-weight: bold;
      margin: $size16 0;
    }

    .summary-table {
      height: 350px;
      overflow-y: auto;

      td:nth-child(1) {
        color: $primary-blue;
      }

      .table-actions-wrapper {
        position: relative;

        .actions-menu {
          position: absolute;
          top: calc(100% - 20px);
          right: 0; 
          z-index: 1; 
        }
      }
    }

    .table-actions-wrapper {
      cursor: pointer;
    }
  }

  .spinner-wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    .loading-spinner {
      position: relative;
      top: 25%;
      left: 50%;
      transform: translateY(-50%);
    }
  }

  @media screen and (max-width: 1024px) {
    .product-details-wrapper {
      .details-header {
        height: unset;
        align-items: flex-start;

        .filter-options-wrapper {
          flex-direction: column;
          align-items: flex-end;
          margin-left: auto;
          gap: $size8;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .actions-wrapper {
      flex-direction: column;
      gap: $size16;
      height: unset;
      align-items: flex-start;
      padding: 0 $size16;
  
      .current-date {
        align-self: center;
        margin: 0;
      }
    }

    .overview-expand-wrapper {
      .overview-expanded-header {
        border-radius: 0;
      }
    }

    .product-details-wrapper {
      border-radius: 0;

      .details-header {
        height: unset;
        flex-direction: column;
        gap: $size8;

        .filter-options-wrapper {
          flex-direction: column;
          align-items: flex-end;
          margin-left: auto;
          gap: $size8;
          width: 100%;

          .filter-option {
            width: 100%;
            gap: $size32;

            .click-outside-wrapper {
              width: 100%;
              max-width: 100%;
            }
          }
        }
      }
    }

    .grid-container {
      grid-template-columns: 1fr;
      gap: $size16;
      font-size: $size14;

      .summary-table {
        height: unset;
        overflow: visible;

        .table-actions-wrapper {
          position: relative;

          .actions-menu {
            top: 4px;
            right: 0;
          }
        }
      }
    }
  }
}

@import "../../styles/shared";

.basket-modal-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  .basket-modal-overflow {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 99;
    cursor: pointer;
    top: 0;
    left: 0;
  }

  .basket-modal-wrapper {
    position: fixed;
    top: 69px;
    right: -420px;
    height: calc(100vh - 70px);
    width: 420px;
    background-color: $white;
    box-shadow: -3px 4px 16px 0 rgba(0, 0, 0, 0.05);
    z-index: 100;
    transition: transform 0.3s ease-in-out;

    .basket-header {
      display: flex;
      align-items: center;
      gap: $size8;
      position: sticky;
      z-index: 101;
      top: 0;
      background-color: $white;
      height: 88px;
      padding: $size16 $size24 $size16 $size24;
      box-sizing: border-box;
      border-bottom: 1px solid transparent;

      .title {
        font-size: $size16;
        margin: 0;
        font-weight: 500;
        color: $primary-black;
      }

      .items-number {
        display: flex;
        align-items: center;
        justify-content: center;
        width: $size24;
        height: $size24;
        color: $white;
        text-align: center;
        background: $primary-blue;
        border-radius: 50%;
      }

      .back-button {
        margin-left: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .header-border {
      border-bottom: 1px solid $medium-grey-blue;
    }

    .basket-body {
      display: flex;
      flex-direction: column;
      gap: 2px;
      overflow-y: auto;
      height: calc(100% - 228px);

      .basket-product-wrapper {
        display: flex;
        gap: $size16;
        box-sizing: border-box;
        padding: $size24;
        border-bottom: 1px solid $medium-grey-blue;
        width: 100%;

        .product-image {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 80px;
          height: 80px;
          flex-shrink: 0;

          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
          }
        }

        .product-details {
          display: flex;
          width: 100%;
          flex-direction: column;

          .product-name {
            font-weight: 700;
            font-size: $size16;
            order: 0;
          }

          .product-description {
            margin-top: $size4;
            font-weight: 400;
            color: $dark-grey-blue;
            order: 1;
          }

          .product-price {
            color: $primary-blue;
            margin-top: $size16;
            font-size: $size16;
            font-weight: 500;
            order: 2;
          }

          .product-quantity {
            display: flex;
            margin-top: $size16;
            gap: $size16;
            align-items: center;
            order: 2;

            .quantity-change-button {
              font-size: $size24;
              cursor: pointer;
            }

            .minus {
              color: $dark-grey-blue;
            }

            .quantity {
              font-size: $size16;
              color: $primary-black;
            }
          }

          .product-plan {
            display: flex;
            flex-direction: column;
            margin-top: $size16;
            gap: $size8;
            order: 1;

            .input-value {
              font-size: $size16;
              font-weight: 500;
              color: $primary-black;
            }

            .click-outside-wrapper {
              margin: 0;
              border: 1px solid $medium-grey-blue; 
              box-shadow: none;
              max-width: 100%;
              box-sizing: border-box;

              .height {
                div {
                  color: $primary-black;
                  font-size: $size14;
                }         

                svg {
                  width: 12px;
                  height: 12px;
                  path {
                    fill : $primary-black;
                  }
                }

                .icon-dropdown-open {
                  transform: rotate(180deg);
                }
              }

              .options-wrapper {
                margin-top: 4px;
                border: 1px solid $medium-grey-blue;
                max-height: 160px;
                position: relative;
                overflow: overlay;

                .selected-option {
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }
          }

          .with-order-2 {
            order: 2 !important;
          }
        }

        .remove-button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: auto;
          height: fit-content;
          width: fit-content;
          background-color: inherit;
          border: none;
          cursor: pointer;
          width: $size24;
          height: $size24;
        }
      }
      
      .basket-product-wrapper:last-of-type {
        border-bottom: 1px solid transparent;
      }

      .no-products {
        align-self: center;
        margin-top: 50%;
        font-size: $size14;
        font-weight: 500;
      }
    }

    .basket-footer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 140px;
      position: sticky;
      z-index: 101;
      bottom: 0;
      padding: $size24 $size24 $size8 $size24;
      background-color: $white;
      box-sizing: border-box;
      border-top: 1px solid $medium-grey-blue;

      .cart-total {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .total-label {
          color: $dark-grey-blue;
          font-size: $size14;
        }

        .number-label {
          font-weight: 500;
          color: $primary-blue;
          font-size: $size24;
        }
      }

      .actions {
        display: flex;
        gap: $size8;
        align-items: center;

        .simple-button {
          width: 100%;
        }
      }

      .extras {
        text-align: center;
        color: $dark-grey-blue;
      }
    }
  }

  .open {
    transform: translateX(-420px);
  }

  .hide {
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
  }

  @media all and (max-width: 768px) {
    .basket-modal-wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      min-width: unset;
      border-radius: 0;
    }
  }
}

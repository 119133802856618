@import "../../styles/shared";

.firmware-version-history {
  padding: $size16;

  .version-expand-header {
    padding: $size8 0;
    cursor: pointer;

    .title {
      margin-left: $size16;

      div:first-of-type {
        color: $primary-black;
        font-size: $size14;
        font-weight: 700;
      }
      div:last-of-type {
        font-size: $size14;
        color: $dark-grey-blue;
      }
    }

    .icon {
      margin-right: $size16;
    }
  }

  .version-expand-body {
    .versions-notes-list {
      display: flex;
      flex-direction: column;
      padding: $size16;
      font-size: $size14;
      gap: $size4;

      .note-item {
        display: flex;
        gap: $size8;
        align-items: flex-start;
        

        .bullet {
          background-color: $primary-blue;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          flex-shrink: 0;
          margin-top: 6px;
        }
      }
    }
    
    .versions-download-list {
      padding-left: $size16;
    }
    
    .firmware-documents {
      display: flex;
      flex-direction: column;
      margin-left: $size16;
      padding: $size8 0;

      .download-document {
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }

      .name-wrapper {
        margin-left: $size16;

        .doc-icon {
          background: #FFFFFF 0% 0% no-repeat padding-box;
          box-shadow: 0px 2px 3px #00000014;
          border-radius: 4px;
          opacity: 1;
        }

        .file-name {
          margin-left: $size16;
          font-size: $size14;
          font-weight: normal;
          color: $primary-black;
        }
      }
    }
  }

  .highlight {
    background-color: $light-grey-blue;
  }

  .border-bottom {
    border-bottom: 1px solid $medium-grey-blue;
  }
  
  .section-title {
    font-size: $size14;
    font-weight: 500;
    color: $dark-grey-blue;
    margin: $size24 0;
    text-transform: uppercase;
  }
  
  .firmware-executables-wrapper {
    display: flex;
    flex-direction: column;
    gap: $size24;

    .firmware-executable {
      display: flex;
      gap: $size16;
      align-items: center;

      .download-document {
        background: transparent;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }

      .doc-icon {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 3px #00000014;
        border-radius: 4px;
        opacity: 1;
      }

      .file-name {
        font-size: $size14;
        color: $primary-black;
        font-weight: 500;
      }
    }
  }

  .inner-separator {
    display: flex;
    height: 1px;
    background-color: $light-grey-blue;
    width: 100%;
  }
}
@import "../../../styles/shared";

.event-log-wrapper {
  display: block;
  height: 100%;
  min-height: 790px;
  margin-top: $size16;
  border-radius: $element-border-radius;
  background-color: $white;
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.05);
  padding: $size16 $size24;

  .search-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: $size8;
    box-sizing: border-box;

    .search-with-filter-wrapper {
      .filter-button-wrapper {
        display: none;
      }

      .search-wrapper {
        .search {
          background: $light-grey-blue;
        }
      }
    }
  }

  .event-log-table-utils {
    @include display-flex;
    flex: 1;
    justify-content: space-between;

    .filters-wrapper {
      @include display-flex;
      width: 420px;
      height: 30px;

      .sort-filter {
        display: flex;
        align-items: center;
        width: 200px;
        height: 30px;
        background-color: $light-grey-blue;
        border-radius: $element-border-radius;
        justify-content: space-between;
        margin-left: 10px;

        .sort-filter-icon {
          display: inline-flex;
          align-items: center;
          justify-content: left;
          height: 30px;
          font-size: 1em;

          .sort-icon {
            width: 45%;
            height: 45%;
          }

          .search-button {
            cursor: pointer;
            color: $primary-blue;
            margin: 0 5px;
          }

          .search {
            border: none;
            width: 165px;
            margin-left: 5px;
            background-color: $light-grey-blue;
          }
        }

        .sort-filter-select {
          background-color: $light-grey-blue;
          border: none;
          width: 120px;
          text-align: center;
        }
      }
    }
  }

  .event-log-table-legend {
    @include display-flex;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0 20px 0;

    .event-log-table-legend-left {
      @include display-flex;
      display: inline-flex;

      .event-log-table-legend-fault {
        background-color: $dark-red;
        border: 1px solid #d7d0d0;
        width: 20px;
        border-radius: $element-border-radius;
        margin-right: 10px;
      }

      .event-log-table-legend-interrupted {
        background-color: $dark-orange;
        border: 1px solid #d7d0d0;
        width: 20px;
        border-radius: $element-border-radius;
        margin-right: 10px;
      }

      .event-log-table-legend-text {
        margin: 0 10px 0 0;
      }
    }

    .event-log-table-legend-right {
      margin-right: 10px;
      cursor: pointer;

      .disabled {
        cursor: not-allowed;
        color: #7C7C7C;
      }
    }
    
    .icons {
      display: inline-flex;
      gap: 4px;
      
      .refresh-icon {
        width: 19.59px;
        height: 19.59px;
      }
    }
  }

  .log-table-wrapper {

    .log-table-header {
      @include display-flex;
      display: inline-flex;
      width: 100%;

      .log-table-header-section {
        @include display-flex;
        display: inline-flex;
        justify-content: space-between;
        color: white;
        background-color: $table-header-background;
        width: 100%;
        min-height: 20px;
        border: 1px solid #d7d0d0;
        padding: 3px 5px;

        .arrow {
          cursor: pointer;
        }
      }
    }

    .log-table-body {
      @include display-flex;
      display: inline-flex;
      width: 100%;

      .log-table-body-section {
        @include display-flex;
        display: inline-flex;
        justify-content: space-between;
        background-color: white;
        width: 100%;
        min-height: 20px;
        border: 1px solid #d7d0d0;
        padding: 3px 5px;
        overflow: hidden;

        .arrow {
          cursor: pointer;
        }

        &.fault {
          background-color: $dark-red;
        }

        &.interrupted {
          background-color: $dark-orange;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    border-radius: 0;
  }
}

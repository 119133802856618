@import "../../styles/shared";

.business-rule-modal {
  .page-modal {
    display: flex;
    flex-direction: column;

    .page-header {

      .status-wrapper {
        display: flex;
        align-items: center;

        .label {
          margin: 0 $size16 0 0;
          font-size: $size14;
          font-weight: 500;
          color: $primary-black;
        }

        .status {
          margin: 0 0 0 $size4;
        }         
      }        
    }

    .page-content {
      .input-error {
        border: 1px solid $error-color;
        border-radius: $element-border-radius;
      }
  
      .input-wrapper,
      .dropdown-wrapper {
        .input-option {
          margin: 0;
          font-size: $size14;
          font-weight: 500;
          gap: $size4;
        }
        input,
        textarea,
        .calendar-input,
        .click-outside-wrapper {
          margin-top: $size8;
        }
  
        .is-digital-input {
          text-transform: capitalize;
        }
      }
  
      .input-wrapper:nth-of-type(2) {
        margin-top: $size16;
      }
  
      input,
      textarea {
        width: 100%;
        height: $size36;
        box-sizing: border-box;
        border-radius: $element-border-radius;
        border: 1px solid $medium-grey-blue;
        font-family: Roboto, sans-serif;
        outline: none;
      }
  
      input {
        height: $size36;
        padding: $size4 $size16;
      }
  
      textarea {
        min-height: 80px;
        padding: $size4 $size16;
      }
  
      input:disabled {
        color: $disabled-grey;
        background-color: $white;
      }
  
      .click-outside-wrapper {
        max-width: 100%;
        height: $size36;
        box-sizing: border-box;
        border: 1px solid $medium-grey-blue;
        border-radius: $element-border-radius;
        box-shadow: none;
        
        .height {
          div:first-of-type {
            font-size: $size14;
            color: black;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        .options-wrapper {
          margin-top: 4px;
          border: 1px solid $medium-grey-blue;
          max-height: 160px;
          position: relative;
          overflow: overlay;

          .selected-option {
            color: $primary-black;
            font-weight: 500;
          }
        }
      }
  
      .type-row-wrapper {
        .input-wrapper,
        .dropdown-wrapper {
          margin-top: $size16;
          width: calc(50% - 8px);
        }
      }
  
      .trial-details-wrapper {
        .input-wrapper,
        .dropdown-wrapper {
          margin-top: $size16;
          width: calc(50% - 8px);
        }
      }
  
      .discount-details-wrapper {
        width: calc(47.5% + 7.5px);
        .input-placeholder-wrapper {
          position: relative;
          .placeholder {
            position: absolute;
            font-size: $size14;
            font-weight: 500;
            pointer-events: none;
            right: 20px;
            top: 14px;
          }
        }
      }
  
      .test-drive-wrapper {

        .dates-wrapper {

          .input-wrapper {
            margin-top: $size16;
            width: calc(50% - 8px);

            .calendar-input {
              background-color: $white;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              box-sizing: border-box;
              padding-left: $size16;

              .arrow-icon {
                display: none;
              }

              .calendar-icon {
                width: 16px;
                height: 16px;
                path {
                  fill: $primary-black;
                }
              }

              .custom-selected {
                height: 34px;
              }
            }

            #calendar-options {
              z-index: 100;
              border-radius: $element-border-radius;
              margin-top: $size4;
              
              .click-outside-wrapper {
                margin-top: 0 !important;
              }
            }
          }
        }
  
        .add-users-wrapper,
        .add-products-wrapper {

          .search-with-options-wrapper {
            position: relative;
            margin-top: $size8;

            .search {
              position: relative;
              margin: 0;
              box-shadow: none;
              border-radius: $element-border-radius;
              border: 1px solid $medium-grey-blue;
            }

            input {
              border: none;
              margin: 0;

              &::placeholder {
                font-style: italic;
              }
            }

            svg {
              position: absolute;
              right: $size8;

              .fillable-area {
                fill: $primary-black !important;
              }
            }
  
            .options-wrapper {
              background-color: $white;
              max-height: 150px;
              overflow: auto;
              position: relative;
              width: 100%;
              z-index: 10001;
              box-shadow: 0px 1px 6px #00000024;
              margin-top: $size4;
              border-radius: $element-border-radius;
  
              .result-wrapper {
                padding: $size8 $size16;
                gap: $size8;
                height: $size36;
                box-sizing: border-box;
                align-items: center;
  
                .user-name,
                .product-title {
                  width: 30%;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }

                .product-title {
                  width: 50% !important;
                }

                .user-email,
                .product-partnumber {
                  color: $grey;
                }
  
                &:hover {
                  background-color: $grey-background;
                  cursor: pointer;
                }
              }
  
              .no-results {
                text-align: center;
                font-size: $size14;
                padding: $size16 0;
              }
  
              .buttons-wrapper {
                margin-top: 0;
                margin-bottom: $size16;
              }
            }
          }

          .emails-wrapper,
          .products-wrapper {
            margin: $size16 0;
            gap: $size8;
            flex-wrap: wrap;

            .user-wrapper,
            .product-wrapper {
              background-color: $medium-grey-blue;
              padding: $size8 $size16;
              border-radius: $size8;
            }

            .remove-user,
            .remove-product {
              display: flex;
              background: none;
              border: none;
              margin-left: $size8;
              text-align: center;
              font-size: $size16;
              font-weight: 700;
              color: $dark-grey-blue;
              cursor: pointer;
            }
          }

          .remove-user-btn,
          .remove-product-btn {
            background: none;
            border: none;
            width: 24px !important;
            height: 24px !important;
            cursor: pointer;
            margin-right: $size4;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              flex-shrink: 0;
              width: 16px !important;
              height: 16px !important;
              margin-right: $size16 !important;
      
              g {
                stroke: $dark-grey-blue;
              }
            }
          }
        }
      }
    }

    @media all and (max-width: 768px) { 

      .page-header {

        .page-header-content {
          margin-right: $size16;

          .status-wrapper {
            width: unset;

            .label {
              display: none;
            }
          }
        }
      }

      .page-content {
        padding: $size16;

        .input-wrapper,
        .dropdown-wrapper {
          width: 100%; 
          margin-top: $size8; 
        }

        .type-row-wrapper {
          display: block;

          .input-wrapper,
          .dropdown-wrapper {
            width: 100% !important; 
          }
        }

        .trial-details-wrapper {
          display: block;

          .input-wrapper,
          .dropdown-wrapper {
            width: 100% !important;
          }
        }

        .dates-wrapper {
          display: block;

          .input-wrapper {
            width:  100% !important;
          }
        }
      }
    }
  }
}

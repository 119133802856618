@import '../../styles/shared';

.login-container {
  //margin-top: 5.4%;
  //margin-bottom: 5.4%;
  margin: 22px auto;

  div,
  p,
  input {
    font-size: 16px;
  }

  a {
    color: #11232e;
    text-decoration: none;
  }

  .form-container, .registration-container {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 50px;
    border-radius: $element-border-radius;
    box-shadow: 0px 0px 25px #0000000D;
    margin: auto;
    width: 100%;
  }
  
  .form-container {
    max-width: 394px;

    .divider {
      border: 2px solid $primary-blue;
      margin: 40px auto;
      max-width: 100px;
    }

    .input-fields {
      display: flex;
      flex-direction: column;
    }

    .input-box {
      background: #F5F6FA 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 1px #00000029;
      border-radius: $element-border-radius;
      display:flex;
      margin: 16px auto;
      position: relative;
      width: 100%;

      .at-icon,
      .lock-icon,
      .eye-icon,
      .checkmark-icon {
        color: #768092;
        font-size: inherit;
        padding: 15px 7px 15px 12px;
        vertical-align: middle;
      }
    }

    input {
      &:-webkit-autofill,
      &:-webkit-autofill:hover, 
      &:-webkit-autofill:focus, 
      &:-webkit-autofill:active,
      &:-internal-autofill-selected {
        -webkit-box-shadow: 0 0 0 30px #f5f6fa inset !important;
        box-shadow: 0 0 0 30px #f5f6fa inset !important;
      }

      &[type="email"],
      &[type="password"],
      &[type="text"] {
        background: #f5f6fa;
        // box-shadow: 0px 1px 1px #00000029;
        // border-radius: 5px;
        border: 0;
        outline: 0;
        padding: 15px 12px 15px 5px;
        //height: 15px;
        //margin: 16px auto;
        //max-width: 374px;
        width: 100%;
      }
    }

    .remember-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 16px auto;

      label {
        padding-left: 9px;
      }
    }

    .signup-link {
      margin-top: 50px;
      margin-bottom: 10px;

      a {
        font-weight: bold;
      }
    }

    .updates-account-message {
      font-style: italic;

      span {
        font-weight: 600;
      }
    }

    button {
      max-width: 394px;
    }

    
    @media all and (max-width: 480px) {
      padding: 1.6rem;
      max-width: 89%;
    }
  }
  
  .registration-container {
    max-width: 494px;
    height: 514px;
    padding: 0px;

    .coltene-icon {
      margin-top: 96px;
      margin-bottom: 61px;
    }
    
    .title {
      font-size: 2.5rem;
      margin-bottom: 13px;
      font-weight: bold;
    }
    p {
      margin-bottom: 15px;
      font-size: 1.3rem;
      font-weight: 500;
    }
    .link {
      text-decoration: underline;
      color: #21A2EE;
    }
  }
}
@import "../../../styles/shared";

.dropdown-with-search {
  position: relative;

  .select-input {
    background-color: $white;
    @include display-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 $size16;
    box-shadow: $input-box-shadow;
    border-radius: $element-border-radius;
    height: 40px;
    cursor: pointer;

    input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      cursor: pointer;
      border: none;
      outline: none;
      padding: $size8 0;
    }

    .active {
      transform: rotateX(180deg);
    }

    svg {
      margin-right: $size16;
      path {
        fill: $dark-grey-blue;
      }
    }
  }

  .select-options {
    position: absolute;
    background-color: $white;
    @include display-flex;
    @include flex-direction-column;
    box-shadow: $modal-box-shadow;
    border-radius: $element-border-radius;
    margin-top: $size4;
    width: 100%;
    padding: $size8 0;
    z-index: 1;

    .search-input {
      margin: $size8;
      padding: $size4;
    }

    .scrollable-wrapper {
      overflow-y: scroll;
      max-height: 300px;

      .option {
        height: $size36;
        padding-left: 5%;
        text-indent: 0;

        &:hover {
          background: $grey-background;
        }
      }

      .no-data {
        text-align: center;
      }
    }
  }
}

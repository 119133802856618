@import "../../../styles/shared";

.confirmation-modal-wrapper {
  position: fixed;
  z-index: 9998;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .modal {
    width: 470px;
    background-color: $white;
    z-index: 1;
    position: relative;
    padding: $size32 $size32 $size24 $size32;
    top: calc((100vh + 69px) / 2);
    transform: translateY(-50%);
    border-radius: $element-border-radius;
    max-height: 80%;
    overflow: unset;
    margin: auto;

    .confirmation-modal-message {
      height: max-content;
      width: 100%;
      font-size: $size18;
      font-weight: 500;
      color: $primary-black;
      display: flex;
      align-items: center;
      gap: $size16;

      .information-svg {
        svg {
          width: $size48;
          height: $size48;
        }
      }
    }

    .confirmation-modal-buttons {
      width: 100%;
      height: $size36;
      display: flex;
      justify-content: flex-end;
      margin-top: $size24;
      gap: $size8;

      @media screen and (max-width: 768px) {
        height: fit-content;
      }
    }
  }

  .overflow {
    position: fixed;
    cursor: pointer;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $primary-black;
    opacity: 0.32;
  }
}

@media all and (max-width: 768px) {
  .confirmation-modal-wrapper {
    .modal {
      width: auto;
      margin: 0 $size24 0 $size24;
      top: calc((100vh + 69px) / 2);
      display: flex;
      padding: $size24 $size16;
      flex-direction: column;
      justify-content: center;

      .confirmation-modal-message {
        display: flex;
        flex-direction: column;
        text-align: center;
      }

      .confirmation-modal-buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        & > * {
          width: 100%;
        }
      }
    }
  }
}

@import "../../styles/shared.scss";

.review-item-modal {

  .page-modal {

    .page-content {

      .review-item-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size16;

        .row-wrapper {
          width: 100%;
          display: flex;
          gap: $size16;

          .input-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: $size8;
            position: relative;
    
            .modal-section-disclaimer {
              padding: $size8 $size16;
              border-radius: $element-border-radius;
              background-color: $transparent-blue;
            }

            .options-wrapper {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              row-gap: $size16;
            }

            .input-option {
              margin: 0;
              font-weight: 500;
            }
            
            .input-item {
              width: 100%;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }

            .textarea-item {
              width: 100%;
              height: 100px;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              padding: $size16;
              line-height: $size24;
              box-sizing: border-box;
              outline: none;
              font-family: 'Roboto';
              font-size: $size14;
            }
          }

          .column-wrapper {
            display: flex;
            flex-direction: column;
            gap: $size4;

            .input-option {
              margin: 0;
              font-weight: 500;
            }

            .identifier-label {
              margin: 0;
              font-weight: 700;
              color: $dark-grey-blue;
            }
          }

          .action-buttons-wrapper {
            margin-left: auto;
            display: flex;
            gap: $size8;

            .action-button {
              padding: 0 $size16;
              height: 36px;
              border: none;
              background: $light-grey-blue;
              border-radius: $size16;
              cursor: pointer;
            }
          }
        }

        .separator {
          width: 100%;
          height: 1px;
          background-color: $medium-grey-blue;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {

      .review-item-modal-content {
        font-size: $size14;
        gap: $size16;

        .row-wrapper {

          .input-wrapper {
            flex-direction: column;
            gap: $size16;
            width: 100%;
            box-sizing: border-box;

            .options-wrapper {
              grid-template-columns: repeat(2, 1fr) !important;
            }
          }

          .action-buttons-wrapper {
            margin: $size16 0 0 0;
            width: 100%;
            justify-content: flex-start;
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 485px) {
    .page-modal {

      .page-content {

        .review-item-modal-content {
          gap: $size16;

          .row-wrapper {
            flex-direction: column;
            gap: unset;
          }
        }
      }
    }
  }
}

.hide-footer {

  .page-footer {
    display: none !important;
  }
}

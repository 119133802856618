@import "../../../styles/shared";

.rich-text-input {
  
  vaadin-rich-text-editor {
    border-radius: $element-border-radius;
    border: 1px solid $medium-grey-blue;
  }

  vaadin-rich-text-editor::part(content) {
    border-radius: 0 0 $element-border-radius $element-border-radius;
  }

  vaadin-rich-text-editor::part(toolbar-group-rich-text) {
    display: none;
  }
}

.rich-text-error {
  vaadin-rich-text-editor {
    border: 1px solid $required-red;
  }
}
@import "../styles/shared";

// ::-webkit-scrollbar {
//   width: 5.5px;
//   height: 5.5px;
// }

::-webkit-scrollbar-button {
  width: 5.5px;
  height: 5.5px;
}

::-webkit-scrollbar-thumb {
  background: $grey-text;
  border: 0 none grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: $off;
}

::-webkit-scrollbar-thumb:active {
  cursor: pointer;
  background: $grey-text;
}

::-webkit-scrollbar-track {
  background: $white;
  border: 0 none $scrollbar-grey;
  border-radius: 10px;
}

::-webkit-scrollbar-track:hover {
  background: transparent;
}

::-webkit-scrollbar-track:active {
  background: $grey-background-tabs;
}

::-webkit-scrollbar-corner {
  background: $grey-background-tabs;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.App {
  height: 100vh;
}

.u-page {
  overflow: auto;
  height: 100vh;

  .profile-container {
    display: none;
  }

  #page-content {
    overflow: unset;

    #main {
      &.nav-expand {
        margin-left: 0;
      }
    }
  }
}

.logo-wrapper {
  margin-left: 61px;
  @media screen and (max-width: 1023px) {
    margin-left: 0;
  }
}

#page-content,
#unauthenticted-content {
  overflow: auto;
  display: inline-block;
  height: 94vh;
  top: 69px;
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  &.disabled-banner {
    width: 100%;
    top: 0;
  }

  #main {
    padding: 10px 25px;
    margin-left: 61px;
    position: relative;
    max-width: 100%;
    // transition: margin-left .3s;
    min-height: 80vh;

    &.disabled-nav {
      margin-left: 0;
      padding: 0;
    }

    > div.public-page {
      margin-left: unset;
    }

    &.nav-expand {
      margin-left: 263px;

      @media screen and (max-width: 1023px) {
        margin-left: 0;
      }
    }

    @media screen and (max-width: 1024px) {
      min-height: 73vh;
    }

    @media screen and (max-width: 1023px) {
      margin-left: 0;
      //min-height: 68vh;
    }

    @media screen and (max-width: 768px) {
      padding: 10px 0;
    }

    @media screen and (max-width: 414px) {
      padding: 14px 0;
      min-height: 68vh;
      //top: 50px;
    }
  }

  @media screen and (max-width: 1023px) {
    top: 69px;
  }

  .online-activation {
    .close {
      height: 18px;
      padding: 9px 14px;
    }
    .title {
      padding: 0px 14px;
    }
    .modal {
      max-width: 368px;
      max-height: 328px;
      top: calc((100vh + 69px) / 2);
      height: 100%;
      padding: 0px;
      box-shadow: 0 2px 6px #00000034;
      border-radius: $element-border-radius;
    }
  }

  @media screen and (max-width: 768px) {
    top: 50px;
  }
}

#unauthenticted-content {
  height: auto;

  #main {
    min-height: 0;
    padding: 0;
  }
}

body.nav-open {
  overflow: hidden;
}

.center {
  text-align: center;
}

.error-message {
  font-size: 0.85rem !important;
  color: $error-color;
  margin-top: 5px;
  text-align: left;
}

.success-message {
  font-size: 0.85rem !important;
  color: $success-color;
  margin-top: 5px;
  text-align: left;
}

[class*="icon"] {
  font-size: 1.4rem;
}

.hr {
  background-color: $medium-grey-blue; //#11232E;
  border: none;
  border-top: 1px solid #d7d0d0;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

textarea {
  resize: none;
}

.d-flex {
  @include display-flex;
}

.flex-row {
  @include flex-direction-row;
}

.flex-column {
  @include flex-direction-column;
}

.flex-justify-center {
  justify-content: center;
}

.flex-justify-between {
  justify-content: space-between;
}

.flex-justify-around {
  justify-content: space-around;
}

.flex-justify-evenly {
  justify-content: space-evenly;
}

.flex-justify-start {
  justify-content: flex-start;
}

.flex-justify-end {
  justify-content: flex-end;
}

.flex-align-start {
  align-items: flex-start;
}

.flex-align-center {
  align-items: center;
}

.flex-align-end {
  align-items: flex-end;
}

.bg-white {
  background: #fff;
}

// .toast-wrapper {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   //background-color: $white;
//   //background-color: $transparent-red;
//   background-color: #fff6f6;
//   width: 100%;
//   height: 53px;
//   gap: $size24;
//   padding: 8px;

//   .status-color {
//     width: 8px;
//     height: 100%;
//     background-color: $required-red;
//     border-radius: $size4;
//   }

//   .text {
//     color: $primary-black;
//     font-size: 16px;
//     font-weight: 700;
//   }

//   svg {
//     width: 24px;
//     height: 24px;
//   }
// }
.Toastify {
  .Toastify__toast-container {
    top: 0;
    margin: 0;
    padding: 0;
    left: 0;
    transform: unset;
    width: 100vw;

    .Toastify__toast {
      background: #fff;
      box-shadow: 0px 3px 6px #00000029;
      min-height: $size36;
      padding: 0;
      display: flex;
      align-items: center;

      .Toastify__toast-body {
        padding: 0;
      }

      .Toastify__close-button {
        color: $primary-black;
        padding: $size16;
        align-self: unset;

        svg {
          width: $size24;
          height: $size24;
        }
      }

      .Toastify__progress-bar {
        background: $medium-grey-blue;
        height: 3px;
      }

      &.Toastify__toast--error {
        background: #fff6f6;

        .Toastify__close-button {
          color: #973937;
        }

        .Toastify__progress-bar {
          background: #cdacac;
        }
      }

      &.Toastify__toast--success {
        background: #fcfff5;

        .Toastify__close-button {
          color: #1e561f;
        }

        .Toastify__progress-bar {
          background: #a8c599;
        }
      }

      &.Toastify__toast--warning {
        background: #fffaf3;

        .Toastify__close-button {
          color: #7a4d05;
        }

        .Toastify__progress-bar {
          background: #ccbea0;
        }
      }

      &.Toastify__toast--info {
        background: #f8ffff;

        .Toastify__close-button {
          color: #0e566c;
        }

        .Toastify__progress-bar {
          background: #bedfe6;
        }
      }
    }
  }
}

.loading-icon {
  animation: spin infinite 2s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  @include display-flex;
  justify-content: center;
  position: fixed;
  top: 0;
  align-items: center;
  height: 100vh;
  margin: auto;
  width: 100vw;

  .loading-icon {
    font-size: 3rem;
    animation: spin infinite 3s linear;
  }
}

.session-expired-modal {
  .modal {
    max-width: 300px !important;
    
    div {
      text-align: center;
      
      .simple-button {
        background-color: $primary-blue;
        color: #fff;
        margin: 0 auto;
      }
    }
  }
}

@import '../../styles/shared';

.nickname-setup-modal-wrapper {
  .page-modal-wrapper {
    .page-modal {
      height: 253px !important;
      max-width: 400px !important;

      .page-header {
        display: none;
      }

      .page-content {
        padding: $size32;
        height: 253px;
        display: flex;
        flex-direction: column;

        .title {
          font-size: $size18;
          color: $primary-black;
          font-weight: 500;
          margin-bottom: $size8;
        }

        .subtitle {
          font-size: $size14;
          color: $dark-grey-blue;
          margin-bottom: $size24;
        }

        .nickname-input {
          width: 100%;
          height: $size36;
          border: 1px solid $medium-grey-blue;
          border-radius: $element-border-radius;
          text-indent: $size16;
          outline: none;
          box-sizing: border-box;
        }

        .nickname-input:focus-within {
          border: 1px solid $primary-blue;
        }

        .buttons-wrapper {
          display: flex;
          justify-content: flex-end;
          gap: $size8;
          margin-top: $size24;
        }
      }

      .page-footer {
        display: none;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal-wrapper {
      .page-modal {
        top: 25%;
      }
    }
  }
}
@import "../../styles/shared.scss";

.change-company-admin-modal {

  .page-modal {
    height: 408px;
    width: 640px;
    overflow: unset;

    .page-content {
      overflow: unset;
    
      .change-company-admin-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: $size16;
        margin-bottom: $size16;

        .row-wrapper {
          width: 100%;
          display: flex;
          gap: $size16;

          .input-wrapper {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            gap: $size8;
    
            .input-option {
              margin: 0;
              font-weight: 500;
            }
            
            .name-input {
              width: 100%;
              height: $size36;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              text-indent: $size16;
              outline: none;
              box-sizing: border-box;
            }

            .click-outside-wrapper {
              margin: 4px 0 0 0;
              border: 1px solid $medium-grey-blue; 
              box-shadow: none;
              max-width: 100%;
              box-sizing: border-box;

              .height {
                div {
                  color: $primary-black;
                  font-size: $size14;
                }         

                svg {
                  width: 12px;
                  height: 12px;
                  path {
                    fill : $primary-black;
                  }
                }

                .icon-dropdown-open {
                  transform: rotate(180deg);
                }
              }

              .options-wrapper {
                margin-top: 4px;
                border: 1px solid $medium-grey-blue;
                max-height: 160px;
                position: relative;
                overflow: overlay;

                .selected-option {
                  color: $primary-black;
                  font-weight: 500;
                }
              }
            }

            .has-error {
              border: 1px solid $required-red;
              border-radius: $element-border-radius;
            }
          }

          .disclaimer {
            display: flex;
            width: 100%;
            padding: $size8 $size16;
            border-radius: $element-border-radius;
            background-color: $transparent-blue;
            box-sizing: border-box;
          }

          .users-wrapper {

            .search-with-options-wrapper {
              position: relative;

              .search {
                position: relative;
                margin: 0;
                box-shadow: none;
                border-radius: $element-border-radius;
                border: 1px solid $medium-grey-blue;
              }

              input {
                border: none;
                margin: 0;

                &::placeholder {
                  font-style: italic;
                }
              }

              svg {
                position: absolute;
                right: $size8;

                .fillable-area {
                  fill: $primary-black !important;
                }
              }
    
              .options-wrapper {
                background-color: $white;
                max-height: 150px;
                overflow: overlay;
                position: relative;
                width: 100%;
                z-index: 10001;
                box-shadow: 0px 1px 6px #00000024;
                margin-top: $size4;
                border-radius: $element-border-radius;
    
                .result-wrapper {
                  padding: $size8 $size16;
                  gap: $size8;
                  height: $size36;
                  box-sizing: border-box;
                  align-items: center;
    
                  .user-name {
                    width: 30%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  }

                  .user-email {
                    color: $grey;
                  }
    
                  &:hover {
                    background-color: $grey-background;
                    cursor: pointer;
                  }
                }
    
                .no-results {
                  text-align: center;
                  font-size: $size14;
                  padding: $size16 0;
                }
    
                .buttons-wrapper {
                  margin-bottom: $size16;
                  display: flex;
                  width: 100%;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;
      width: 100% !important;

      .page-content {
        overflow: scroll;
      
        .change-company-admin-modal-content {
          font-size: $size14 !important;
          gap: $size8;

          .row-wrapper {
            flex-direction: column;
            gap: $size8;
    
            .input-wrapper {
              flex-direction: column;
              width: 100%;
              box-sizing: border-box;
            }
          }
        }
      }

      .page-footer {
        .cancel-button {
          width: 100%;
        }

        .submit-button {
          width: 100%;
        }
      }
    }
  }
}
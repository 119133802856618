@import "../../../styles/shared";

.filters-modal-page {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;

  .filters-overflow {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $primary-black;
    opacity: 0.32;
    z-index: 99;
    cursor: pointer;
    top: 0;
    left: 0;
  }

  .filters-modal-wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 69px;
    right: 0;
    height: calc(100vh - 70px);
    width: 320px;
    background-color: $white;
    box-shadow: -3px 4px 16px 0 rgba(0, 0, 0, 0.05);
    z-index: 100;

    .filters-header {
      display: flex;
      align-items: center;
      height: 56px;
      width: 100%;
      margin-top: $size16;
      cursor: pointer;

      .filters-back-button {
        display: flex;
        align-items: center;

        .back-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 24px;
          height: 24px;
          margin-left: $size24;
          border-radius: 12px;
          flex-shrink: 0;
        }

        .title {
          margin: 0 0 0 $size16;
          font-weight: 500;
        }
      }

      .clear-all-button {
        height: $size24;
        border-radius: 12px;
        padding: 0 $size16 0 $size16;
        margin-left: auto;
        margin-right: $size24;
        color: $dark-grey-blue;
        background-color: $light-grey-blue;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
      }      
    }

    .filters-body {
      min-height: calc(100% - 180px);
      flex: 1;
      overflow-y: auto;

      .filter-wrapper {
        display: block;
        min-height: 40px;
        height: fit-content;
        margin: $size24 $size24 0 $size24;

        .filter-header {
          display: flex;
          height: 32px;
          margin: 0 0 $size16 0;
          align-items: center;
          cursor: pointer;

          .title {
            margin: 0;
            font-size: $size18;
            font-weight: 700;
          }

          .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            margin-left: auto;
            border-radius: 12px;
          }
        }

        .filter-singleselect-content {
          display: flex;
          flex-direction: column;

          .click-outside-wrapper {
            margin: 0;
            border: 1px solid transparent;
            box-shadow: none;
            max-width: 100%;
            cursor: pointer;

            .select-input {
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
            }

            .is-focused {
              border: 1px solid $primary-blue;
            }

            .height {

              div {
                color: $primary-black;
                font-size: $size14;
              }         
    
              .placeholder {
                font-style: italic;
                color: $dark-grey-blue;
              }

              svg {
                width: 12px;
                height: 12px;
                path {
                  fill : $primary-black;
                }
              }
    
              .icon-dropdown-open {
                transform: rotate(180deg);
              }

              .text-wrapper {
                text-indent: $size16;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .reset-filter-button {
                background: none;
                border: none;
                width: 24px !important;
                height: 24px !important;
                cursor: pointer;
                margin-right: $size4;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                  flex-shrink: 0;
                  width: 16px !important;
                  height: 16px !important;

                  g {
                    stroke: $dark-grey-blue;
                  }
                }
              }
            }
    
            .options-wrapper {
              margin-top: 4px;
              border: 1px solid $medium-grey-blue;
              max-height: 390px;
              position: relative;
              overflow: overlay;
    
              .options-filter {
                width: 100%;
                height: 100%;
                background-color: $white;
                z-index: 1;
    
                .search {
                  background-color: $light-grey-blue;
                  border-radius: $element-border-radius;
                  margin: $size16 $size16 $size4 $size16 !important;
                  box-shadow: none !important;
                  z-index: 1;
    
                  .search-icon {
                    display: none;
                  }
                }
              }

              .option {
                font-size: $size14;
                line-height: 20px;
                padding: $size4 $size16 $size4 $size16;
                text-indent: 0;
                box-sizing: border-box;
                height: unset;
              }
    
              .selected-option {
                color: $primary-black;
                font-weight: 500;
              }
            }

            &:focus-within {
              border: 1px solid $primary-blue;
            }
          }
        }

        .filter-multiselect-content {
          display: flex;
          flex-direction: column;
          gap: $size8;

          .filter-multiselect-item {
            display: flex;
            align-items: center;
            min-height: $size36;
            cursor: pointer;
          }

          .show-all {
            margin: $size16 0 0 0;
            font-size: $size14;
            color: $primary-black;
            font-weight: 500;
            text-decoration: underline;
            cursor: pointer;
          }
        }

        .filter-date-content {        

          .react-datepicker-wrapper {
            width: 100%;
            margin-bottom: $size8;

            .custom-date-input {
              display: flex;
              background-color: $white;
              height: 40px;
              width: 100%;
              margin: $size8 0 0 0;
              border: 1px solid $medium-grey-blue;
              border-radius: $element-border-radius;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;

              .date-text {
                font-size: $size14;
                margin-left: $size16;
              }

              .calendar-icon {
                width: 16px;
                height: 16px;
                margin-right: $size8;

                path {
                  fill: $primary-black;
                }
              }
            }
          }

          .input-option {
            color: $primary-black;
            font-size: $size14;
            font-weight: 500;
            margin: 0;
          }

          .react-datepicker-popper {
            margin-top: $size4;

            .react-datepicker {
              font-size: 1rem;
              border-radius: $element-border-radius;
              border: 1px solid $medium-grey-blue;
              box-shadow: -3px 4px 16px 0 rgba(0, 0, 0, 0.05);
              font-family: "Roboto";
  
              .react-datepicker__triangle {
                display: none;
              }
  
              .react-datepicker__header {
                background-color: $light-grey-blue;
                border-bottom: 1px solid $medium-grey-blue;
                display: flex;
                flex-direction: column;
                gap: $size8;

                .react-datepicker__current-month {
                  font-size: $size14;
                  font-weight: 700;
                  color: $primary-black;
                }

                .react-datepicker__day-names {
                  font-weight: 500;
                }

                .react-datepicker__header__dropdown {
                  display: flex;
                  justify-content: space-between;
                  padding: 0 $size16;

                  .react-datepicker__month-dropdown-container {
                    margin: 0;

                    .react-datepicker__month-read-view {
                      text-align: left;

                      .react-datepicker__month-read-view--down-arrow {
                        margin-left: $size4;
                      }
                    }
                  }

                  .react-datepicker__year-dropdown-container {
                    margin: 0;
                  }
                }
              }
              
              .react-datepicker__month {
                .react-datepicker__week {
                  
                  .react-datepicker__day {
                    border-radius: 50%;
                  }

                  .react-datepicker__day--selected {
                    background-color: $primary-blue !important;
                  }
    
                  .react-datepicker__day--selected:hover {
                    background-color: $primary-blue !important;
                  }

                  .react-datepicker__day--keyboard-selected {
                    background-color: $primary-blue !important;
                    border-radius: 50%;
                  }

                  .react-datepicker__day--keyboard-selected:hover {
                    background-color: $primary-blue !important;
                    border-radius: 50%;
                  }              
                }
              }

              .react-datepicker__year {
                .react-datepicker__year-text--keyboard-selected {
                  background-color: $primary-blue !important;
                }

                .react-datepicker__year-text--keyboard-selected:hover {
                  background-color: $primary-blue !important;
                }

                .react-datepicker__year-text--selected {
                  background-color: $primary-blue !important;
                }

                .react-datepicker__year-text--selected:hover {
                  background-color: $primary-blue !important;
                }
              }

              .react-datepicker__month-dropdown {
                background-color: $white;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                font-size: $size14;
                padding: $size8 0;
                width: 60%;
                left: 20%;

                @media screen and (max-width: 768px) {
                  width: 80%;
                  left: 10%;
                }

                .react-datepicker__month-option:hover {
                  background-color: $light-grey-blue;
                }
              }

              .react-datepicker__year-dropdown {
                background-color: $white;
                border: 1px solid $medium-grey-blue;
                border-radius: $element-border-radius;
                font-size: $size14;
                padding: $size8 0;

                .react-datepicker__year-option:hover {
                  background-color: $light-grey-blue;
                }
              }
            }
          }
        }

        .filter-multiselect-with-children-content {

          .filter-multiselect-with-children-item {
            margin-bottom: $size16;

            .parent-option,
            .child-option {
              height: $size36;
              display: flex;
              align-items: center;
              cursor: pointer;

              .multiselect-icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;

                .icon-wrapper {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: $size24;
                  height: $size24;

                  svg {
                    path {
                      fill: $primary-black;
                    } 
                  }

                  .up-icon {
                    transform: rotate(180deg);
                  }
                }                
              }

              .parent-checkbox {
                flex-shrink: 0;

                .checkbox-wrapper {
                  .checkbox-text {
                    font-weight: 500;
                  }

                  svg {
                    path {
                      stroke: $white;
                    }
                  }
                }

                .minus-icon {
                  width: 12px;
                  height: 12px;

                  g {
                    path {
                      fill: $white !important;
                    }
                  }
                }
              }
            }

            .child-option {
              margin-left: $size24;
            }

            .filter-item-checkbox {
              pointer-events: none;
            }

            .filter-item-title {
              margin: 0 0 0 $size8;
              font-size: $size14;
              font-weight: 500;
              color: $primary-black;
            }

            .filter-child-item-title {
              margin: 0 0 0 $size8;
              font-size: $size14;
              color: $primary-black;
            }

            .show-all {
              margin: $size16 0 0 0;
              font-size: $size14;
              color: $primary-black;
              font-weight: 500;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
    }

    .filters-footer {
      position: sticky;
      width: 100%;
      height: 84px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: $size8;
      box-sizing: border-box;
      border-top: 1px solid $medium-grey-blue;

      .submit-button {
        margin-right: $size24;
      }
    }
  }

  @media all and (max-width: 768px) {
    .filters-modal-wrapper {
      position: absolute;
      width: 100%;
      top: 0;
      height: 100%;

      .cancel-button {
        margin-left: $size24;
        width: 100%;
      }

      .submit-button {
        width: 100%;
      }
    }
  }
}

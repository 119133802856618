@import '../../../styles/shared';

.simple-button {
  cursor: pointer;
  height: $size36;
  border-radius: $element-border-radius;
  border: 0;
  min-width: 6.857rem;
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 $size16 0 $size16;
  box-sizing: border-box;
  font-size: $size14;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  color: $primary-black;
  background-color: $medium-grey-blue;
  text-align: center;
  user-select: none;

  &.full-width {
    width: 100%;
  }

  &.action-button {
    background-color: $white;
    color: $primary-blue;
    border: 1px solid $primary-blue;
  }

  &.submit-button {
    color: $white;
    background-color: $primary-blue;
    border: 0;
  }

  &.cancel-button {
    background-color: $medium-grey-blue;
    color: $primary-black;
    border: 0;
  }

  &.action-delete-button {
    color: $danger-red;
    background-color: $light-red;
    border: 1px solid $danger-red;
  }

  &.delete-button {
    color: $white;
    background-color: $danger-red;
    border: 0;
  }

  &.success-button {
    color: $white;
    background-color: $success-green;
    border: 0;
  }

  &.load-more-button {
    color: $strong-blue;
    border: 1px solid $strong-blue;
    background-color: transparent;
  }

  &:hover:not(.disabled) {
    box-shadow: $button-box-shadow;
  }

  &.disabled {
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
    color: $medium-grey-blue;
    background-color: $white;
    border: 1px solid $medium-grey-blue;
  }

  &.label-button {
    color: $primary-blue;
    background-color: transparent;
    border: 0;
    padding: 0;
    font-size: $size14;
    width: max-content;
    height: max-content;
    min-width: max-content;
    font-weight: 400;
    text-align: left;
    user-select: text;
  }

  &.label-button:hover {
    box-shadow: none;
  }
}

.language-container {
  padding: 0;

  .language-wrapper {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .lang-name {
      padding: 0 10px 0 0;

      @media screen and (max-width: 414px) {
        font-size: 1.34rem;
      }
    }

    [class*="icon"] {
      font-size: 1rem;
  }

    @media screen and (max-width: 414px) {
      .icon {
        font-size: 1rem;
        right: 23px;
        position: absolute;
      }
    }
  }

  .language-options {
    width: 100%;

    .lang-option {
      text-align: left;
      padding: 5px 0;
    }

    .focused {
      font-weight: 500;
    }
  }
}
@import "../../styles/shared.scss";

.admin-order-details-modal {
  .page-modal {
    min-width: 870px;
    .admin-order-details-wrapper {
      width: 100%;

      .cancelation-requested-banner {
        height: 56px;
        background: $dark-yellow-transparent;
        border: 1px solid $dark-yellow;
        border-radius: $element-border-radius;
        color: $dark-yellow;
        font-size: $size16;
        font-weight: 500;
        box-sizing: border-box;
        margin-bottom: $size24;
      }

      .section {
        margin-top: $size24;
        width: 200px;

        .section-title {
          color: $dark-grey-blue;
          font-weight: 700;
          size: $size14;
        }

        .section-value {
          size: $size14;
          margin-top: $size8;
          font-size: $size14;
          gap: $size8;
          align-items: center;

          .success-svg {
            .a {
              fill: $success-green;
            }
          }

          .processed {
            color: $success-green;
          }

          .processing {
            padding-top: 1px;
            color: $dark-yellow;
          }

          .cancelled {
            color: $red;
          }
        }
      }

      .admin-ordered-products {
        padding-top: 0 !important;
        margin-top: 0 !important;
        .table {
          margin-top: $size16;
          .table-content {
            .table-header {
              display: grid;
              grid-template-columns: minmax(250px, 1fr) minmax(80px, 0.6fr) minmax(80px, 0.6fr) minmax(80px, 0.6fr);
              overflow: hidden;
              .header {
                padding-left: $size16;
                background-color: $table-header-grey;
                font-weight: bold;
                height: $size32;
                margin-bottom: $size16;
                display: flex;
                align-items: center;
              }

              .header-text {
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .row-container {
              display: grid;
              grid-template-columns: minmax(250px, 1fr) minmax(80px, 0.6fr) minmax(80px, 0.6fr) minmax(80px, 0.6fr);
              overflow: hidden;
              margin-top: $size8;

              .body {
                align-items: flex-start;
                padding-left: $size16;
                .product-details {
                  gap: $size8;

                  .image-wrapper {
                    background: $light-grey-blue;
                    width: 80px;
                    height: 80px;
                    border-radius: $element-border-radius;

                    img {
                      width: 80px;
                      height: 80px;
                      object-fit: cover;
                    }

                    svg {
                      width: 80px;
                      height: 80px;
                    }
                  }

                  .details {
                    gap: $size8;
                    div:nth-child(1) {
                      font-weight: bold;
                    }
                    div:nth-child(2) {
                      color: $dark-grey-blue;
                    }
                  }
                }
              }

              .body:nth-child(1) {
                padding-left: 0;
              }
            }

            .table-footer {
              margin-top: $size32;
              display: grid;
              grid-template-columns: minmax(250px, 1fr) minmax(80px, 0.6fr) minmax(80px, 0.6fr) minmax(80px, 0.6fr);
              grid-template-rows: repeat(36px, 3);
              overflow: hidden;
              border-top: 1px solid $medium-grey-blue;
              padding: $size32 0 0 0;

              .footer {
                padding-left: $size16;
                height: $size32;
                display: flex;
                align-items: center;
              }

              .footer-text {
                width: auto;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .footer-bolded {
                font-weight: 700;
              }
            }
          }
        }

        .mobile-view-products {
          .item-container {
            .product-details {
              gap: $size8;
              margin-top: $size16;
              margin-bottom: $size16;

              .image-wrapper {
                background: $light-grey-blue;
                width: 80px;
                height: 80px;
                border-radius: $element-border-radius;

                img {
                  width: 80px;
                  height: 80px;
                }

                svg {
                  width: 80px;
                  height: 80px;
                }
              }

              .details {
                gap: 4px;
                width: 100%;
                div:nth-child(1) {
                  font-weight: bold;
                }
                div:nth-child(2) {
                  color: $dark-grey-blue;
                }

                .price-info {
                  gap: 56px;
                  align-items: flex-start;
                  .price-info-body {
                    .title {
                      color: $black;
                      font-size: $size12;
                      font-weight: bold;
                    }

                    .value {
                      color: $black;
                    }
                  }
                  @media screen and (max-width: 380px) {
                    gap: 25px;
                  }
                }
              }
            }
          }

          .price-overview {
            display: flex;
            flex-direction: column;
            gap: $size8;
            margin-top: $size16;
            border-top: 1px solid $medium-grey-blue;
            padding: $size16 0 0 0;
            width: 100%;

            .price {
              display: flex;
              gap: $size8;
              font-size: $size14;
              color: $primary-black;
              width: 100%;

              .label {
                width: 50%;
                text-align: end;
              }

              .value {
                width: 50%;
                text-align: end;
              }
  
              .bolded {
                font-weight: 700;
              }
            }
          }
        }
      }

      .admin-payment-details,
      .admin-customer-details {
        border-top: 1px solid $scrollbar-grey;
      }

      .admin-payment-details,
      .admin-customer-details,
      .admin-ordered-products {
        margin-top: $size16;
        background: $white;
        padding: $size16 0 $size16 0;

        .title {
          font-size: $size16;
          font-weight: bold;
        }
        .details {
          gap: 150px;
        }
      }

      .admin-payment-details,
      .admin-customer-details {
        padding-top: $size32;
        padding-left: 0;

        .details {
          gap: $size8;
          width: 100%;
          box-sizing: border-box;
          flex-wrap: nowrap;

          .section {
            width: 33%;
          }
        }

        @media screen and (max-width: 494px) {
          .details {
            flex-wrap: wrap !important;
            gap: 0 !important;

            .section {
              width: auto !important;
              padding-right: $size32;
            }
          }
        }
      }

      .admin-payment-details {
        .details {

          .success-svg {
            .a {
              fill: $success-green;
            }
          }

          .cancel-svg {
            margin-top: -1px;
            margin-left: -$size4;
            width: $size24;
            height: $size24;
          }

          .processed {
            width: $size16;
            height: $size16;
            color: $success-green;
          }
          .processing {
            margin-top: -$size4;
            margin-left: -$size4;
            color: $dark-yellow;
          }
          .cancelled {
            margin-left: -3px;
            color: $dark-grey-blue;
          }
        }

        .pending-svg {
          margin-top: -5px;
          margin-left: -5px;
        }
      }

      .admin-customer-details {
        .details {
          flex-wrap: wrap;
          gap: 0;
        }

        .section:first-of-type {
          .section-value {
            color: $light-blue-primary;
          }
        }

        @media screen and (max-width: 494px) {
          .section {
            width: auto;
            padding-right: $size32;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      min-width: 100%;
    }
  }
}

.no-footer {
  .page-modal {
    .page-content {
      height: calc(100% - 69px);
    }
    
    .page-footer {
      display: none;
    }
  }
}

@import './styles/shared';

html {
  font-size: 14px;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}

body {
  overflow: hidden;
  background: #F5F6FA 0% 0% no-repeat padding-box;

  margin: 0;
  padding: 0;
  color: $primary-black;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

// h1 {
//   font-size: 2.3rem;
// }

// h2 {
//   font-size: 1.715rem;
// }

// h3 {
//   font-size: 1.337rem;
// }

// h4 {
//   font-size: 1.143rem;
// }

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:focus {
  outline: none;
}

@import '../../styles/shared';

.cycle-history-wrapper {
  padding: $size16;
  display: flex;
  box-sizing: border-box;
  gap: $size24;

  .cycles-calendar-wrapper {
    display: flex;
    height: max-content;
    flex-shrink: 0;
    

    .calendar {
      height: max-content;

      .calendar-header {
        background-color: $light-grey-blue;
        height: $size36;
        border-radius: $element-border-radius;
        padding: 0 $size8;
        box-sizing: border-box;

        button {
          width: $size24;
          height: $size24;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 12px;
            height: 12px;
          }
        }
        
      }

      .date {
        .selected {
          background-color: $light-grey-blue;
          color: $primary-black !important;
          border-radius: 20px;
        }

        .current-date {
          color: $primary-blue !important;
        }
      }
    }
  }

  .cycles-data-wrapper {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    //max-height: 355px;
    overflow-y: auto;

    .label {
      font-size: $size14;
      font-weight: 700;
      color: $dark-grey-blue;
      margin-bottom: $size4;
    }

    .selected-date {
      font-size: $size14;
      color: $primary-black;
      margin-bottom: $size16;
    }

    .cycles-wrapper {
      display: inline-grid;
      grid-template-columns: repeat(auto-fill, 144px);
      grid-gap: $size4;

      .cycle-item-wrapper {
        display: flex;
        flex-grow: 1;
        height: 24px;
        padding: 0 $size8;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        cursor: pointer;
        background-color: $success-green;
        border-radius: $element-border-radius;

        .time {
          margin: 0;
          font-size: $size12;
          font-weight: 700;
          color: $white;          
        }

        .title {
          margin: 0;
          font-size: $size12;
          color: $white;
          opacity: 0.6;
        }
      }

      .cycle-pending {
        background-color: $tracking-grey !important;
      }

      .cycle-complete {
        background-color: #26c875 !important;
      }

      .cycle-fault {
        background-color: #e2445c !important;
      }

      .cycle-aborted,
      .cycle-interrupted,
      .stop-button-pressed {
        background-color: #fdab3d !important;
      }
    }

    .no-cycles-wrapper {
      display: flex;
      align-items: center;
      width: 100%;

      .disclaimer {
        margin: 0;
        font-size: $size14;
        font-style: italic;
        color: $dark-grey-blue;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;

    .cycles-calendar-wrapper {
      width: 100%;
    }

    .cycles-data-wrapper {
      width: 100%;
      max-height: unset !important;
      height: unset !important;
    }
  }
}


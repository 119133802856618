@import "../../../styles/shared";

.tabs-wrapper {
  .tabs {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    overflow-x: auto;

    img {
      border: 0px;
      padding: 0;
      margin: 0;
      margin-right: 10px;
    }

    > li {
      @include display-flex;
      cursor: pointer;
    }

    &.box-tab {
      > li {
        align-items: center;
        justify-content: center;
        padding: 0.5em 1em;
        padding: 1rem 1.8rem 0.9rem;
        margin: 0;
        margin: 0px 3.5px;
        text-align: center;
        min-width: 83px;
        white-space: nowrap;

        &:first-of-type {
          margin-left: 0px;
        }
      }
      
      .tab-item {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .tab {
        background: #dcdcdc;
        opacity: 0.5;
        // width: 238px;
        // height: 30px;
        // margin: 0px 3.5px;

        div {
          text-align: center;
          font: normal normal normal 16px/20px Roboto;
          letter-spacing: 0px;
          color: black;
          text-decoration: none;

          @media screen and (max-width: 480px) {
            font: normal normal normal 14px/20px Roboto;
          }
        }
      }

      .current {
        background: #fff;
        color: black;
        font-weight: bold;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        // width: 238px;
        // height: 30px;

        position: relative;

        div {
          text-decoration: none;
          text-align: left;
          font: normal normal bold 16px/18px Roboto;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;

          @media screen and (max-width: 480px) {
            font: normal normal normal 14px/20px Roboto;
          }
        }
      }
    }

    &.underlined-box-tab {
      border-bottom: 1px solid $medium-grey-blue;

      .current {
        background: none;
        display: block;

        .tab-title {
          font-size: $size16;
          font-weight: 700;
        }
      }

      .current:after {
        content: '';
        height: 4px;
        background: $primary-blue;
        display:block;
        margin-top: 14px;
        border-radius: 2px;
      }

      .tab {
        background: none;
        display: block;
        opacity: 1;

        .tab-title {
          font-size: $size16;
          color: $dark-grey-blue;
        }
      }

      li {
        padding: 0;
        margin: 0 24px 0 0;
        min-width: 0;
      }

      .tab:after {
        content: '';
        height: 4px;
        background: transparent;
        display: block;
        margin-top: 12px;
        border-radius: 2px;
      }

      @media screen and (max-width: 768px) {
        margin: 0 $size16;
      }
    }

    &.underline-tab {
      > li {
        background: #fff;
        margin: 0px 2.8%;
        padding: 11px 0;
        justify-content: flex-start;

        &:first-of-type {
          margin-left: 0px;
        }

        &:nth-of-type(3) {
          min-width: 75px;
        }

        &:nth-of-type(4) {
          min-width: 100px;
        }

        div {
          font-size: 1.15rem;
          letter-spacing: 0px;
        }

        @media screen and (max-width: 480px) {
          justify-content: flex-start;
          min-width: 171px;

          div {
            text-align: left;
          }
        }
      }

      .current {
        font-weight: bold;
        border-bottom: 4px solid $primary-blue;
        border-radius: $element-border-radius;
      }
    }
  }

  .content {
    min-height: 90vh;
  }
}

.tabs-wrapper:has(> .underlined-box-tab) {
  margin-top: 20px;
}

@media all and (max-width: 480px) {
  .tabs-wrapper {
    .tabs {
      img {
        display: none;
      }
    }
  }
}

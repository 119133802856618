@import "../../../styles/shared";

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: $size14;

  .breadcrumb-item {
    text-transform: capitalize;
    color: $dark-grey-blue;

    &.home {
      cursor: pointer;
    }

    &.active {
      color: $primary-black;
      font-weight: bold;
    }

    &.inactive {
      cursor: auto;
    }

    &.back-wrapper {
      display: flex;
      align-items: center;
      cursor: pointer;
      
      .back-icon {
        width: 18px;
        height: 18px;
        margin-right: $size4;
        
        path {
          fill: $primary-blue;
        }
      }

      .back-text {
        color: $primary-blue;
      }

      .back-separator {
        margin: 0 $size12;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0 $size16;
  }
}

@import '../../styles/shared';

.analytics-details-wrapper {

  .page-modal-wrapper {
    .page-modal {
      .page-content {
        height: calc(100% - 69px);

        .analytics-details-content {

          .graph-wrapper {
            margin: $size32 0;

            .recharts-wrapper {

              .recharts-legend-wrapper {

                .legend-wrapper {
                  display: flex;
                  align-items: center;
                  margin-left: 55px;
                  margin-top: 16px;
                  justify-content: flex-start;
            
                  .legend-item {
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
            
                    .legend-item-dot {
                      width: 11px;
                      height: 11px;
                      border-radius: 50px;
                      margin-right: 5px;
                      flex-shrink: 0;
            
                      &.unique_sn_cf {
                        background-color: #21a2ee;
                      }
            
                      &.total_cf {
                        background-color: #f04438;
                      }
            
                      &.total_connected {
                        background-color: #12b76a;
                      }

                      @media screen and (max-width: 768px) {
                        width: 8px;
                        height: 8px;
                      }
                    }
            
                    &.active {
                      background-color: $grey-background;
                      padding: 5px 10px;
                      border-radius: $element-border-radius;
                    }
            
                    &.cursor-pointer {
                      cursor: pointer;
                    }
                  }
                }
              }

              
            }
          }
        }
      }

      .page-footer {
        display: none;
      }
    }
  }
}

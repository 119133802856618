@import "../../styles/shared.scss";

.view-role-details-modal {

  .page-modal {
    width: 640px;

    .page-content {
      height: calc(100% - 69px);

      .view-role-details-modal-content {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin-bottom: $size16;
  
        .disclaimer {
          padding: $size8 $size16;
          border-radius: $element-border-radius;
          background-color: $transparent-blue;
          margin-bottom: $size16;
        }

        .role-expand-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 56px;
          padding: 0 $size8;
          box-sizing: border-box;
          cursor: pointer;
      
          .title {
            color: $primary-black;
            font-size: $size14;
            font-weight: 700;
          }
        }
      
        .role-expand-body {
          display: flex;
          flex-direction: column;
          margin-top: $size16;
          gap: $size4;
          width: 100%;
          box-sizing: border-box;

          .title {
            color: $primary-black;
            font-size: $size14;
            font-weight: 500;
          }

          .description {
            color: $primary-black;
            font-size: $size14;
          }
        }

        .highlight {
          background-color: $light-grey-blue;
        }
      
        .border-bottom {
          border-bottom: 1px solid $medium-grey-blue;
        }
      }
    }

    .page-footer {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    .page-modal {
      height: 100% !important;
      width: 100% !important;

      .view-role-details-modal-content {
        font-size: $size14;
      }
    }
  }
}
